import { useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { incrementDateRange } from "@/views/reporting/functions";
import { getAvailabilityDisplayData } from "@/views/reporting/functions/availability-data";

const useStyles = createUseStyles({
	content: {
		display: "flex",
		flexDirection: "column",
	},
	availabilityScore: {
		marginBottom: "32px",
		display: "flex",
		flexDirection: "column",
		fontFamily: "Stolzl",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		color: "#353430",
		"& > span:first-child": {
			fontSize: "48px",
			fontWeight: "bold",
			paddingBottom: "8px",
		},
		"& > span:last-child": {
			fontSize: "24px",
			fontWeight: "500",
		},
	},
});

function SiteAvailabilityScore({ reportData, dateRange, isNewFormat, isAllMachinesSelected }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [presentationData, setPresentationData] = useState("");

	function mapData(data) {
		let score = 0;
		if (isNewFormat) {
			score = data.availabilityScore * 100;
		} else {
			let range = incrementDateRange(dateRange);
			const numberOfMachines = [...new Set(data.map((item) => item.machineId))].length;
			const onlineTime = data.reduce((acc, record) => {
				if (getAvailabilityDisplayData(record.status)[3] === true) {
					const diff = Date.parse(record.endTime) - Date.parse(record.startTime);
					return acc + diff;
				}
				return acc;
			}, 0);
			const totalTime = (Date.parse(range.end) - Date.parse(range.start)) * numberOfMachines;
			score = totalTime === 0 ? 0 : (onlineTime / totalTime) * 100;
		}
		const text = `${score.toLocaleString(undefined, { maximumFractionDigits: 1 })}%`;
		setPresentationData(text);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	return (
		<div className={classes.content}>
			<div className={classes.availabilityScore}>
				<span>{presentationData}</span>
				<span>
					<FormattedMessage id={isAllMachinesSelected ? "Site Availability" : "Availability Score"} />
				</span>
			</div>
		</div>
	);
}

export default SiteAvailabilityScore;

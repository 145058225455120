import { Button, Dropdown, Grid, Header, Icon, Message, Segment, Table } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Fragment } from "react";
import Fusion from "@/assets/images/machine-type/fusion.png";
import { readLocalStorage } from "@/api/local-storage";
import { sortObjectsAscending } from "@/utils/sort";
import useAxios from "@/api/useAxios";
import { useNavigate } from "react-router";

const useStyles = createUseStyles((theme) => ({
	saveButtonSegment: {
		border: "unset !important",
		boxShadow: "unset !important",
		margin: "10px !important",
	},
	ready: {
		margin: "0px !important",
		padding: "100px !important",
		backgroundColor: theme.colors.lightBackground,
		height: "calc(100vh - 220px) !important",
	},
	grid: {
		marginTop: "24px !important",
		maxWidth: "100vw",
		overflowX: "none",
		"& .row": {
			paddingTop: "0px !important",
		},
	},
	machine: {
		backgroundColor: theme.colors.white,
		marginTop: "0px !important",
		borderTopRightRadius: "6px",
		borderBottomRightRadius: "6px",
		paddingLeft: "40px !important",
		maxHeight: "calc(100vh - 215px)",
		height: "calc(100vh - 215px)",
	},
	machineHeading: {
		marginTop: "40px !important",
	},
	machineImage: {
		width: "266px",
	},
	trackHeader: {
		backgroundColor: `${theme.colors.white} !important`,
	},
	tracks: {
		margin: "0px !important",
	},
	tracksTableDiv: {
		backgroundColor: `${theme.colors.white} !important`,
		borderRadius: "6px",
		maxHeight: "calc(100vh - 215px)",
		height: "calc(100vh - 215px)",
	},
	tracksTable: {
		border: "unset !important",
	},
	buttons: {
		backgroundColor: "unset !important",
		"& tbody tr": {
			borderBottomWidth: "0px",
		},
		"& tr td": {
			borderTopWidth: "0px !important",
			padding: "15px !important",
		},
	},
	saveCell: {
		padding: "6px",
	},
	main: {
		paddingLeft: "unset !important",
	},
}));

const disableDropDowns = (machineStatus) => {
	return (
		machineStatus.toLowerCase() === "service" ||
		machineStatus.toLowerCase() === "online" ||
		machineStatus.toLowerCase() === "paused"
	);
};

function FusionChangeCorrugate({ corrugates, selectedMachine }) {
	const navigate = useNavigate();
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [tracks, setTracks] = useState(selectedMachine.tracks);
	const [errors, setErrors] = useState([]);
	const FusionMachineApi = useAxios("/fusionapi/api/v1/machines", token);

	const validateData = () => {
		var errors = [];

		var trackInfo = [];
		for (var i = 0; i < tracks.length; i++) {
			if (
				tracks[i].loadedCorrugate !== null &&
				tracks[i].loadedCorrugate !== undefined &&
				tracks[i].loadedCorrugate.id !== "00000000-0000-0000-0000-000000000000"
			) {
				trackInfo.push({
					trackNumber: tracks[i].trackNumber,
					offset: parseFloat(tracks[i].trackOffset),
					corrugate: tracks[i].loadedCorrugate,
				});
				if (tracks[i].trackOffset < selectedMachine.physicalMachineSettings.longHeadParameters.minimumPosition) {
					errors.push(
						intl.formatMessage(
							{ id: "Offset can't be lower than minimum position on track TRACK" },
							{ track: tracks[i].trackNumber },
						),
					);
				}
				if (tracks[i].trackOffset > selectedMachine.physicalMachineSettings.longHeadParameters.maximumPosition) {
					errors.push(
						intl.formatMessage(
							{ id: "Offset can't be larger than maximum position on track TRACK" },
							{ track: tracks[i].trackNumber },
						),
					);
				}
			}
		}

		setErrors(errors);
	};

	useEffect(() => {
		validateData();
	}, [tracks]);

	return (
		<Grid className={classes.grid}>
			<Grid.Row>
				<Grid.Column width={3} className={classes.machine}>
					<Grid>
						<Grid.Row>
							<Grid.Column>
								<Header as="h2" textAlign="left" className={classes.machineHeading}>
									<Header.Subheader>
										<FormattedMessage id="Machine" />
									</Header.Subheader>
									{selectedMachine.alias}
									<Header.Subheader>{selectedMachine.machineType}</Header.Subheader>
								</Header>
								<img src={Fusion} className={classes.machineImage} alt="Fusion" />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Grid.Column>
				<Grid.Column width={13} className={classes.main}>
					{(() => {
						switch (selectedMachine.status.toLowerCase()) {
							case "initializing":
								return (
									<Segment basic textAlign="center" className={classes.ready}>
										<Icon name="hourglass half" size="huge" color="blue" className={classes.checkBox} />
										<Header as="h2">
											<FormattedMessage id="Machine is initializing, please wait" />
										</Header>
									</Segment>
								);
							case "service":
							case "online":
							case "error":
							case "offline":
							case "paused":
							case "changingcorrugate":
								return (
									<Fragment>
										{tracks?.length === 0 ? (
											<div>Error: Incorrect Machine Configuration: No Tracks Configured</div>
										) : (
											<Grid className={classes.tracks}>
												<Grid.Row>
													<Grid.Column width={12}>
														<div className={classes.tracksTableDiv}>
															<Table className={classes.tracksTable}>
																<Table.Header>
																	<Table.Row>
																		<Table.HeaderCell className={classes.trackHeader}>
																			<FormattedMessage id="Track" />
																		</Table.HeaderCell>
																		<Table.HeaderCell className={classes.trackHeader}>
																			<FormattedMessage id="Selected zFold" />
																		</Table.HeaderCell>
																		<Table.HeaderCell className={classes.trackHeader}>
																			<FormattedMessage id="Track Offset" />
																		</Table.HeaderCell>
																	</Table.Row>
																</Table.Header>
																<Table.Body>
																	{tracks.map((t) => {
																		return (
																			<Table.Row
																				key={"Track" + t.trackNumber}
																				error={
																					errors.length > 0 &&
																					((t.trackNumber === 1 && t.trackOffset - t.loadedCorrugate.width < 0) ||
																						(t.trackNumber === 2 &&
																							t.trackOffset + t.loadedCorrugate.width >
																								selectedMachine.physicalMachineSettings.crossHead.maximumPosition))
																				}
																			>
																				<Table.Cell>{t.trackNumber}</Table.Cell>
																				<Table.Cell>
																					<Dropdown
																						selection
																						disabled={disableDropDowns(selectedMachine.status)}
																						value={t.loadedCorrugate?.id ?? "00000000-0000-0000-0000-000000000000"}
																						options={[
																							...corrugates.reduce(
																								(acc, corrugate) => [
																									...acc,
																									{
																										key: corrugate.id,
																										value: corrugate.id,
																										text: corrugate.alias,
																									},
																								],
																								[],
																							),
																						]}
																						onChange={(_, { value }) => {
																							const newCorrugate = corrugates.filter((c) => c.id === value);
																							const newTrack = { ...t, loadedCorrugate: newCorrugate[0] };

																							setTracks(
																								sortObjectsAscending(
																									[
																										...tracks.filter((tt) => tt.trackNumber !== t.trackNumber),
																										newTrack,
																									],
																									"trackNumber",
																								),
																							);
																						}}
																						tabIndex={2}
																					/>
																				</Table.Cell>
																				<Table.Cell>{t.trackOffset}</Table.Cell>
																			</Table.Row>
																		);
																	})}
																</Table.Body>
															</Table>
															<Message error={errors.length > 0} hidden={errors.length === 0}>
																<Message.List>
																	{errors.map((err, errIndex) => {
																		return (
																			<Message.Item key={errIndex}>
																				<FormattedMessage id={err} />
																			</Message.Item>
																		);
																	})}
																</Message.List>
															</Message>
														</div>
													</Grid.Column>
													<Grid.Column width={4}>
														<Table basic="very" className={classes.buttons}>
															<Table.Body>
																<Table.Row>
																	<Table.HeaderCell className={classes.saveCell}>
																		<Button
																			primary
																			disabled={errors.length > 0}
																			floated="right"
																			onClick={() => {
																				FusionMachineApi.updateWithUrl(
																					`${selectedMachine.id}/tracks`,
																					tracks,
																					() => {
																						navigate("/production/job-queue");
																					},
																					(err) => {
																						if (err?.response?.status === 304) navigate("/production/job-queue");
																						setErrors([err?.response?.data]);
																					},
																				);
																			}}
																		>
																			<FormattedMessage id="Save" />
																		</Button>
																	</Table.HeaderCell>
																</Table.Row>
															</Table.Body>
														</Table>
													</Grid.Column>
												</Grid.Row>
											</Grid>
										)}
									</Fragment>
								);
							default:
								return <div>State &quot;{selectedMachine.status.toLowerCase()}&quot; not handled</div>;
						}
					})()}
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}

export default FusionChangeCorrugate;

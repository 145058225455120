import { Grid, Table } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage, useIntl } from "react-intl";

import { countSection, resolutionSection, totalItems } from "./optimal-production-details";

const useStyles = createUseStyles((theme) => ({
	...theme.optimalProductionTable,
}));

const toActionDescription = (actionCode, entityToBeModified, entitiesToBeAssociated, intl) => {
	var action = "";
	var toTarget = "";

	switch (actionCode) {
		case "AssignMaterialToProductionGroup":
			action = intl.formatMessage({ id: "Assign Corrugates" });
			toTarget = intl.formatMessage({ id: "to Production Group" });
			return `${action} ${entitiesToBeAssociated} ${toTarget} ${entityToBeModified}`;

		case "AssignMachineToProductionGroup":
			action = intl.formatMessage({ id: "Assign Machines" });
			toTarget = intl.formatMessage({ id: "to Production Group" });
			return `${action} ${entitiesToBeAssociated} ${toTarget} ${entityToBeModified}`;

		case "LoadMaterial":
			action = intl.formatMessage({ id: "Load" });
			toTarget = intl.formatMessage({ id: "on machine" });
			return `${action} ${entityToBeModified} ${toTarget} ${entitiesToBeAssociated}`;

		case "EnableMachineGroup":
			action = intl.formatMessage({ id: "Enable Machine Group" });
			return `${action}: ${entitiesToBeAssociated}`;

		case "ReplenishMaterial":
			action = intl.formatMessage({ id: "Replenish" });
			toTarget = intl.formatMessage({ id: "on machine" });
			return `${action} ${entityToBeModified} ${toTarget} ${entitiesToBeAssociated}`;

		default:
			return intl.formatMessage({ id: "Unknown actionCode:" }) + " " + actionCode;
	}
};

const OptimalProductionBlocked = ({ details, count }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();

	return (
		<div className={classes.outer}>
			<Grid className={classes.grid}>
				<Table className={classes.table}>
					<Table.Body>
						<Table.Row className={classes.tableRow}>
							<Table.Cell className={classes.columnLabelText}>{totalItems(count, intl)}</Table.Cell>
							<Table.Cell className={classes.columnLabelText}>
								<FormattedMessage id="Action to Take" />
							</Table.Cell>
						</Table.Row>
						{details.map((item, i) => (
							<Table.Row className={classes.tableRow} key={i}>
								<Table.Cell className={classes.tableItemText}>{countSection(item.count, intl)}</Table.Cell>
								<Table.Cell className={classes.tableItemText}>
									{resolutionSection(item.resolutions, intl, toActionDescription, classes)}
								</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>
				</Table>
			</Grid>
		</div>
	);
};

export default OptimalProductionBlocked;

import { getTokenFromLocalStorage, logout, UserContext } from "@/components/user-context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIdentityApi } from "@/contexts/identity-api-context.jsx";
import useOnLoadedEffect from "@/components/use-on-loaded-effect";
import { isDev } from "../utils/isDev";
import { useLocation, useNavigate } from "react-router";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const getCurrentUserInitialState = () => ({ role: [] });

const UserContextProvider = ({ children }) => {
	const navigate = useNavigate();
	const [currentUser, _setCurrentUser] = useState(getCurrentUserInitialState);
	const { pathname } = useLocation();
	const reactPlugin = useAppInsightsContext();
	const identityApi = useIdentityApi();

	const setCurrentUser = useCallback(
		async (newData) => {
			if (newData === undefined) newData = { role: [] };
			let additionalTenants = newData?.AdditionalTenants ?? currentUser.AdditionalTenants;
			let licenseInfo = {};
			if (newData != null && newData.id != null && newData.tid != null && identityApi != null) {
				let response = await identityApi.GetAdditionalTenants({
					UserId: newData.id,
				});

				if (response.success) {
					let newTenants = response.responseData.AdditionalTenants ?? response.responseData.additionalTenants;
					if (newTenants != null) additionalTenants = newTenants;
				}

				let licensingResponse = await identityApi.getLicensingInfo(newData.tid);

				if (licensingResponse.success) {
					let info = licensingResponse.responseData;
					if (info != null) licenseInfo = info;
				}
			}

			let finalSet = {
				...newData,
				...licenseInfo,
				AdditionalTenants: additionalTenants,
			};

			_setCurrentUser(finalSet);
			return finalSet;
		},
		[currentUser?.AdditionalTenants],
	);

	const onStorage = useCallback(
		(e) => {
			if (e.key === "BEARER" && e.oldValue !== e.newValue) {
				console.info("Change to BEARER token stored in local storage from another browser window, refreshing page");
				navigate(0);
			}
		},
		[navigate],
	);

	useEffect(() => {
		window.addEventListener("storage", onStorage);

		return () => {
			window.removeEventListener("storage", onStorage);
		};
	}, [onStorage]);

	useOnLoadedEffect(() => {
		const getToken = async () => {
			const token = await getTokenFromLocalStorage();
			await setCurrentUser(token);
			let unauthenticatedRoutes = [
				"/universal-login",
				"/login-2phase",
				"/forgot-password",
				"/complete-password-reset",
				"/sso-error",
			];

			if (!token && !pathname?.endsWith("logged-out")) {
				if (unauthenticatedRoutes.includes(pathname?.toLowerCase())) {
					navigate(pathname?.toLowerCase());
				} else if (isDev() && pathname?.toLowerCase().includes("/dojo")) navigate("/dojo");
				else navigate("/login");
			}
		};

		getToken();
	});

	useEffect(() => {
		if (currentUser) {
			const appInsights = reactPlugin?.getAppInsights();
			if (appInsights) {
				const handler = appInsights.addTelemetryInitializer((env) => {
					env.data = env.data || {};

					env.data["company"] = currentUser.tname;
					env.data["isPacksizeUser"] = currentUser.email?.toLowerCase().endsWith("@packsize.com");
				});
				return () => handler.remove();
			}
		}
	}, [reactPlugin, currentUser]);

	const userContextValue = useMemo(() => ({ currentUser, setCurrentUser, logout }), [currentUser, setCurrentUser]);

	return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

export default UserContextProvider;

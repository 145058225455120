import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { ProductionContext } from "@/contexts/production-context";
import { JobQueueContext } from "@/views/production/orders/job-queue-provider";

function MachineStatusMessage() {
	const { currentMachineGroup } = useContext(ProductionContext);
	const { selectedBatches } = useContext(JobQueueContext);

	switch (currentMachineGroup?.status.toLowerCase()) {
		case "initializing":
			return <FormattedMessage id="Machine Initializing" defaultMessage="Machine Initializing" />;
		case "service":
		case "machineservice":
			return <FormattedMessage id="Machine is in Service Mode" defaultMessage="Machine is in Service Mode" />;
		case "error":
			return <FormattedMessage id="Machine Error" defaultMessage="Machine Error" />;
		case "paused":
			return <FormattedMessage id="Machine Paused" defaultMessage="Machine Paused" />;
		case "offline":
			return <FormattedMessage id="Machine Offline" defaultMessage="Machine Offline" />;
		case "online":
			if (currentMachineGroup?.productionEnabled) {
				if (selectedBatches?.length > 0) {
					return (
						<FormattedMessage
							id="Producing on {machineGroupName} ({count})"
							defaultMessage="Producing on {machineGroupName} ({count})"
							values={{ machineGroupName: currentMachineGroup.alias, count: selectedBatches?.length || 0 }}
						/>
					);
				} else {
					return <FormattedMessage id="Ready to Receive Jobs" defaultMessage="Ready to Receive Jobs" />;
				}
			} else {
				return (
					<FormattedMessage
						id="Press Play on the Status Bar to Produce"
						defaultMessage="Press Play on the Status Bar to Produce"
					/>
				);
			}
		default:
			return `State ${currentMachineGroup?.status.toLowerCase()} not handled`;
	}
}

export default MachineStatusMessage;

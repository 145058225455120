import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";
import { ReactComponent as BackArrow } from "@/views/reporting/img/black-left-arrow.svg";
import { ReactComponent as ForwardArrow } from "@/views/reporting/img/black-right-arrow.svg";
import { ReactComponent as DisabledLeftArrow } from "@/views/reporting/img/disabled-left-arrow.svg";
import { ReactComponent as DisabledRightArrow } from "@/views/reporting/img/disabled-right-arrow.svg";

const useStyles = createUseStyles({
	pagination: {
		marginBottom: "32px",
		marginLeft: "auto",
		marginRight: "auto",
		"& > svg": {
			verticalAlign: "middle",
			margin: "8px",
		},
		"& > span": {
			verticalAlign: "middle",
		},
	},
});

function SwimLanePagination({ pagination, setPagination, paginationResult, limit }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const leftArrow = paginationResult.hasPrevious ? (
		<BackArrow onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })} key="enabled-left-arrow" />
	) : (
		<DisabledLeftArrow key="disabled-left-arrow" />
	);
	const rightArrow = paginationResult.hasNext ? (
		<ForwardArrow
			onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
			key="enabled-right-arrow"
		/>
	) : (
		<DisabledRightArrow key="disabled-right-arrow" />
	);
	return (
		<div className={classes.pagination}>
			{limit < 1000 &&
				paginationResult.totalPages > 1 && [
					leftArrow,
					<span key="swim-lane-pagination-pages-of-total-pages">
						<FormattedMessage
							id="{page} of {pages}"
							defaultMessage="{page} of {pages}"
							values={{
								page: pagination.page,
								pages: paginationResult.totalPages,
							}}
						/>
					</span>,
					rightArrow,
				]}
		</div>
	);
}
export default SwimLanePagination;

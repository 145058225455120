import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "semantic-ui-react";
import { ReactComponent as ErrorIcon } from "../img/errors-icon.svg";
import { ReactComponent as TrophyIcon } from "../img/trophy-icon.svg";
import DashboardCard from "@/views/reporting/dashboard-card";
import { getPlannedProductionDataForSite } from "@/views/reporting/services/API/Reports/planned-production-api";
import { getBucketErrorsV1AvailabilityByPlannedProductionSchedulesForSite } from "../services/API/Reports/errors-availability-api";
import { incrementDateRange, midnightDate } from "../functions";
import TotalErrors from "./cards/total-errors";
import SiteAvailabilityScore from "./cards/site-availability-score";
import SiteAvailabilityStatus from "./cards/site-availability-status";
import AvailabilityByMachine from "./cards/availability-by-machine";
import ExperienceContainer from "../layout/experience-container";
import Row from "../layout/row";
import TopErrors from "./cards/top-errors";
import TopErrorsByMachine from "./cards/top-errors-by-machine";
import AverageErrorsPerHourByMachine from "./cards/average-errors-per-hour-by-machine";
import MachineStatusByHour from "./cards/machine-status-by-hour";
import { isFeatureFlagEnabled } from "@/utils/auth";
import errorBoundary from "@/components/error-boundary";
import { sortByValue } from "@/views/reporting/functions/sort";

function ErrorsAndAvailability({ currentUser }) {
	const intl = useIntl();
	const [errorData, setErrorData] = useState([]);
	const [availabilityData, setAvailabiltyData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [internalNavigation, setInternalNavigation] = useState("");
	const [isBucketData, setIsBucketData] = useState(false);
	const [machines, setMachines] = useState(() => {
		const machinesSaved = localStorage.getItem("MACHINES");
		return machinesSaved !== null && machinesSaved !== "undefined" ? JSON.parse(machinesSaved) : [];
	});

	const [availabilitySortByMachine, setAvailabilitySortByMachine] = useState("none");
	const [availabilitySortByAmount, setAvailabilitySortByAmount] = useState("desc");

	const [avgErrorsSortByMachine, setAvgErrorsSortByMachine] = useState("none");
	const [avgErrorsSortByAmount, setAvgErrorsSortByAmount] = useState("desc");

	const selectedSites = useMemo(() => [currentUser.CustomerId, currentUser.tid], [currentUser]);
	const [footerText, setFooterText] = useState("");
	const [oldFooterText, setOldFooterText] = useState("Card is based only on a 24hr time period");

	const [dateOptions, setDateOptions] = useState({
		start: midnightDate(new Date()),
		end: midnightDate(new Date()),
	});
	const [showGraph, setShowGraph] = useState(true);
	const defaultColor = "#323430";

	function onSortClick(current, setCurrentSort, oppositeCurrent, setOppositeSort) {
		if (oppositeCurrent !== "none") {
			setOppositeSort("none");
		}
		if (current === "desc") {
			setCurrentSort("asc");
		} else {
			setCurrentSort("desc");
		}
	}

	function getSortIcon(sortValue) {
		if (sortValue === "asc") {
			return <Icon name="arrow up" />;
		}
		if (sortValue === "desc") {
			return <Icon name="arrow down" />;
		}
		return "";
	}

	const subtitles = {
		availability: [
			{
				id: "Machines",
				onClick: () =>
					onSortClick(
						availabilitySortByMachine,
						setAvailabilitySortByMachine,
						availabilitySortByAmount,
						setAvailabilitySortByAmount,
					),
				icon: () => getSortIcon(availabilitySortByMachine),
			},
			{
				id: "Availability",
				onClick: () =>
					onSortClick(
						availabilitySortByAmount,
						setAvailabilitySortByAmount,
						availabilitySortByMachine,
						setAvailabilitySortByMachine,
					),
				icon: () => getSortIcon(availabilitySortByAmount),
			},
		],
		avgErrors: [
			{
				id: "Machines",
				onClick: () =>
					onSortClick(
						avgErrorsSortByMachine,
						setAvgErrorsSortByMachine,
						avgErrorsSortByAmount,
						setAvgErrorsSortByAmount,
					),
				icon: () => getSortIcon(avgErrorsSortByMachine),
			},
			{
				id: "Error Count",
				onClick: () =>
					onSortClick(
						avgErrorsSortByAmount,
						setAvgErrorsSortByAmount,
						avgErrorsSortByMachine,
						setAvgErrorsSortByMachine,
					),
				icon: () => getSortIcon(avgErrorsSortByAmount),
			},
		],
		topErrors: ["Error Name", "Error Count"],
	};

	async function getData(sites) {
		setIsLoading(true);
		let errors = [];
		let availability = [];
		let plannedProduction = [];
		let data = [];
		const newDateRange = incrementDateRange(dateOptions);
		const featureFlagPlannedProduction = isFeatureFlagEnabled(
			currentUser,
			"reporting-allow-availability-planned-production",
		);

		if (featureFlagPlannedProduction) {
			plannedProduction = await getPlannedProductionDataForSite(sites, newDateRange);
			setFooterText("Card is based on your Planned Production schedule");
			setOldFooterText("Card is based on your Planned Production schedule");
		}

		data = await getBucketErrorsV1AvailabilityByPlannedProductionSchedulesForSite(
			plannedProduction,
			sites,
			machines.filter((m) => m.checked).map((m) => m.id),
			newDateRange,
			featureFlagPlannedProduction,
		);

		availability = data.availabilityBuckets;

		availability.byMachineByHour = sortByValue(availability.byMachineByHour, "machineName", "asc");

		errors = data.errorsBucketV1Responses;

		setIsLoading(false);
		setIsBucketData(true);
		setErrorData(errors);
		setAvailabiltyData(availability);
	}

	function errorsByMachine() {
		return (
			<Row>
				<DashboardCard
					key="Top Errors by Machine"
					title="Top Errors by Machine"
					subtitles={["Error Name", "Error Count"]}
					setInternalNavigation={setInternalNavigation}
					color={defaultColor}
					dateRange={dateOptions}
				>
					<TopErrorsByMachine
						reportData={errorData}
						dateRange={dateOptions}
						limit={1000}
						color={defaultColor}
						isNewFormat={isBucketData}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function averageErrorsPerHourByMachine() {
		return (
			<Row>
				<DashboardCard
					key="Avg. Errors Per Hour by Machine"
					title="Average Errors Per Hour by Machine"
					subtitles={subtitles.avgErrors}
					setInternalNavigation={setInternalNavigation}
					color={defaultColor}
					dateRange={dateOptions}
				>
					<AverageErrorsPerHourByMachine
						reportData={errorData}
						dateRange={dateOptions}
						limit={1000}
						color={defaultColor}
						isNewFormat={isBucketData}
						sortByAmount={avgErrorsSortByAmount}
						sortByMachine={avgErrorsSortByMachine}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function availabilityByMachine() {
		return (
			<Row>
				<DashboardCard
					key="Availability by Machine"
					title="Availability by Machine"
					subtitles={subtitles.availability}
					setInternalNavigation={setInternalNavigation}
					limit={1000}
					navigationDestination={"AvailabilityByMachine"}
					color={defaultColor}
					dateRange={dateOptions}
					footerText={footerText}
				>
					<AvailabilityByMachine
						reportData={availabilityData}
						dateRange={dateOptions}
						limit={1000}
						color={defaultColor}
						isNewFormat={isBucketData}
						sortByAmount={availabilitySortByAmount}
						sortByMachine={availabilitySortByMachine}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function statusByHour() {
		return (
			<Row bottom>
				<DashboardCard
					key="Machine Status By Hour"
					showHeader={false}
					setInternalNavigation={setInternalNavigation}
					color={defaultColor}
					dateRange={dateOptions}
					footerText={oldFooterText}
				>
					<MachineStatusByHour
						showGraph={showGraph}
						setShowGraph={setShowGraph}
						data={availabilityData}
						dateRange={dateOptions}
						limit={1000}
						isNewFormat={isBucketData}
					/>
				</DashboardCard>
			</Row>
		);
	}

	function errorsByDowntime() {
		// return (
		// 	<Row bottom>
		// 		<DashboardCard
		// 			key="Errors by Downtime"
		// 			title="Errors by Downtime"
		// 			subtitles={["Errors", "Minutes"]}
		// 			setInternalNavigation={setInternalNavigation}
		// 			limit={1000}
		// 			navigationDestination={"ErrorsByDowntime"}
		// 			color={defaultColor}
		// 			dateRange={dateOptions}
		// 		>
		// 			<ErrorsByDowntime reportData={availabilityData} dateRange={dateOptions} limit={1000} color={defaultColor} />
		// 		</DashboardCard>
		// 	</Row>
		// );
	}

	function showOverview() {
		const totalTime = Date.parse(dateOptions.end) - Date.parse(dateOptions.start);
		const navigationDestination = totalTime > 24 * 60 * 60 * 1000 ? "" : "StatusByHour";
		const isAllMachinesSelected = machines.every((m) => m.checked);
		return [
			<Row size="half" key="errors-and-availability-row-1-1">
				<DashboardCard title={<ErrorIcon height={40} width={40} />} dateRange={dateOptions}>
					<TotalErrors reportData={errorData} dateRange={dateOptions} isNewFormat={isBucketData} />
				</DashboardCard>
			</Row>,
			<Row size="half" key="errors-and-availability-row-1-2">
				<DashboardCard title={<TrophyIcon height={40} width={40} />} dateRange={dateOptions} footerText={footerText}>
					<SiteAvailabilityScore
						reportData={availabilityData}
						dateRange={dateOptions}
						isNewFormat={isBucketData}
						isAllMachinesSelected={isAllMachinesSelected}
					/>
				</DashboardCard>
			</Row>,
			<Row key="errors-and-availability-row-2">
				<DashboardCard
					showHeader={false}
					dateRange={dateOptions}
					limit={6}
					navigationDestination={navigationDestination}
					setInternalNavigation={setInternalNavigation}
					footerText={oldFooterText}
				>
					<MachineStatusByHour
						showGraph={showGraph}
						setShowGraph={setShowGraph}
						data={availabilityData}
						dateRange={dateOptions}
						limit={6}
						isNewFormat={isBucketData}
					/>
				</DashboardCard>
			</Row>,
			<Row size="half" key="errors-and-availability-row-3-1">
				<DashboardCard
					key="Avg. Errors Per Hour by Machine"
					title="Average Errors Per Hour by Machine"
					subtitles={subtitles.avgErrors}
					setInternalNavigation={setInternalNavigation}
					limit={6}
					navigationDestination={"AverageErrorsPerHourByMachine"}
					color={defaultColor}
					dateRange={dateOptions}
				>
					<AverageErrorsPerHourByMachine
						reportData={errorData}
						dateRange={dateOptions}
						limit={6}
						color={defaultColor}
						isNewFormat={isBucketData}
						sortByAmount={avgErrorsSortByAmount}
						sortByMachine={avgErrorsSortByMachine}
					/>
				</DashboardCard>
				<DashboardCard title="Top 6 Errors" dateRange={dateOptions} subtitles={["Error Name", "Error Count"]}>
					<TopErrors reportData={errorData} dateRange={dateOptions} color={defaultColor} isNewFormat={isBucketData} />
				</DashboardCard>
				<DashboardCard
					title="Top Errors by Machine"
					dateRange={dateOptions}
					subtitles={["Error Name", "Error Count"]}
					limit={6}
					navigationDestination={"ErrorsByMachine"}
					setInternalNavigation={setInternalNavigation}
				>
					<TopErrorsByMachine
						reportData={errorData}
						dateRange={dateOptions}
						color={defaultColor}
						limit={6}
						isNewFormat={isBucketData}
					/>
				</DashboardCard>
			</Row>,
			<Row size="half" key="errors-and-availability-row-3-2">
				<DashboardCard
					key="Site Availability Status"
					title="Site Availability Status"
					dateRange={dateOptions}
					footerText={footerText}
				>
					<SiteAvailabilityStatus reportData={availabilityData} dateRange={dateOptions} isNewFormat={isBucketData} />
				</DashboardCard>
				<DashboardCard
					key="Availability by Machine"
					title="Availability by Machine"
					subtitles={subtitles.availability}
					setInternalNavigation={setInternalNavigation}
					limit={6}
					navigationDestination={"AvailabilityByMachine"}
					color={defaultColor}
					dateRange={dateOptions}
					isNewFormat={isBucketData}
					footerText={footerText}
				>
					<AvailabilityByMachine
						reportData={availabilityData}
						dateRange={dateOptions}
						limit={6}
						color={defaultColor}
						isNewFormat={isBucketData}
						sortByAmount={availabilitySortByAmount}
						sortByMachine={availabilitySortByMachine}
					/>
				</DashboardCard>
				{/* <DashboardCard
						key="Errors by Downtime"
						title="Errors by Downtime"
						subtitles={["Errors", "Minutes"]}
						setInternalNavigation={setInternalNavigation}
						limit={6}
						navigationDestination={"ErrorsByDowntime"}
						color={defaultColor}
						dateRange={dateOptions}
					>
						<ErrorsByDowntime reportData={errorData} dateRange={dateOptions} limit={6} color={defaultColor} />
					</DashboardCard> */}
			</Row>,
		];
	}

	function showDashboard() {
		switch (internalNavigation) {
			case "":
				return showOverview();
			case "ErrorsByMachine":
				return errorsByMachine();
			case "AverageErrorsPerHourByMachine":
				return averageErrorsPerHourByMachine();
			case "AvailabilityByMachine":
				return availabilityByMachine();
			case "StatusByHour":
				return statusByHour();
			case "ErrorsByDowntime":
				return errorsByDowntime();
			default:
				return <div>{intl.formatMessage({ id: "This is not the card you are looking for" })}</div>;
		}
	}

	useEffect(() => {
		if (dateOptions.id) getData(selectedSites, dateOptions);
	}, [dateOptions, selectedSites, machines]);

	const title = "Errors and Availability Overview";

	return (
		<ExperienceContainer
			currentUser={currentUser}
			header={title}
			backMessage={`Back to ${title}`}
			dateOptions={dateOptions}
			setDateOptions={setDateOptions}
			internalNavigation={internalNavigation}
			setInternalNavigation={setInternalNavigation}
			machines={machines}
			setMachines={setMachines}
			isLoading={isLoading}
			noData={
				(isBucketData
					? errorData.topErrors.length === 0 && availabilityData.byMachine.length === 0
					: errorData.length === 0 && availabilityData.length === 0) || machines.every((m) => !m.checked)
			}
		>
			{showDashboard()}
		</ExperienceContainer>
	);
}
export default errorBoundary(ErrorsAndAvailability);

import { format, isValid } from "date-fns";

export function sanitizeCsvOutput(value) {
	value = value.toString();
	value = value.replaceAll(`"`, `"`);
	value = value.replaceAll('"', '""');
	value = value.replaceAll(`\r\n`, " ");
	value = value.replaceAll(`\n`, " ");
	return `'${value}`;
}

export function formatDateTime(value) {
	const date = new Date(value.split(".")[0]);
	if (isValid(date)) {
		return format(date, "yyyy-MM-dd HH:mm:ss");
	} else return "Invalid Date";
}

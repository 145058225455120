import { createUseStyles, useTheme } from "react-jss";

import { FormattedMessage } from "react-intl";
import { Loader } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	loading: {
		height: "200px",
		display: "flex",
		alignItems: "center",
	},
}));

const Loading = (props) => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<div className={`${classes.loading} ${props.className}`}>
			<Loader active inline="centered" data-cy="loader" data-testid="loader">
				<FormattedMessage id={props.messageId || "Loading"} />
			</Loader>
		</div>
	);
};

export default Loading;

import { createUseStyles } from "react-jss";
import { getNumericAbbreviation } from "@/views/reporting/functions/swimlanes";

const useStyles = createUseStyles(() => ({
	content: {
		display: "flex",
		flexDirection: "column",
		position: "relative",
	},
	line: {
		position: "absolute",
		bottom: "8px",
		borderLeft: "1px dotted #e3e7e9",
	},
	line0: {
		composes: ["$line"],
		left: "0%",
	},
	line25: {
		composes: ["$line"],
		left: "25%",
	},
	line50: {
		composes: ["$line"],
		left: "50%",
	},
	line75: {
		composes: ["$line"],
		left: "75%",
	},
	line100: {
		composes: ["$line"],
		right: "0",
	},
	percentile: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		color: "#7c7b79",
		paddingBottom: "32px",
		"& > span": {
			width: "50px",
			textAlign: "center",
		},
		"& > span:first-child, span:nth-child(2)": {
			textAlign: "left",
		},
		"& > span:last-child, span:nth-child(4)": {
			textAlign: "right",
		},
	},
}));

function SwimLanes({ upperBound, numberOfHeadings, children }) {
	const classes = useStyles();
	const oneFourth = getNumericAbbreviation(upperBound / 4);
	const oneHalf = getNumericAbbreviation(upperBound / 2);
	const threeFourth = getNumericAbbreviation(upperBound - upperBound / 4);
	const fullFourth = getNumericAbbreviation(upperBound);

	function getTopInPixels(numberOfHeadings) {
		switch (numberOfHeadings) {
			case 2:
				return "84px";
			case 1:
				return "44px";
			default:
				return "4px";
		}
	}

	const topInPixels = getTopInPixels(numberOfHeadings);
	const topStyle = {
		top: topInPixels,
	};

	return (
		<div>
			<div className={classes.content}>
				<span className={classes.line0} style={topStyle}></span>
				<span className={classes.line25} style={topStyle}></span>
				<span className={classes.line50} style={topStyle}></span>
				<span className={classes.line75} style={topStyle}></span>
				<span className={classes.line100} style={topStyle}></span>
				{children}
			</div>
			<div className={classes.percentile}>
				<span>0</span>
				<span>{oneFourth}</span>
				<span>{oneHalf}</span>
				<span>{threeFourth}</span>
				<span>{fullFourth}</span>
			</div>
		</div>
	);
}

export default SwimLanes;

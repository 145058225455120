import useScanToCreateInput from "@/views/production/job-queue/use-scan-input";
import useScanToTriggerInput from "@/views/production/box-last/use-scan-to-trigger";
import { ProductionContext } from "@/contexts/production-context";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { Input } from "semantic-ui-react";
import {
	isScanToTriggerConfigured,
	scanToProduceOptions,
} from "@/views/configuration/production-groups/manual-scan-to-produce-dropdown";
import styled from "styled-components";
import { machineStatuses } from "@/components/machine-status";

const StyledInput = styled(Input)`
	min-width: 320px;
`;

function ScanInput({ triggerJobByLPN }) {
	const intl = useIntl();
	const { currentMachineGroup, currentProductionGroup } = useContext(ProductionContext);
	const machineInErrorState = currentMachineGroup.status === machineStatuses.error;

	const scanToTriggerConfigured = isScanToTriggerConfigured(currentProductionGroup.manualScanToProduce);
	const scanToCreateConfigured = currentProductionGroup.manualScanToProduce === scanToProduceOptions.scanToCreate;

	const {
		inputRef: scanToCreateInputRef,
		onKeyUp: onScanToCreateInputKeyUp,
		isLoading: isScanToCreateInputLoading,
	} = useScanToCreateInput({ currentMachineGroup, historyExpanded: false });

	const {
		inputRef: scanToTriggerInputRef,
		onKeyUp: onScanToTriggerInputKeyUp,
		isLoading: isScanToTriggerInputLoading,
	} = useScanToTriggerInput({ currentMachineGroup, triggerJobByLPN, historyExpanded: false });

	return (
		<>
			{scanToCreateConfigured && !machineInErrorState && (
				<StyledInput
					loading={isScanToCreateInputLoading}
					disabled={isScanToCreateInputLoading}
					input={{ ref: scanToCreateInputRef, onKeyUp: onScanToCreateInputKeyUp }}
					placeholder={intl.formatMessage({ id: "Ready to scan" }) + "..."}
					autoFocus
				/>
			)}
			{scanToTriggerConfigured && !machineInErrorState && (
				<StyledInput
					loading={isScanToTriggerInputLoading}
					disabled={isScanToTriggerInputLoading}
					input={{ ref: scanToTriggerInputRef, onKeyUp: onScanToTriggerInputKeyUp }}
					placeholder={intl.formatMessage({ id: "Ready to scan" }) + "..."}
					autoFocus
				/>
			)}
		</>
	);
}

export default ScanInput;

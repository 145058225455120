import { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import DimDataContext from "../contexts/dimensioning-data-context";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import { Grid, Input } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	dimensionParameters: {
		display: "flex !important",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "flex-start",
		alignContent: "stretch",
		"& .field": {
			marginRight: "1rem !important",
			width: "7em",

			"& input": {
				textAlign: "center",
			},
		},
		paddingTop: "1em",
	},
	inputErrorIcon: {
		top: "26px",
		right: "11px",
		position: "absolute",
	},
	prompt: {
		paddingBottom: "8px",
		size: "16px",
	},
	titleText: {
		paddingBottom: "0",
	},
}));

export const distoUnits = {
	" in": 0,
	" mm": 0,
};

export function isDistoInput(input) {
	return input && input.length > 3 && input.substring(input.length - 3).toLowerCase() in distoUnits;
}

const DimensioningDimensions = ({
	prompt,
	defaultPrompt,
	callback,
	errors,
	value,
	isEdit,
	inputRefs = null,
	activeStep = 0,
}) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);
	const [state, setState] = useState(value ?? { length: 0, width: 0, height: 0 });
	let refs = null;

	useEffect(() => {
		if (callback) callback(state);
	}, [state]);

	if (inputRefs) {
		refs = inputRefs;
	}

	function adjustDistoValue(value) {
		let newValue = value;
		if (isDistoInput(newValue)) {
			const dim_split = newValue.split(" ");
			dim_split[0] -= dimCtx.customerConfiguration.productionSettings.distoOffset;
			newValue = dim_split[0];
		}
		return newValue;
	}

	return (
		<Grid>
			<div className={classes.titleText}>{isEdit ? defaultPrompt : prompt ? prompt : defaultPrompt}</div>
			<Grid.Row className={classes.dimensionParameters}>
				<Grid.Column width={isEdit ? 2 : 4}>
					<div className={classes.prompt}>Length*</div>
					<Input
						icon
						min={0}
						fluid
						placeholder="Length"
						value={state.length}
						onChange={(_, { value }) => {
							setState({ ...state, length: value });
						}}
						error={!!errors.length}
						ref={(ref) => {
							if (refs) refs.current[activeStep] = ref;
						}}
						onBlur={(event) => {
							const newValue = adjustDistoValue(event.target.value);
							setState({ ...state, length: newValue });
						}}
					>
						<input />
						{errors.length && (
							<i className="icon">
								<Error className={classes.inputErrorIcon} />
							</i>
						)}
					</Input>
				</Grid.Column>
				<Grid.Column width={isEdit ? 2 : 4}>
					<div className={classes.prompt}>Width*</div>
					<Input
						icon
						min={0}
						fluid
						placeholder="Width"
						value={state.width}
						onChange={(_, { value }) => {
							setState({ ...state, width: value });
						}}
						error={!!errors.width}
						onBlur={(event) => {
							const newValue = adjustDistoValue(event.target.value);
							setState({ ...state, width: newValue });
						}}
					>
						<input />
						{errors.width && (
							<i className="icon">
								<Error className={classes.inputErrorIcon} />
							</i>
						)}
					</Input>
				</Grid.Column>
				<Grid.Column width={isEdit ? 2 : 4}>
					<div className={classes.prompt}>Height*</div>
					<Input
						icon
						min={0}
						fluid
						placeholder="Height"
						value={state.height}
						onChange={(_, { value }) => {
							setState({ ...state, height: value });
						}}
						onBlur={(event) => {
							const newValue = adjustDistoValue(event.target.value);
							setState({ ...state, height: newValue });
						}}
						error={!!errors.height}
					>
						<input />
						{errors.height && (
							<i className="icon">
								<Error className={classes.inputErrorIcon} />
							</i>
						)}
					</Input>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default DimensioningDimensions;

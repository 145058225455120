import { Accordion, Button, Divider, Form, Grid, Header, Icon, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { Fragment, useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { SmallRegular } from "@/components/typography";

import CheckboxButton from "@/components/checkbox-button";
import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import { UserContext } from "@/components/user-context";
import { fetchProductionGroupAliasByMachineGroup } from "@/api/production-group-api";
import { readLocalStorage } from "@/api/local-storage";
import UnorderedCheckbox from "./unordered-checkbox";
import OrderedCheckbox from "./ordered-checkbox";
import useAxios from "@/api/useAxios";
import ManualScanToProduceDropDown, { isScanToTriggerConfigured } from "./manual-scan-to-produce-dropdown";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	header: {
		...theme.configurationDialog.header,
		paddingBottom: "0 !important",
		paddingLeft: "1.5rem !important",
		"& h2": {
			paddingTop: "0 !important",
		},
	},
	headerText: {
		...theme.configurationDialog.headerText,
		width: "unset",
	},
	headerButtons: {
		display: "flex",
		"& button:first-child": {
			marginRight: "3rem !important",
		},
	},
	column: {
		paddingBottom: "0 !important",
	},
	columnHeader: {
		...theme.typography.bodyBold,
		paddingBottom: "4px !important",
	},
	text: {
		...theme.typography.bodyBold,
	},
	dim: {
		opacity: ".5",
	},
	mgs: {
		marginTop: "10px !important",
		marginBottom: "10px !important",

		...theme.typography.bodyBold,
	},
	zFold: {
		width: "100% !important",
		paddingLeft: "35px !important",
		paddingRight: "35px !important",
	},
	zFolds: {
		marginTop: "10px !important",
		marginBottom: "10px !important",
		...theme.typography.bodyBold,
	},
	halfHeightGroup: {
		height: "50%",
	},
	filters: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		"& div": {
			minHeight: "40px !important",
			alignItems: "center",
		},
	},
	sortBy: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		"& div": {
			minHeight: "40px !important",
			alignItems: "center",
		},
	},
	rules: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		"& div": {
			minHeight: "40px !important",
			alignItems: "center",
		},
	},
	fullHeightGroup: {
		height: "calc(100% - 30px)",
	},
	rowGroup: {
		height: "calc(100% - 30px)",
		maxHeight: "calc(100% - 30px)",
		backgroundColor: theme.colors.white,
		borderRadius: "6px",
		marginLeft: "-8px",
		"& .row": {
			marginLeft: "1rem",
			marginRight: "1rem",
		},
		overflowY: "auto",
	},
	pipelineGroup: {
		height: "100%",
		maxHeight: "100%",
		backgroundColor: theme.colors.white,
		borderRadius: "6px",
		marginLeft: "-8px",
		marginRight: "8px",
		"& .row": {
			marginLeft: "1rem",
			marginRight: "1rem",
		},
	},
	pause: {
		marginTop: "10px !important",
		marginBottom: "10px !important",
	},
	distribute: {
		marginTop: "10px !important",
		marginBottom: "10px !important",
	},
	assignedTo: {
		marginLeft: "20px",
		fontStyle: "italic",
	},
	form: {
		...theme.configurationDialog.form,
		paddingBottom: "0 !important",
		height: "calc(100vh - 170px)",
		"& form": {
			height: "100%",
		},
		"& form > .grid": {
			height: "100%",
		},
		"& form > .grid > .column": {
			height: "100%",
		},
	},
	accordian: {
		height: "100%",
		paddingTop: "8px",
		"& .content.active": {
			height: "calc(100% - 130px)",
			overflowY: "auto",
		},
		"& .title": {
			backgroundColor: "#e3e7e9",
			marginTop: "2px",
			paddingLeft: "16px !important",
		},
		"& .content": {
			paddingLeft: "16px !important",
		},
		"& .title > .dropdown.icon": {
			marginRight: "8px !important",
			width: "24px !important",
		},
		"& .active.title > .dropdown.icon": {
			transform: "rotate(90deg) !important",
			marginRight: "8px !important",
			width: "24px !important",
		},
	},
	note: {
		...theme.typography.body,
		marginLeft: "24px",
	},
}));

const categoryNames = Object.freeze({
	filters: "filterSteps",
	sorts: "sortSteps",
	rules: "rulesSteps",
});

const filterNames = Object.freeze({
	byReleasedForProductionDate: "FilterByReleasedForProductionDate",
	byOptimalProduction: "FilterByOptimalProduction",
	byPackStation: "FilterByPackStation",
	byStoppedCartonPropertyGroup: "FilterByStoppedCartonPropertyGroup",
	byStoppedClassification: "FilterByStoppedClassification",
	byStoppedPickZone: "FilterByStoppedPickZone",
});

const sortNames = Object.freeze({
	byCartonPropertyGroup: "SortByCartonPropertyGroup",
	byClassification: "SortByClassification",
	byCreateByDate: "SortByCreateByDate",
	byImportDate: "SortByImportDateAscending",
	byMachineGroupExclusivity: "SortByMachineGroupExclusivity",
	byMachineOptimalProductionExclusivity: "SortByMachineOptimalProductionExclusivity",
	byPriorityAscending: "SortByPriority",
	byPriorityDescending: "SortByPriorityDescending",
	byReleasedForProductionDateAscending: "SortByReleasedForProductionDateAscending",
	byDateAttribute: "SortByDateAttribute",
	byCartonPropertyGroupRatio: "SortByCartonPropertyGroupRatio",
});

const ruleNames = Object.freeze({
	pauseBetweenJobs: "PauseBetweenJobs",
	allowDistribution: "AllowDistribution",
	tileAcrossBatches: "TileAcrossBatches",
	tileWithinBatches: "TileWithinBatches",
	failAfterRetries: "FailAfterRetries",
});

const NewProductionGroup = (props) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const { closeModal } = props;
	const [errors, setErrors] = useState({});
	const [accordianIndex, setAccordianIndex] = useState(0);
	const [machineGroups, setMachineGroups] = useState([]);
	const [corrugates, setCorrugates] = useState([]);
	const [pipelines, setPipelines] = useState([]);
	const { setList, edit, setEdit } = useContext(ListContext);
	const { currentUser } = useContext(UserContext);
	const useAssignPgToCpgs = currentUser["ff-cpg-allow-pg-assignment"];
	const [productionGroupLookup, setProductionGroupLookup] = useState(null);
	const [originalConfiguredMgs, setOriginalConfiguredMgs] = useState([]);
	const [productionGroups, setProductionGroups] = useState([]);

	const productionGroupApi = useAxios("/ProductionGroupApi/api/v1/ProductionGroups", token);
	const machineGroupsApi = useAxios("/MachineGroupApi/api/v1/MachineGroups", token);
	const corrugatesApi = useAxios("/CorrugateApi/api/v1/corrugates", token);

	const selectionPipelineApi = useAxios("/SelectionPipelineApi/api/v1/SelectionPipelineConfigurations", token);

	useEffect(() => {
		if (Object.keys(edit).length === 0) {
			setEdit({
				selectionAlgorithm: "Order",
				configuredMachineGroups: [],
				configuredCorrugates: [],
				pipeline: { [categoryNames.filters]: [], [categoryNames.sorts]: [], [categoryNames.rules]: [] },
			});

			setOriginalConfiguredMgs([]);
		} else {
			setOriginalConfiguredMgs([...edit.configuredMachineGroups]);
		}

		productionGroupApi.get((data) => setProductionGroups(data));
		selectionPipelineApi.get((data) => setPipelines(data));
		machineGroupsApi.get((data) => setMachineGroups(data));
		corrugatesApi.get((data) => {
			data.sort((a, b) => (a.alias > b.alias ? 1 : -1));
			setCorrugates(data);
		});
	}, []);

	useEffect(() => {
		if (!edit.pipelineId || !pipelines.length) return;
		const pipeline = pipelines.filter((p) => p.id === edit.pipelineId);
		setEdit({
			...edit,
			pipeline: pipeline.length
				? pipeline[0].dataPipeline
				: { [categoryNames.filters]: [], [categoryNames.sorts]: [], [categoryNames.rules]: [] },
		});
	}, [pipelines]);

	useEffect(() => {
		if (machineGroups.length > 0 && productionGroupLookup === null) {
			loadProductionGroupLookup();
		}
	}, [machineGroups]);

	const loadProductionGroupLookup = async () => {
		let localProductionGroupLookup = {};
		for (let i in machineGroups) {
			let machine = machineGroups[i];
			let productionGroup = await fetchProductionGroupAliasByMachineGroup(machine.id, token);
			if (productionGroup !== undefined) {
				localProductionGroupLookup[machine.id] = productionGroup;
			}
		}
		setProductionGroupLookup(localProductionGroupLookup);
	};

	const validate = () => {
		const newErrors = {};
		if (!edit.alias) newErrors.alias = intl.formatMessage({ id: "Name is required" });
		setErrors(newErrors);
		return newErrors;
	};

	const close = () => {
		closeModal();
	};

	const saveAndClose = async () => {
		const errors = validate();
		if (Object.keys(errors).length) return;

		try {
			delete edit.status;
			edit.pipeline.name = edit.alias;
			if (edit.id) {
				if (edit.pipelineId && edit.pipelineId !== "00000000-0000-0000-0000-000000000000") {
					await selectionPipelineApi.updateWithId(
						{ id: edit.pipelineId, tenantId: currentUser.Tenant, dataPipeline: edit.pipeline },
						async () => {
							await productionGroupApi.updateWithId(
								edit,
								(data) => {
									setList(data);
									close();
								},
								(e) => handleProductionGroupErrors(e, setErrors, errors, intl, edit),
							);
						},
					);
				} else {
					await selectionPipelineApi.add(
						{ tenantId: currentUser.Tenant, dataPipeline: edit.pipeline },
						async (response) => {
							edit.pipelineId = response.filter((r) => r.dataPipeline.name === edit.alias)[0].id;
							await productionGroupApi.updateWithId(
								edit,
								(data) => {
									setList(data);
									close();
								},
								(e) => handleProductionGroupErrors(e, setErrors, errors, intl, edit),
							);
						},
					);
				}
			} else {
				await selectionPipelineApi.add({ dataPipeline: edit.pipeline }, async (response) => {
					edit.pipelineId = response.filter((r) => r.dataPipeline.name === edit.alias)[0].id;
					await productionGroupApi.add(
						edit,
						(data) => {
							setList(data);
							close();
						},
						(e) => handleProductionGroupErrors(e, setErrors, errors, intl, edit),
					);
				});
			}
		} catch (e) {
			setErrors({
				...errors,
				addOrUpdate: intl.formatMessage({
					id: "Failed to save Production Group",
				}),
			});
		}
	};

	const handleProductionGroupErrors = (e, setErrors, errors, intl, edit) => {
		if (e.response && e.response.status === 409) {
			if (e.response.data === "UniqueTenantAlias")
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage(
						{
							id: "Failed to save Production Group: An item already exists with the name {name}",
						},
						{ name: edit.alias },
					),
				});
			else
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage({
						id: "Failed to save Production Group: A selected machine group already belongs to another Production Group",
					}),
				});
		} else
			setErrors({
				...errors,
				addOrUpdate: intl.formatMessage({
					id: "Failed to save Production Group",
				}),
			});
	};

	const updateSelection = (checked, category, name, configuration) => {
		if (checked) {
			if (configuration) edit.pipeline[category].push({ name, configuration });
			else edit.pipeline[category].push({ name });
		} else edit.pipeline[category] = edit.pipeline[category].filter((step) => step.name !== name);
		setEdit({ ...edit });
	};

	const filterSteps = [
		{
			label: "By Released For Production",
			name: filterNames.byReleasedForProductionDate,
		},
		{
			label: "By Optimal Production",
			name: filterNames.byOptimalProduction,
			configuration: {
				UseV2: Boolean(currentUser["ff-optimal-production-v2"]),
			},
		},
		{
			label: "By Pack Station",
			name: filterNames.byPackStation,
		},
		{
			label: "By Stopped Carton Property Group",
			name: filterNames.byStoppedCartonPropertyGroup,
		},
		{
			label: "By Stopped Classification",
			name: filterNames.byStoppedClassification,
		},
		{
			label: "By Stopped Pick Zone",
			name: filterNames.byStoppedPickZone,
		},
	];

	const mgCanBeSelected = (machineGroup) => {
		if (machineGroup.productionEnabled) return false;

		const partOfThisMg = originalConfiguredMgs.includes(machineGroup.id);
		if (partOfThisMg) return true;

		// Check if this MG is assigned to another PG
		if (productionGroups) {
			const matchingPgs = productionGroups.filter((pg) => pg.configuredMachineGroups.includes(machineGroup.id));
			return matchingPgs.length === 0;
		}
		return false;
	};

	const sortSteps = [
		{
			label: "By Carton Property Group Status",
			name: sortNames.byCartonPropertyGroup,
		},
		{
			label: "By Classification Status",
			name: sortNames.byClassification,
		},
		{
			label: "By Create By Date",
			name: sortNames.byCreateByDate,
		},
		//"By Date"
		{
			label: "By Import Date",
			name: sortNames.byImportDate,
		},
		{
			label: "By Machine Group Exclusivity",
			name: sortNames.byMachineGroupExclusivity,
		},
		{
			label: "By Optimal Production Exclusivity",
			name: sortNames.byMachineOptimalProductionExclusivity,
		},
		{
			label: "By Priority Ascending",
			name: sortNames.byPriorityAscending,
			conflictsWith: [sortNames.byPriorityDescending],
			configuration: {
				SortAscending: true,
			},
		},
		{
			label: "By Priority Descending",
			name: sortNames.byPriorityDescending,
			conflictsWith: [sortNames.byPriorityAscending],
			configuration: {
				SortAscending: false,
			},
		},
		{
			label: "By Released For Production Date",
			name: sortNames.byReleasedForProductionDateAscending,
		},
		{
			label: "By Group Id Date Time",
			name: sortNames.byDateAttribute,
			configuration: {
				AttributeName: "GroupIdTimeStamp",
				Descending: false,
				InvalidDatesUseMinValue: false,
			},
		},
	];

	if (useAssignPgToCpgs) {
		sortSteps.unshift({
			label: "By Carton Property Group Ratio",
			name: sortNames.byCartonPropertyGroupRatio,
		});
	}

	const rulesSteps = [
		{ label: "Pause between jobs", name: ruleNames.pauseBetweenJobs },
		{
			label: "Allow distribution of work across machine groups",
			name: ruleNames.allowDistribution,
		},
		{
			label: "Tile Across Batches",
			name: ruleNames.tileAcrossBatches,
			conflictsWith: [ruleNames.tileWithinBatches],
		},
		{
			label: "Tile Within Batches",
			name: ruleNames.tileWithinBatches,
			conflictsWith: [ruleNames.tileAcrossBatches],
		},
		{ label: "Cancel Production After 3 Failed Attempts", name: ruleNames.failAfterRetries },
	];

	const sortListByConfiguration = (list, configuredSteps) => {
		if (!configuredSteps || configuredSteps.length === 0) return list;

		// Removing selected items in order to make a sorted separate list
		const selected = [];
		configuredSteps.forEach((element) => {
			const found = list.find((selected) => selected.name === element.name);
			const index = list.indexOf(found);
			list.splice(index, 1);
			selected.push(found);
		});

		// Add not selected items after the selected items
		return selected.concat(list);
	};

	const getAssignedTo = (mgId) => {
		if (productionGroupLookup !== null && mgId in productionGroupLookup) {
			let productionGroup = productionGroupLookup[mgId];
			return (
				<Fragment>
					<FormattedMessage id={`Assigned to:`} />
					&nbsp;
					{productionGroup}
				</Fragment>
			);
		}
	};

	const shouldAddReleasedForProductionFilter = (value) =>
		isScanToTriggerConfigured(value) &&
		!edit.pipeline[categoryNames.filters].some((x) => x.name === filterNames.byReleasedForProductionDate);

	return Object.keys(edit).length === 0 ? (
		<Fragment />
	) : (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" textAlign="left" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit Production Group" : "New Production Group"} />
				</Header>
				<Header floated="right" className={classes.headerButtons}>
					<Button primary onClick={saveAndClose}>
						<FormattedMessage id="Save" />
					</Button>
					<CloseButton onClick={close} />
				</Header>
			</Segment>
			<Segment className={classes.form}>
				<Form error>
					<Grid relaxed="very" stackable divided>
						<Grid.Column width={4} className={classes.column}>
							<div style={{ paddingBottom: 56 }}>
								<Form.Input
									required
									label={
										<SmallRegular>
											<FormattedMessage id="Name" />
										</SmallRegular>
									}
									placeholder={intl.formatMessage({ id: "Name" })}
									value={edit.alias}
									onChange={(e) => setEdit({ ...edit, alias: e.target.value })}
									error={errors.alias ? { content: errors.alias } : null}
									icon
								>
									<input />
									{errors.alias && (
										<i className="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
								<Form.TextArea
									label={
										<SmallRegular>
											<FormattedMessage id="Description" />
										</SmallRegular>
									}
									placeholder={intl.formatMessage({ id: "Description" })}
									value={edit.description ?? ""}
									onChange={(e) => setEdit({ ...edit, description: e.target.value })}
								/>
							</div>
							<Divider />
							<ManualScanToProduceDropDown
								selectedOption={edit.manualScanToProduce}
								handleChange={(value) => {
									// if option is "Scan to trigger", auto-select the "By Released For Production" filter step
									if (shouldAddReleasedForProductionFilter(value)) {
										updateSelection(true, categoryNames.filters, filterNames.byReleasedForProductionDate, null);
									}

									setEdit({ ...edit, manualScanToProduce: value });
								}}
							/>
						</Grid.Column>
						<Grid.Column width={4} className={classes.column}>
							<div className={classes.halfHeightGroup}>
								<Grid.Row className={classes.columnHeader}>
									<SmallRegular>
										<FormattedMessage id="z-Folds" />
									</SmallRegular>
								</Grid.Row>
								<div className={classes.rowGroup}>
									{corrugates.map((corrugate) => (
										<Grid.Row key={corrugate.id}>
											<CheckboxButton
												className={classes.zFolds}
												defaultChecked={edit.configuredCorrugates.includes(corrugate.id)}
												onChange={(e) => {
													if (e.target.checked)
														setEdit({
															...edit,
															configuredCorrugates: [...edit.configuredCorrugates, corrugate.id],
														});
													else {
														setEdit({
															...edit,
															configuredCorrugates: edit.configuredCorrugates.filter((i) => i !== corrugate.id),
														});
													}
												}}
											>
												{corrugate.alias}
											</CheckboxButton>
										</Grid.Row>
									))}
								</div>
							</div>
							<div className={classes.halfHeightGroup}>
								<Grid.Row className={classes.columnHeader}>
									<span className={classes.text}>
										<SmallRegular>
											<FormattedMessage id="Machine Groups" />
										</SmallRegular>
									</span>
								</Grid.Row>
								<div className={classes.rowGroup}>
									{machineGroups.map((mg) => (
										<Grid.Row key={mg.id}>
											<CheckboxButton
												disabled={mgCanBeSelected(mg) ? "" : "disabled"}
												className={`${classes.mgs} ${mgCanBeSelected(mg) ? "" : classes.dim}`}
												defaultChecked={edit.configuredMachineGroups.includes(mg.id)}
												onChange={(e) => {
													if (e.target.checked) {
														setEdit({
															...edit,
															configuredMachineGroups: [...edit.configuredMachineGroups, mg.id],
														});
													} else {
														setEdit({
															...edit,
															configuredMachineGroups: edit.configuredMachineGroups.filter((i) => i !== mg.id),
														});
													}
												}}
											>
												{mg.alias}
											</CheckboxButton>
											<div className={`${classes.assignedTo} ${mgCanBeSelected(mg) ? "" : classes.dim}`}>
												{getAssignedTo(mg.id)}
											</div>
											{mg.productionEnabled && (
												<div className={`${classes.dim} ${classes.assignedTo}`}>
													<FormattedMessage id="Production Enabled" />
												</div>
											)}
										</Grid.Row>
									))}
								</div>
							</div>
						</Grid.Column>
						<Grid.Column width={8} className={classes.column}>
							<div className={classes.fullHeightGroup}>
								<Grid.Row className={classes.columnHeader}>
									<SmallRegular>
										<FormattedMessage id="Selection Pipeline" />
									</SmallRegular>
								</Grid.Row>
								<div className={classes.pipelineGroup}>
									<Accordion fluid className={classes.accordian}>
										<Accordion.Title
											active={accordianIndex === 0}
											index={0}
											onClick={(_, { index }) => {
												setAccordianIndex(accordianIndex === index ? -1 : index);
											}}
										>
											<Icon name="dropdown" />
											<FormattedMessage id="Filters" />
										</Accordion.Title>
										<Accordion.Content active={accordianIndex === 0}>
											<div className={classes.filters}>
												{sortListByConfiguration(filterSteps, edit.pipeline?.filterSteps).map((s) => (
													<UnorderedCheckbox
														key={s.name}
														label={s.label}
														category={categoryNames.filters}
														name={s.name}
														pipeline={edit.pipeline}
														handleClick={(e) =>
															updateSelection(e.target.checked, categoryNames.filters, s.name, s.configuration)
														}
													/>
												))}
											</div>
										</Accordion.Content>
										<Accordion.Title
											active={accordianIndex === 1}
											index={1}
											onClick={(_, { index }) => {
												setAccordianIndex(accordianIndex === index ? -1 : index);
											}}
										>
											<Icon name="dropdown" />
											<FormattedMessage id="Sort By" />
											{accordianIndex === 1 && (
												<span className={classes.note}>
													<FormattedMessage id="Note Sort in order of greatest importance" />
												</span>
											)}
										</Accordion.Title>
										<Accordion.Content active={accordianIndex === 1}>
											<div className={classes.sortBy}>
												{sortListByConfiguration(sortSteps, edit.pipeline?.sortSteps).map((s) => (
													<OrderedCheckbox
														key={s.name}
														label={s.label}
														category={categoryNames.sorts}
														name={s.name}
														configuration={s.configuration}
														updatePipeline={(pipeline) => setEdit({ ...edit, pipeline })}
														conflictsWith={s.conflictsWith}
														pipeline={edit.pipeline}
														handleClick={(e) =>
															updateSelection(e.target.checked, categoryNames.sorts, s.name, s.configuration)
														}
													/>
												))}
											</div>
										</Accordion.Content>
										<Accordion.Title
											active={accordianIndex === 2}
											index={2}
											onClick={(_, { index }) => {
												setAccordianIndex(accordianIndex === index ? -1 : index);
											}}
										>
											<Icon name="dropdown" />
											<FormattedMessage id="Rules" />
										</Accordion.Title>
										<Accordion.Content active={accordianIndex === 2}>
											<div className={classes.rules}>
												{sortListByConfiguration(rulesSteps, edit.pipeline?.rulesSteps).map((s) => (
													<UnorderedCheckbox
														key={s.name}
														label={s.label}
														category={categoryNames.rules}
														name={s.name}
														pipeline={edit.pipeline}
														conflictsWith={s.conflictsWith}
														handleClick={(e) =>
															updateSelection(e.target.checked, categoryNames.rules, s.name, s.configuration)
														}
													/>
												))}
											</div>
										</Accordion.Content>
									</Accordion>
								</div>
							</div>
						</Grid.Column>
					</Grid>
					{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
				</Form>
			</Segment>
		</Segment.Group>
	);
};

export default NewProductionGroup;

import { Dropdown, Grid, Header, Icon, Table } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import SaveButton from "@/components/save-button";
import X7Image from "@/assets/images/machine-type/x7.png";
import X7Stop from "@/assets/images/machine-type/X7ZFoldStop.svg";
import X7Proceed from "@/assets/images/machine-type/X7Proceed.svg";
import { readLocalStorage } from "@/api/local-storage";
import { sortObjectsAscending } from "@/utils/sort";
import useAxios from "@/api/useAxios";
import { useIntl } from "react-intl";
import emptyId from "@/constants/emptyId";

const useStyles = createUseStyles((theme) => ({
	...theme?.typography,
	successColor: {
		color: theme?.success,
	},
	warningColor: {
		color: theme?.warning,
	},
	header: {
		...theme?.typography?.subtitle,
		color: theme?.colors?.text,
	},
	subHeader: {
		fontSize: theme?.typography?.body["font-size"] + "!important",
		color: theme?.colors?.text,
	},
	machineHeading: {
		fontSize: theme?.typography?.textSmallBold["font-size"] + "!important",
	},
	machineAlias: {
		marginTop: "0.25rem !important",
	},
	machineStatus: {
		marginTop: "0.625rem !important",
		marginBottom: "2rem !important",
	},
	X7Grid: {
		height: "528px",
	},
	leftBox: {
		borderRadius: "0rem 0.375rem 0.375rem 0rem",
		backgroundColor: theme?.colors?.white,
		paddingTop: "1.5rem",
		paddingBottom: "1.5rem",
		height: "100%",
	},
	rightBox: {
		borderRadius: "0.375rem 0rem 0rem 0.375rem",
		backgroundColor: theme?.colors?.white,
		height: "100%",
	},
	middleBox: {
		borderRadius: "0.375rem",
		paddingTop: "1.5rem",
		backgroundColor: theme?.colors?.white,
		height: "100%",
		"& td": {
			paddingTop: "1.5rem !important",
			paddingBottom: "1.5rem !important",
			overflow: "visible !important",
		},
		"& td:first-child, th:first-child": {
			paddingLeft: "2rem !important",
		},
		"& td:last-child, th:last-child": {
			paddingRight: "3rem !important",
		},
	},
	rightSideIcon: {
		"& i": {
			position: "absolute !important",
			right: "1rem !important",
		},
		"& i.dropdown": {
			top: "0.6rem !important",
		},
		"& i.dont": {
			top: "0.8125rem !important",
		},
		width: "100%",
	},
	columns: {
		...theme?.typography?.textSmallBold,
		color: theme?.colors?.text,
	},
	userPrompt: {
		textAlign: "center",
		paddingTop: "161px !important",
		"& .row": {
			justifyContent: "center !important",
			paddingTop: "0.5rem !important",
			paddingBottom: "0.5rem !important",
		},
	},
	saveButton: {
		right: "2rem",
		position: "absolute",
		top: "10rem",
	},
	centerVertically: {
		display: "flex",
		alignItems: "center",
	},
	trackWidths: {
		color: theme?.colors?.subtleText,
	},
	info: {
		color: theme?.colors?.primary,
		boxShadow: `0em 0em 0em 0.2em inset !important`,
	},
	popup: {
		maxWidth: "500px",
	},
}));

const X7ChangeCorrugate = ({ corrugates, selectedMachine }) => {
	const intl = useIntl();
	const refreshInterval = 2000;
	const [allowChange, setAllowChange] = useState(false);
	const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [originalTracks, setOriginalTracks] = useState([]);
	const [successfulSave, setSuccessfulSave] = useState(false);
	const [tracks, setTracks] = useState(selectedMachine.tracks);
	const token = readLocalStorage("BEARER");
	const x7Api = useAxios("/x7api/api/v1.0/machines", token);
	const theme = useTheme();
	const corrugateWidthToleranceMm = 5; // roughly 0.2"
	const mmPerInch = 25.4;

	const classes = useStyles({ theme });

	const getCorrugateWithinTolerance = (corrugate, machine, track) => {
		var trackMinWidthMm = track.corrugateWidthMm - corrugateWidthToleranceMm;
		var trackMaxWidthMm = track.corrugateWidthMm + corrugateWidthToleranceMm;
		var corrugateWidthMm = corrugate.width;
		if (machine.unitOfMeasurement === "UsCustomary") {
			// here the corrugate is in inches, convert to mm for comparison
			corrugateWidthMm = corrugateWidthMm * mmPerInch;
		}
		return corrugateWidthMm <= trackMaxWidthMm && corrugateWidthMm >= trackMinWidthMm;
	};

	const getTrackSpanText = (machine, track) => {
		var trackMinWidth = track.corrugateWidthMm - corrugateWidthToleranceMm;
		var trackMaxWidth = track.corrugateWidthMm + corrugateWidthToleranceMm;
		if (machine.unitOfMeasurement === "UsCustomary") {
			trackMinWidth = trackMinWidth / mmPerInch;
			trackMaxWidth = trackMaxWidth / mmPerInch;
		}
		var text = trackMinWidth.toFixed(2) + " - " + trackMaxWidth.toFixed(2);

		return <div>{text}</div>;
	};

	const getValidCorrugatesForTrack = (track) => {
		var res = [];
		[
			...corrugates.filter((c) => c.id === "00000000-0000-0000-0000-000000000000"),
			...corrugates.filter((corrugate) => getCorrugateWithinTolerance(corrugate, selectedMachine, track)),
		].map((validCorrugate) =>
			res.push({
				key: `${selectedMachine.alias}-${track.trackNumber}_${validCorrugate.id}`,
				text: validCorrugate.alias,
				value: validCorrugate.id,
			}),
		);
		return res;
	};

	const allowTrackToBeChanged = (track) => {
		if (allowChange === false) return false;
		return true;
	};

	const isValidTrackConfiguration = (track) => {
		if (track.loadedCorrugate === null) return true;

		var validTracks = getValidCorrugatesForTrack(track);
		var result = validTracks.filter((c) => c.value === track.loadedCorrugate.id);
		return result.length > 0;
	};

	const refresh = async () => {
		x7Api.getWithUrl(
			`${selectedMachine.id}`,
			(data) => {
				selectedMachine.status = data.status;
				// the only update we will take from the backend is the corrugate width set on the HMI
				// we want to preserve any selections the user may have made before clicking the Save button
				const existingTracks = Array.from(tracks);
				const incomingTracks = Array.from(data.tracks);
				existingTracks.forEach((t) => {
					const matchingTrack = incomingTracks.find((tr) => tr.trackNumber === t.trackNumber);
					if (matchingTrack?.corrugateWidthMm) {
						t.corrugateWidthMm = matchingTrack.corrugateWidthMm;
						// TODO - convert if necessary
					}
				});
				// now update the backing store for the table
				setTracks(existingTracks);
				setAllowChange(data.status === "Paused" || data.status === "Offline" || data.status === "Error");
			},
			(err) => {
				console.error(err);
			},
		);
	};

	useEffect(() => {
		const f = async () => await refresh();
		f();

		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(refresh, refreshInterval);
			return () => clearInterval(interval);
		}
	}, [refreshInterval]);

	const storeOriginalTracks = (reset = false) => {
		const localtracks = [];
		if (reset || originalTracks.length === 0) {
			tracks.forEach((track) => {
				localtracks.push(track.loadedCorrugate?.id ?? emptyId);
			});
			setOriginalTracks(localtracks);
		}
	};
	useEffect(() => {
		storeOriginalTracks();
	}, [tracks]);

	useEffect(() => {
		checkSaveButtonEnabled();
	}, [originalTracks, successfulSave]);

	useEffect(() => {
		if (successfulSave && !allowChange) {
			setSuccessfulSave(false);
		}
	}, [allowChange]);

	const checkSaveButtonEnabled = () => {
		var modified = false;
		tracks.forEach((trk, idx) => {
			if (!originalTracks[idx]) {
				// if we have nothing to compare with, return (this state hasnt been loaded yet)
				return;
			}
			if ((trk.loadedCorrugate?.id ?? emptyId) !== originalTracks[idx]) {
				modified = true;
			}
		});
		if (successfulSave && modified) {
			setSuccessfulSave(false);
		}
		setSaveButtonEnabled(modified);
	};

	const selectedCorrugateChanged = (corrugateId, track, m) => {
		const corrugate = corrugates.find((c) => c.id === corrugateId);
		if (corrugate !== null || corrugate !== undefined) {
			if (!track.loadedCorrugate) {
				track.loadedCorrugate = {};
			}
			track.loadedCorrugate.alias = corrugate.alias;
			track.loadedCorrugate.id = corrugate.id;
		}
		checkSaveButtonEnabled();
		setTracks(
			sortObjectsAscending([...tracks.filter((tt) => tt.trackNumber !== track.trackNumber), track], "trackNumber"),
		);
	};

	const saveLoadedCorrugate = async () => {
		const updates = [];
		const machine = { ...selectedMachine };
		tracks.forEach((e) => {
			if (e.loadedCorrugate && e.loadedCorrugate.id === emptyId) {
				e.loadedCorrugate = null;
			}
		});
		const request = {
			MachineId: machine.id,
			TenantId: machine.tenantId,
			Corrugates: tracks,
		};
		const task = x7Api.updateWithUrl(
			`${machine.id}/corrugates`,
			request,
			(response) => {
				storeOriginalTracks(true);
				setSuccessfulSave(true);
				setIsSaving(false);
			},
			(err) => {
				setIsSaving(false);
				console.error(err);
			},
		);
		updates.push(task);
		setIsSaving(true);
		await Promise.all(updates);
	};

	const translate = (key) => {
		return intl.formatMessage({ id: key });
	};
	return (
		<>
			<div>
				<SaveButton
					isSaving={isSaving}
					succesfulSave={successfulSave}
					saveButtonEnabled={saveButtonEnabled}
					onClick={saveLoadedCorrugate}
					className={classes.saveButton}
					dataCy="x7-corrugates-save-button"
				/>
			</div>
			<Grid className={classes.X7Grid}>
				<Grid.Row>
					<Grid.Column width={4} className={classes.leftBox}>
						<Grid>
							<Grid.Column width={1} />
							<Grid.Column width={14}>
								<Grid.Row>
									<Header textAlign="left" className={classes.machineHeading}>
										<div className={classes.textSmallBold}>{translate("Machine")}</div>
										<div className={classes.header2 + " " + classes.machineAlias}>{selectedMachine.alias}</div>
										<div className={classes.centerVertically + " " + classes.machineStatus}>
											<Icon
												size="large"
												className={selectedMachine.status === "Online" ? classes.successColor : classes.warningColor}
												name={(selectedMachine.status === "Online" ? "check" : "pause") + " circle"}
											/>
											<span className={classes.textSmallBold}>{translate(selectedMachine.status)}</span>
										</div>
									</Header>
									<img src={X7Image} style={{ width: "100%", maxWidth: "15.3125rem" }} />
								</Grid.Row>
							</Grid.Column>
						</Grid>
					</Grid.Column>
					<Grid.Column width={8}>
						<div key={selectedMachine.id} className={classes.middleBox}>
							<Table fixed singleline="true" basic="very">
								<Table.Header>
									<Table.Row verticalAlign="middle">
										<Table.HeaderCell className={classes.columns} width={3}>
											{translate("Track")}
										</Table.HeaderCell>
										<Table.HeaderCell className={classes.columns} width={5}>
											{translate("Configured Width")}
										</Table.HeaderCell>
										<Table.HeaderCell className={classes.columns} width={8}>
											{translate("Loaded z-Fold")}
										</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{
										/* we must not show tracks that do not exist (i.e. if the machine is configured for 2 tracks, do not show 4)
										 * initially this setting will not exist since we have not connected to the machine yet, so assume 0 tracks
										 */
										tracks
											.filter((t) => t.trackNumber <= selectedMachine.physicalMachineSettings?.trackCount ?? 0)
											.map((track, i) => (
												<Table.Row key={i} verticalAlign="middle">
													<Table.Cell className={classes.columns}>{track.trackNumber}</Table.Cell>
													<Table.Cell className={classes.columns + " " + classes.trackWidths}>
														{getTrackSpanText(selectedMachine, track)}
													</Table.Cell>
													<Table.Cell className={classes.columns}>
														<Dropdown
															key={`${selectedMachine.alias}-${track.trackNumber}`}
															data-cy={`x7-track-${track.trackNumber}`}
															selection
															selectOnNavigation={false}
															value={track.loadedCorrugate?.id ?? emptyId}
															className={classes.rightSideIcon}
															disabled={allowTrackToBeChanged(track) === false}
															icon={allowChange ? "dropdown" : "dont"}
															options={getValidCorrugatesForTrack(track)}
															placeholder={track.loadedCorrugate?.alias ?? ""}
															style={
																isValidTrackConfiguration(track)
																	? {}
																	: { color: "red", border: "1px solid rgb(253 4 4 / 15%)", ".dt": "" }
															}
															onChange={(e, { value }) => selectedCorrugateChanged(value, track, selectedMachine)}
														/>
													</Table.Cell>
												</Table.Row>
											))
									}
								</Table.Body>
							</Table>
						</div>
					</Grid.Column>
					<Grid.Column width={4} className={classes.rightBox}>
						{allowChange === true ? (
							<Grid className={classes.userPrompt}>
								<Grid.Row>
									<img src={X7Proceed} style={{ width: "56px", height: "56px" }} />
								</Grid.Row>
								<Grid.Row>
									<span className={`${classes.textMedium}`}>
										{translate(successfulSave ? "StartMachine" : "Proceed to change z-Fold")}
									</span>
								</Grid.Row>
								<Grid.Row>
									<span className={`${classes.textRegularGrey}`}>
										{translate(
											successfulSave ? "StartMachineContinueProduction" : "Select drop downs to change z-Fold",
										)}
									</span>
								</Grid.Row>
							</Grid>
						) : (
							<Grid className={classes.userPrompt}>
								<Grid.Row>
									<img src={X7Stop} style={{ width: "56px", height: "56px" }} />
								</Grid.Row>
								<Grid.Row>
									<span className={`${classes.textMedium}`}>{translate("StopMachine")}</span>
								</Grid.Row>
								<Grid.Row>
									<span className={`${classes.textRegularGrey}`}>{translate("StopMachineChangeZFold")}</span>
								</Grid.Row>
							</Grid>
						)}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

export default X7ChangeCorrugate;

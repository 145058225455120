import Dropdown from "@/components/dropdown/dropdown";
import TwoLineDropdownItem from "@/components/dropdown/two-line-dropdown-item";
import { SmallBook, SmallerRegular, SmallRegular } from "@/components/typography";
import Flex from "@/components/flex";
import { FormattedMessage } from "react-intl";

export const scanToProduceOptions = Object.freeze({
	none: "None",
	scanToCreate: "ScanToCreate",
	scanToTriggerAll: "ScanToTrigger",
	scanToTriggerSingle: "ScanToTriggerSingle",
});

export const isScanToTriggerConfigured = (value) =>
	value === scanToProduceOptions.scanToTriggerAll || value === scanToProduceOptions.scanToTriggerSingle;

export const isScanToTriggerSingleConfigured = (value) => value === scanToProduceOptions.scanToTriggerSingle;

function ManualScanToProduceDropDown({ selectedOption, handleChange }) {
	const noneText = (
		<SmallBook>
			<FormattedMessage id="None" />
		</SmallBook>
	);

	const options = [
		{ key: scanToProduceOptions.none, text: noneText, value: scanToProduceOptions.none },
		{
			key: scanToProduceOptions.scanToCreate,
			text: <TwoLineDropdownItem mainTextId="Scan to create" subTextId="Produce a job from barcode content" />,
			value: scanToProduceOptions.scanToCreate,
		},
		{
			key: scanToProduceOptions.scanToTriggerAll,
			text: (
				<TwoLineDropdownItem
					mainTextId="Scan to trigger (all)"
					subTextId="LPN trigger value releases matching batches"
				/>
			),
			value: scanToProduceOptions.scanToTriggerAll,
		},
		{
			key: scanToProduceOptions.scanToTriggerSingle,
			text: (
				<TwoLineDropdownItem
					mainTextId="Scan to trigger (single)"
					subTextId="LPN trigger value releases one job in a batch"
				/>
			),
			value: scanToProduceOptions.scanToTriggerSingle,
		},
	];

	return (
		<Flex column gap={8}>
			<label>
				<SmallRegular>
					<FormattedMessage id="Manual scan to produce" />
				</SmallRegular>
			</label>
			<Dropdown
				defaultValue={selectedOption || scanToProduceOptions.none}
				fluid
				selection
				onChange={(_, data) => handleChange(data.value)}
				options={options}
			/>
			{selectedOption && selectedOption !== scanToProduceOptions.none && (
				<SmallerRegular style={{ paddingLeft: 16 }}>
					*
					<FormattedMessage id="Must be configured in import selection" />
				</SmallerRegular>
			)}
		</Flex>
	);
}

export default ManualScanToProduceDropDown;

import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import NumUpDown from "@/components/numeric-up-down/numeric-up-down";
import { isNullOrWhitespace } from "@/utils/form-validation";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	nudContainer: {
		display: "flex",
		"align-items": "baseline",
	},
	nudText: {
		"padding-right": "30px",
	},
}));

const NewClassification = ({ closeModal }) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const [errors, setErrors] = useState({});
	const { setList, edit, setEdit } = useContext(ListContext);
	const { add, updateWithId } = useAxios("/classificationApi/api/v1/classifications", token);

	const validate = () => {
		const newErrors = {};
		if (isNullOrWhitespace(edit.alias)) newErrors.alias = intl.formatMessage({ id: "Name is required" });
		setErrors(newErrors);
		return newErrors;
	};

	const close = () => {
		closeModal();
	};

	const saveAndClose = async () => {
		var errors = validate();
		if (Object.keys(errors).length) return;

		if (edit.number === null) edit.number = 0;

		edit.number = edit.number ? edit.number.toString() : "0";
		delete edit.isPriorityLabel;
		delete edit.numberOfRequests;
		delete edit.builtInClassification;
		delete edit.status;
		try {
			if (edit.id) await updateWithId(edit, setList);
			else await add(edit, setList);

			close();
		} catch (e) {
			if (e.response && e.response.status === 409)
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage(
						{
							id: "Failed to save Classification: An item already exists with the name {name} or number {number}",
						},
						{ name: edit.alias, number: edit.number },
					),
				});
			else
				setErrors({
					...errors,
					addOrUpdate: intl.formatMessage({
						id: "Failed to save Classification",
					}),
				});
		}
	};

	return (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit Classification" : "New Classification"} />
				</Header>
				<Header floated="right" className={classes.closeButton}>
					<CloseButton onClick={close} />
				</Header>
				<Header floated="right" className={classes.saveButton}>
					<Button primary onClick={saveAndClose} data-cy="classification-save-button">
						<FormattedMessage id="Save" />
					</Button>
				</Header>
			</Segment>
			<Segment className={classes.form}>
				<Form error data-cy="add-form">
					<Grid relaxed="very" stackable className={classes.topGrid}>
						<Grid.Row columns={1}>
							<Grid.Column>
								<Form.Input
									required
									label={intl.formatMessage({ id: "Name" })}
									placeholder={intl.formatMessage({ id: "Name" })}
									value={edit.alias}
									onChange={(e) => setEdit({ ...edit, alias: e.target.value })}
									icon
									error={errors.alias ? { content: errors.alias } : null}
									data-cy="classification-name-input"
								>
									<input />
									{errors.alias && (
										<i className="icon">
											<Error className={classes.inputError} />
										</i>
									)}
								</Form.Input>
								<div className={classes.nudContainer}>
									<b className={classes.nudText}>
										<FormattedMessage id="Number" />
									</b>
									<NumUpDown
										number={edit.number == null ? 0 : parseInt(edit.number, 10)}
										minValue={0}
										setValue={(num) => {
											setEdit({ ...edit, number: num });
										}}
										data-cy="classifications-nud"
									/>
								</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
				</Form>
			</Segment>
		</Segment.Group>
	);
};

export default NewClassification;

import { useCallback } from "react";
import { Modal } from "semantic-ui-react";
import DiffView from "@/components/diff-view";
import { useQuery } from "@tanstack/react-query";
import packNetAxios from "@/lib/api-client.js";
import Loading from "@/components/loading";
import { createUseStyles } from "react-jss";
import styled from "styled-components";
import { Close } from "@styled-icons/material-rounded";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router";
import FailedRetry from "@/components/failed-retry";
import { BodyLarge, SmallBook } from "@/components/typography";

const getHistoryData = async ({ queryKey }) => {
	const [entityHistoryUrl] = queryKey;
	if (entityHistoryUrl) {
		return (await packNetAxios.get(`/PipelineAuditApi/api/v1/ConfigurationAudit/${entityHistoryUrl}`)).data;
	}
	return null;
};

const StyledModal = styled(Modal)`
	&& {
		display: flex !important;
	}

	min-height: 40vh;
	flex-direction: column;
`;

const ModalHeader = styled(Modal.Header)`
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	padding-right: 24px !important;
`;

const ModalFooter = styled(Modal.Actions)`
	text-align: left !important;
`;

const CloseIcon = styled(Close)`
	cursor: pointer;
`;

const ModalContent = styled(Modal.Content)`
	padding: 0 !important;
	flex: 1;
`;

const NoHistory = styled.div`
	position: sticky;
	top: 50%;
	transform: translateY(-50%);
	text-align: center;
`;

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
}));

const defaultTransform = (data) => {
	delete data.UserName;
	delete data.EventUtcTimeStamp;

	return data;
};

const EntityHistoryModal = ({
	title,
	entityHistoryUrl,
	onClose,
	autoExpand,
	showTypeColumn,
	actionColumn,
	showActivityColumn,
	transformEntityData,
}) => {
	const isDiffModalOpened = !!entityHistoryUrl;
	const classes = useStyles();

	const {
		data: historyData,
		isLoading,
		isLoadingError,
		refetch,
	} = useQuery({
		queryKey: [entityHistoryUrl],
		queryFn: getHistoryData,
		retryOnMount: true,
	});

	const composedTransform = useCallback(
		(data) => {
			if (transformEntityData) {
				return transformEntityData(defaultTransform(data));
			}
			return defaultTransform(data);
		},
		[transformEntityData],
	);

	return (
		<StyledModal
			size="fullscreen"
			centered={true}
			closeOnEscape={true}
			closeOnDimmerClick={true}
			className={classes.modal}
			open={isDiffModalOpened}
			onClose={onClose}
		>
			<ModalHeader className={classes.title}>
				{title}
				<CloseIcon onClick={onClose} width={36} />
			</ModalHeader>
			<ModalContent scrolling className={classes.content}>
				{!isLoading && !isLoadingError && (
					<>
						{historyData?.length > 0 && (
							<div className={classes.diff}>
								<DiffView
									additionalDataTransform={composedTransform}
									autoExpand={autoExpand}
									data={historyData.slice(0, 10)}
									showTypeColumn={showTypeColumn}
									showActivityColumn={showActivityColumn}
									actionColumn={actionColumn}
								/>
							</div>
						)}

						{!historyData?.length && (
							<NoHistory>
								<BodyLarge>
									<FormattedMessage id="No audit history exists for this item" />
								</BodyLarge>
							</NoHistory>
						)}
					</>
				)}

				{isLoading && <Loading />}
				{isLoadingError && <FailedRetry retry={refetch} />}
			</ModalContent>
			{historyData?.length > 10 && (
				<ModalFooter>
					<SmallBook>
						<FormattedMessage
							id={"To view the full history, visit {link}"}
							values={{
								link: (
									<Link to="/configuration/configuration-audit" onClick={onClose}>
										<FormattedMessage id={"Configuration Audit"} />
									</Link>
								),
							}}
						/>
					</SmallBook>
				</ModalFooter>
			)}
		</StyledModal>
	);
};

export default EntityHistoryModal;

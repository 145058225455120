import styled from "styled-components";
import Flex from "@/components/flex";
import { Icon } from "semantic-ui-react";
import { addOpacity } from "@/theme/utils";

const StatusContainer = styled(Flex)`
	background-color: ${(props) => getMachineGroupStatusColor(props.$machineGroupStatus, props.theme)};
	border: ${(props) => (props.$showBorder ? `1px solid ${props.theme.colors.border1}` : "none")};
	border-radius: 6px;
	padding: 8px;
	margin: 0;
`;

const StyledIcon = styled(Icon)`
	&&& {
		margin: 0;
	}
`;

const getMachineGroupStatusColor = (status, theme) => {
	switch (status.toLowerCase()) {
		case "online":
			return addOpacity(theme.colors.online, 0.1);
		case "offline":
			return addOpacity(theme.colors.disabled, 0.1);
		case "error":
			return addOpacity(theme.colors.error, 0.1);
		case "initializing":
			return addOpacity(theme.colors.white, 0.1);
		case "paused":
			return addOpacity(theme.colors.paused, 0.1);
		default:
			return addOpacity(theme.colors.white, 0.1);
	}
};

export const getMachineGroupStatusIcon = ({ status, productionEnabled = true, useColor, size = "large" }) => {
	switch (status.toLowerCase()) {
		case "online":
			if (productionEnabled) {
				return <StyledIcon size={size} name="check circle outline" color={useColor ? "green" : "black"} />;
			}
			return <StyledIcon size={size} name="pause circle outline" color={useColor ? "yellow" : "black"} />;
		case "offline":
			return <StyledIcon size={size} name="times circle outline" color={useColor ? "grey" : "black"} />;
		case "error":
			return <StyledIcon size={size} name="exclamation circle" color={useColor ? "red" : "black"} />;
		case "initializing":
			return <StyledIcon size={size} name="refresh" color={useColor ? "grey" : "black"} />;
		case "paused":
			return <StyledIcon size={size} name="pause circle outline" color={useColor ? "yellow" : "black"} />;
		default:
			break;
	}
};

function MachineGroupStatusIcon({ machineGroupStatus, border, children }) {
	return (
		<StatusContainer alignCenter $machineGroupStatus={machineGroupStatus} $showBorder={border} gap={4}>
			{getMachineGroupStatusIcon({ status: machineGroupStatus, useColor: true })}
			{children}
		</StatusContainer>
	);
}

export default MachineGroupStatusIcon;

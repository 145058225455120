import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { PublicSettingsContext } from "@/contexts/public-settings-context";
import { useContext, useEffect } from "react";
import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from "web-vitals";

let applicationInsights = null;

export const reactPlugin = new ReactPlugin();

// expose ApplicationInsights instance here as the React plugin does not do so
export const getApplicationInsights = () => applicationInsights;

function AppInsightsProvider({ children }) {
	const { instrumentationKey } = useContext(PublicSettingsContext);

	useEffect(() => {
		if (instrumentationKey) {
			const appInsights = new ApplicationInsights({
				config: {
					connectionString: instrumentationKey,
					extensions: [reactPlugin],
					autoTrackPageVisitTime: true,
					enableCorsCorrelation: true,
					enableUnhandledPromiseRejectionTracking: true,
					enableRequestHeaderTracking: true,
					enableResponseHeaderTracking: true,
					enableAutoRouteTracking: true,
					correlationHeaderExcludedDomains: ["myapp.azurewebsites.net", "*.queue.core.windows.net"],
				},
			});

			appInsights.loadAppInsights();

			applicationInsights = appInsights;

			const sendMetric = ({ name, value }) => {
				appInsights.trackMetric({ name, average: value });
			};

			onINP(sendMetric);
			onCLS(sendMetric);
			onFID(sendMetric);
			onLCP(sendMetric);
			onTTFB(sendMetric);
			onFCP(sendMetric);
		}
	}, [instrumentationKey]);

	if (!instrumentationKey) return <>{children}</>;

	return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
}

export default AppInsightsProvider;

import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		"& > div:last-child": {
			paddingBottom: "32px",
		},
	},
	heading: {
		fontSize: "16px",
		fontStyle: "normal",
		fontFamily: "Stolzl",
		fontWeight: "400",
		color: "#7c7b79",
		paddingBottom: "15px",
	},
	value: {
		fontSize: "16px",
		fontStyle: "normal",
		fontFamily: "Stolzl",
		fontWeight: "bold",
		color: "#353430",
		"& > div:last-child": {
			paddingBottom: "30px",
		},
	},
});

function TextValueGroup({ headerText, value }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();

	return (
		<div className={classes.container}>
			<div className={classes.heading}>
				<FormattedMessage id={headerText} />
			</div>
			<div className={classes.value}>
				{value.toLocaleString(undefined, {
					minimumFractionDigits:
						headerText &&
						(headerText.trim().toLowerCase().includes("availability") ||
						headerText.trim().toLowerCase().includes("quality") ||
						headerText.trim().toLowerCase().includes("performance")
							? 1
							: 0),
					maximumFractionDigits:
						headerText &&
						(headerText.trim().toLowerCase().includes("time")
							? 2
							: headerText.trim().toLowerCase().includes("availability") ||
								  headerText.trim().toLowerCase().includes("quality") ||
								  headerText.trim().toLowerCase().includes("performance")
								? 1
								: 0),
				})}
				{headerText &&
					(headerText.trim().toLowerCase().includes("time")
						? intl.formatMessage({ id: "hr" })
						: headerText.trim().toLowerCase().includes("rate")
							? `/${intl.formatMessage({ id: "hr" })}`
							: headerText.trim().toLowerCase().includes("count")
								? ""
								: "%")}
			</div>
		</div>
	);
}

export default TextValueGroup;

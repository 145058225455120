import React, { useContext, useEffect } from "react";
import sdk from "identity-api-sdk";
import { BaseRoutesContext } from "@/contexts/base-routes-context.jsx";

const IdentityApiContext = React.createContext(null);

export const IdentityApiProvider = ({ children }) => {
	const data = useContext(BaseRoutesContext);
	const identitySdk = new sdk(null, data?.identity);

	useEffect(() => {
		identitySdk.setSdkRootUrl(data.identity);
	}, [data]);

	return <IdentityApiContext.Provider value={identitySdk}>{children}</IdentityApiContext.Provider>;
};

export const useIdentityApi = () => useContext(IdentityApiContext);

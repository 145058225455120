import { useContext, useEffect, useState } from "react";
import { ProductionContext } from "@/contexts/production-context";
import machineTypes from "@/constants/machineTypes";
import ErrorsAndWarningsOld from "./errors-and-warnings-old";
import ErrorsAndWarningsNew from "./errors-and-warnings-new";
import { UserContext } from "@/components/user-context";

export default function ErrorsAndWarnings() {
	const { currentMachineGroup } = useContext(ProductionContext);
	const [newMachines, setNewMachines] = useState([]);
	const { currentUser } = useContext(UserContext);

	useEffect(() => {
		setNewMachines(
			Object.entries(currentMachineGroup?.configuredMachinesWithMachineType ?? {}).map(([, machineType]) => ({
				machineType,
			})) ?? [],
		);
	}, [currentMachineGroup]);

	const hasFeatureFlag = "ff-new-error-and-warning-modal" in currentUser;

	if (hasFeatureFlag) {
		if (newMachines.length > 0) {
			switch (newMachines[0].machineType) {
				case machineTypes.Em:
				case machineTypes.X4:
				case machineTypes.X5:
				case machineTypes.X5wPrint:
				case machineTypes.X6:
				case machineTypes.ZebraPrinter:
					return <ErrorsAndWarningsOld />;
				case machineTypes.Fusion:
				case machineTypes.X7:
					return <ErrorsAndWarningsNew />;
				default:
					console.error(`No errors and warnings service for ${newMachines[0].machineType} machines`);
					return <ErrorsAndWarningsOld />;
			}
		} else {
			return <ErrorsAndWarningsOld />;
		}
	} else {
		return <ErrorsAndWarningsOld />;
	}
}

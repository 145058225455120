import { Button } from "semantic-ui-react";
import { createUseStyles } from "react-jss";
import { useIntl } from "react-intl";
import clsx from "clsx";

const useStyles = createUseStyles((theme) => ({
	...theme?.typography,
	base: {
		textTransform: "capitalize !important",
		maxHeight: "40px",
	},
	primary: {
		backgroundColor: theme?.primary,
		"& :disabled": {
			backgroundColor: `${theme?.accent} !important`,
		},
	},
	secondary: {
		background: `${theme?.white} none !important`,
		color: theme?.primary,
		border: "1px solid rgb(0, 104, 171) !important",
	},
	text: {
		color: theme?.primary,
		backgroundColor: "transparent !important",
		boxShadow: "none !important",
	},
	icon: {
		width: "42px",
		height: "42px",
		padding: "0px 0px !important",
		margin: "0 !important",
		background: "white !important",
		"& > *": {
			margin: "0 !important",
		},
		"& i": {
			marginTop: "-7px !important",
			marginLeft: "-2px !important",
		},
	},
	iconBorder: {
		width: "42px",
		height: "42px",
		padding: "0px 0px !important",
		marginTop: "-10px !important",
		background: "white !important",
		border: "1px solid rgba(34, 36, 38, 0.15) !important",
		"& > *": {
			margin: "0 !important",
		},
		"& i": {
			marginTop: "-7px !important",
			marginLeft: "-2px !important",
		},
	},
	noWrap: {
		whiteSpace: "nowrap",
	},
}));

/*
Button Types
	Primary: Mostly commonly used button.
	Secondary: The white button with a blue border. Sometimes icon only button. 
	Text: Looks like a link without the underline, but with the button padding included.
	Icon: Icon only. No background.
	Tab: Buttons seen next to page title, background only shows when active or on hover.

	Global button will replace: 
	close-button.js
	delete-button.js
	edit-button.js
	icon-button.js 
	next-button.js 
	save-button.js 
	sub-section-button.js

	For Examples of usage see components-list-view.jsx 
*/
export default function GlobalButton({
	type = "primary",
	onClick,
	text,
	icon = false,
	disabled = false,
	className = null,
	loading = false,
	nowrap,
	id,
	testId,
	children,
	...otherProps
}) {
	const classes = useStyles();
	const intl = useIntl();
	let typeClass = classes.base;
	switch (type) {
		case "primary":
			typeClass = classes.primary;
			break;
		case "secondary":
			typeClass = classes.secondary;
			break;
		case "text":
			typeClass = classes.text;
			break;
		case "icon":
			typeClass = classes.icon;
			break;
		case "icon-with-border":
			typeClass = classes.iconBorder;
			break;
		default:
			typeClass = classes.tab;
			break;
	}
	return (
		<Button
			primary={type === "primary" || type === "secondary"}
			id={id}
			data-testid={testId}
			className={clsx(classes.button, classes.base, nowrap && classes.noWrap, typeClass, className)}
			onClick={onClick}
			disabled={disabled}
			loading={loading}
			{...otherProps}
		>
			{icon}
			{children}
			{text ? intl.formatMessage({ id: text }) : ""}
		</Button>
	);
}

// import {createUseStyles} from "react-jss";
//
// const useStyles = createUseStyles((theme) => ({
//     loadingBar: {
//         position: "relative !important",
//         height: "12px !important",
//         width: "100% !important",
//         backgroundColor: "#D9D9D9 !important",
//         borderRadius: "1em !important",
//         overflow: "hidden !important",
//     }, loadingSpan: {
//         position: "absolute !important",
//         top: "0 !important",
//         left: "0 !important",
//         content: "'' !important",
//         width: "25% !important",
//         height: "100% !important",
//         backgroundColor: "rgba(230, 230, 230, 0.891)",
//         background: "#B9BE10 !important",
//         animationDelay: "4s",
//         animation: "login-loading-animation 2.5s infinite !important",
//         borderRadius: "8px !important",
//     },
// }));

const LoadingBar = () => {
	// const ref = useRef(null);
	// const [location, setLocation] = useState(0);
	// const animate = () => {
	//     let fullCurrentPosition = ref.current.style.transform;
	//     console.info("!important", fullCurrentPosition)
	//
	//     let local = fullCurrentPosition.match(/(?<=\()(.*?)(?=\))/g);
	//     console.info("!important", fullCurrentPosition, local)
	//
	//     setLocation(local);
	//     if (location >= 425) ref.current.style.transform = `translateX(${location-1})`
	//     if (location <= -125) ref.current.style.transform = `translateX(${location+1})`
	// };

	return <div></div>;
	// return <div className={classes.loadingBar}>
	//     <span ref={ref} className={classes.loadingSpan}></span>
	// </div>;
};

export default LoadingBar;

import { useEffect, useState } from "react";
import * as ReactDOMServer from "react-dom/server";
import { Icon } from "semantic-ui-react";
import Chart from "react-apexcharts";
import { createUseStyles, useTheme } from "react-jss";

import NoData from "@/views/reporting/no-data";
import { incrementDateRange } from "@/views/reporting/functions";
import { getAvailabilityDisplayData } from "@/views/reporting/functions/availability-data";
import { useIntl } from "react-intl";
import Legend from "../../legend";
import LegendDot from "../../legend-dot";

const useStyles = createUseStyles({
	content: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "no-wrap",
	},
	availabilityStatus: {
		display: "flex",
		flexDirection: "row",
		height: "255px",
		marginBottom: "32px",
		width: "100%",
		"& > div:first-child": {
			width: "300px",
			"& > div": {
				"& > div": {
					"& > svg": {},
					"& > svg:not(:root)": {
						overflow: "visible !important",
					},
					"& div": {
						border: "0px solid #353430",
						"& div": {
							backgroundColor: "#353430 !important",
							color: "#ffffff !important",
						},
					},
				},
			},
		},
		"& > div:last-child": {
			width: "50%",
		},
	},
});

function SiteAvailabilityStatus({ reportData, dateRange, isNewFormat }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [presentationData, setPresentationData] = useState("");
	const intl = useIntl();

	function getOptions() {
		if (!presentationData || !presentationData.length) return { series: [] };

		const colors = presentationData.map((a) => a.color);
		const series = presentationData.map((a) => a.percent);
		const labels = presentationData.map((a) => a.status);

		return {
			chart: {
				type: "pie",
			},
			dataLabels: {
				enabled: true,
				style: {
					fontSize: "15px",
					fontFamily: "Stolzl-Medium",
					colors: ["#353430"],
				},
				dropShadow: {
					enabled: false,
					blur: 0,
					top: 1,
				},
			},
			colors: colors,
			series: series,
			labels: labels,
			plotOptions: {
				pie: {
					expandOnClick: true,
					dataLabels: {
						offset: -20,
						minAngleToShowLabel: 10,
					},
				},
			},
			states: {
				hover: {
					filter: {
						type: "none",
					},
				},
				active: {
					filter: {
						type: "none",
					},
				},
			},
			tooltip: {
				custom: function ({ series, seriesIndex }) {
					const name = presentationData[seriesIndex].status;
					const color = presentationData[seriesIndex].color;
					const percent = `${series[seriesIndex].toFixed(1)}%`;

					return (
						'<div style = "display: flex; justify-content: center; align-items: center; height: 35.5px; vertical-align: center; padding: 8px 16px 8px 16px" > ' +
						ReactDOMServer.renderToString(<Icon circular style={{ backgroundColor: color }} size="mini" />) +
						'<span style="padding-left: 8px">' +
						`${name} - ${percent}` +
						"</span>" +
						"<div style=\"position: absolute; width: 0.625em; height: 0.625em; transform: rotate(45deg); z-index: 2; top: auto; right: auto; bottom: -0.2625em; left: 50%; margin-left: -0.2625em; background: #1B1C1D; box-shadow: none !important; content: ''; border-bottom-right-radius: 2px\">" +
						"</div>" +
						"</div>"
					);
				},
			},
			legend: {
				show: false,
			},
			stroke: {
				width: 0,
			},
		};
	}

	function mapData(data, dateRange) {
		let aggregation = [];
		if (isNewFormat) {
			aggregation = data.byStatus.map((item) => {
				const [ordering, color, displayStatus] = getAvailabilityDisplayData(item.status);
				item.siteId = "";
				item.color = color;
				item.ordering = ordering;
				item.percent = item.percentage * 100;
				item.status = displayStatus;
				return item;
			});
		} else {
			const numberOfMachines = [...new Set(data.map((item) => item.machineId))].length;
			let newDateRange = { ...dateRange };
			if (newDateRange.id === "Custom") {
				newDateRange = incrementDateRange(dateRange);
			}
			const totalTime = (Date.parse(newDateRange.end) - Date.parse(newDateRange.start)) * numberOfMachines;
			aggregation = data.reduce((groups, item) => {
				const [ordering, color, displayStatus] = getAvailabilityDisplayData(item.status);
				const group = groups.find((i) => i.status === displayStatus);
				const timeInMilliseconds = Date.parse(item.endTime) - Date.parse(item.startTime);
				if (!group) {
					return [
						...groups,
						{
							status: displayStatus,
							color: color,
							ordering: ordering,
							timeInMilliseconds: timeInMilliseconds,
						},
					];
				}
				group.timeInMilliseconds += timeInMilliseconds;
				return groups;
			}, []);
			aggregation.sort((a, b) => {
				if (b.timeInMilliseconds < a.timeInMilliseconds) return -1;
				if (b.timeInMilliseconds > a.timeInMilliseconds) return 1;
				return 0;
			});
			aggregation = aggregation.map((item) => {
				item.percent = (item.timeInMilliseconds / totalTime) * 100;
				return item;
			});
		}
		setPresentationData(aggregation);
	}

	useEffect(() => {
		if (reportData?.length || isNewFormat) mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let options = getOptions();

	const sorted = [...presentationData].sort((a, b) => {
		if (a.ordering < b.ordering) return -1;
		if (a.ordering > b.ordering) return 1;
		return 0;
	});

	return presentationData && presentationData.length !== 0 ? (
		<div className={classes.content}>
			<div className={classes.availabilityStatus}>
				<div>
					<Chart options={options} series={options.series} type="pie" width="100%" height="260px" />
				</div>
				<div>
					<Legend headerText={"Availability Status"}>
						{sorted.map((item, i) => {
							const siteId = item.siteId ?? i;
							const machineName = item.machineName ?? i;
							return (
								<LegendDot key={`cb-${siteId}-${machineName}`} color={item.color}>
									{intl.formatMessage({ id: item.status })}
								</LegendDot>
							);
						})}
					</Legend>
				</div>
			</div>
		</div>
	) : (
		<NoData />
	);
}

export default SiteAvailabilityStatus;

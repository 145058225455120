import { useIntl } from "react-intl";
import { useEffect, useState } from "react";

import NoData from "@/views/reporting/no-data";
import ProgressBarList from "@/views/reporting/progress-bar-list";
import { getSwimLaneMaxiumum } from "@/views/reporting/functions/swimlanes";

function TopErrors({ reportData, dateRange, color, isNewFormat }) {
	const [presentationData, setPresentationData] = useState([]);
	const intl = useIntl();

	function mapData(data) {
		let aggregation;
		if (isNewFormat) {
			aggregation = data.topErrors;
		} else {
			aggregation = data.reduce((groups, item) => {
				const group = groups.find((i) => i.errorCode === item.errorCode);

				if (group) {
					group.count += 1;
					return groups;
				} else {
					return [
						...groups,
						{
							errorCode: item.errorCode,
							errorName: item.errorName,
							count: 1,
						},
					];
				}
			}, []);
			aggregation = aggregation.sort((a, b) => (b.count > a.count ? 1 : -1));
		}
		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.count),
		);
		const upper = getSwimLaneMaxiumum(maximumConsumed);
		aggregation = aggregation.map((item) => {
			return {
				...item,
				percentage: (item.count / upper) * 100,
			};
		});
		setPresentationData(aggregation);
	}

	useEffect(() => {
		if (!isNewFormat) {
			if (reportData?.length) mapData(reportData, dateRange);
		} else {
			mapData(reportData, dateRange);
		}
	}, [reportData, dateRange]);

	let items = presentationData.map((p) => {
		let key = p.errorName;
		if (!key || key.trim() === "") key = "Placeholder Error";
		return {
			percent: p.percentage,
			text: `${intl.formatMessage({ id: key })} - ${p.errorCode}`,
			value: p.count,
		};
	});

	if (!isNewFormat) items = items.slice(0, 6);

	return items.length > 0 ? <ProgressBarList color={color} items={items} limit={items.length} /> : <NoData />;
}
export default TopErrors;

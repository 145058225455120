import { createUseStyles } from "react-jss";

const useStyles = createUseStyles(() => ({
	noData: {
		display: "flex !important",
		flexDirection: "column !important",
		alignItems: "center !important",
		justifyContent: "center",
		flex: "1 !important",
		backgroundColor: "white !important",
		padding: "20px",
		// border: "solid 1px #e3e7e9",
		borderRadius: "8px",
		height: "100%",
		gap: "16px",
		width: "98%",
	},
	largeNoDataText: {
		fontFamily: "Stolzl-Medium",
		color: "#353430",
		fontSize: "24px",
	},
}));

function EmptySearchResults({ icon, mainText, subText, hasButton = false, button }) {
	const classes = useStyles();

	return (
		<div className={classes.noData}>
			{icon}
			<span className={classes.largeNoDataText}>
				{/* Format the mainText and subtext before passing in to account for text that includes dynamically displayed values.
				Example: intl.formatMessage({id: "Missing ID",}, { id: currentId })
				*/}
				{mainText}
			</span>
			<span>{subText}</span>
			{hasButton && button}
		</div>
	);
}

export default EmptySearchResults;

import { Button, Card, Divider, Header, Segment } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { machineTypes, routeStrings } from "@/constants";

import EmService from "./em-service";
import { FormattedMessage } from "react-intl";
import { Fragment } from "react";
import FusionService from "./fusion-service";
import Loading from "@/components/loading";
import { UserContext } from "@/components/user-context";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";
import { useContext } from "react";
import { getX5Machines } from "@/api/x5-api";
import X5wPrintService from "./x5wPrintService/x5wPrint-service";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: "#f2f4f5 !important",
		borderRadius: "0px !important",
		minHeight: "96px",
	},
	headerText: {
		color: "#353430 !important",
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
	},
	servicePage: {
		marginTop: "16px !important",
	},
	cards: {
		margin: "16px !important",
	},
}));

function useMachineService(token) {
	const EmApi = useAxios(routeStrings.EmApi, token);
	const FusionApi = useAxios(routeStrings.FusionApi, token);

	const { currentUser } = useContext(UserContext);

	const get = async (setCallBack, errorCallBack) => {
		let errorCount = 0;
		let successCount = 0;
		let machineList = [];

		const errorHandler = (msg, err) => {
			console.error(msg, err);
			errorCount++;
		};

		const appendItems = (list, machineType) => {
			successCount++;

			if (!list.length) return;

			const newItemsWithType = list.map((item) => ({ ...item, machineType }));
			machineList = [...machineList, ...newItemsWithType];
		};

		let tasks = [
			EmApi.get(
				(newItems) => appendItems(newItems, machineTypes.Em),
				(err) => errorHandler("EM", err),
			),
			"ff-pack-net-fusion-machine" in currentUser && "ff-fusion-allow-access-machines-service" in currentUser
				? FusionApi.getWithUrl(
						"v1/machines",
						(newItems) => appendItems(newItems, machineTypes.Fusion),
						(err) => errorHandler("Fusion", err),
					)
				: null,
			currentUser.role.includes("createprintsolution")
				? getX5Machines(
						(newItems) => {
							let x5wPrintMachines = newItems.filter((machine) => machine.settings.hasEfiPrinter);
							x5wPrintMachines.map((m) => {
								m.status = "Service";
							});
							appendItems(x5wPrintMachines, machineTypes.X5wPrint);
						},
						(err) => errorHandler("X5wPrint", err),
					)
				: null,
		];

		await Promise.allSettled(tasks);

		if (errorCount > 0 && successCount === 0) {
			const newErr = new Error("Couldn't load any machine");
			if (errorCallBack) errorCallBack(newErr);
			else throw newErr;
		} else {
			machineList.sort((a, b) => (a.alias > b.alias ? 1 : -1));
			if (setCallBack) {
				setCallBack(machineList);
			}
		}

		return machineList;
	};

	const enterServiceMode = async (machine, setCallBack, errorCallBack) => {
		switch (machine.machineType) {
			case machineTypes.Em:
				await EmApi.addWithUrl(`${machine.id}/enterservicemode`, {}, setCallBack, errorCallBack);
				break;
			case machineTypes.Fusion:
				await FusionApi.addWithUrl(`v1/machines/${machine.id}/enterservicemode`, {}, setCallBack, errorCallBack);
				break;
			case machineTypes.X5wPrint:
				break;
			default:
				throw new Error(`'enterServiceMode' not setup in use-machines.js for machine type ${machine.machineType}`);
		}
	};

	const leaveServiceMode = async (machine, setCallBack, errorCallBack) => {
		switch (machine.machineType) {
			case machineTypes.Em:
				await EmApi.addWithUrl(`${machine.id}/leaveservicemode`, {}, setCallBack, errorCallBack);
				break;
			case machineTypes.Fusion:
				await FusionApi.addWithUrl(`v1/machines/${machine.id}/leaveservicemode`, {}, setCallBack, errorCallBack);
				break;
			default:
				throw new Error(`'leaveServiceMode' not setup in use-machines.js for machine type ${machine.machineType}`);
		}
	};

	return { get, enterServiceMode, leaveServiceMode };
}

function MachineService() {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const token = readLocalStorage("BEARER");
	const MachineServices = useMachineService(token);
	const [machineList, setMachineList] = useState(null);
	const [selectedMachine, setSelectedMachine] = useState(undefined);
	const refreshInterval = 3000;

	const load = () => {
		MachineServices.get((machines) => setMachineList(machines));
	};
	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		if (refreshInterval && refreshInterval > 0) {
			const interval = setInterval(load, refreshInterval);
			return () => clearInterval(interval);
		}
	}, [refreshInterval]);

	useEffect(() => {
		if (selectedMachine) {
			var machines = machineList.filter((m) => m.id === selectedMachine.id);
			if (machines.length) setSelectedMachine(machines[0]);
		}
	}, [machineList]);

	const loadingComponent = (
		<Fragment>
			<Segment clearing className={classes.header}>
				<Header floated="left" className={classes.headerText}>
					<FormattedMessage id="Machine Service" />
				</Header>
			</Segment>
			<Loading />
		</Fragment>
	);

	const isInServiceMode = (machine) => {
		return (
			machine &&
			(machine.currentStatus === "Service" || machine.status === "Service" || machine.status === "Calibrating")
		);
	};

	if (machineList == null) return loadingComponent;

	const selectedMachineIsInServiceMode = isInServiceMode(selectedMachine);

	if (selectedMachine && !selectedMachineIsInServiceMode) {
		return loadingComponent;
	}

	return (
		<Fragment>
			<Segment clearing className={classes.header}>
				<Header floated="left" className={classes.headerText}>
					{selectedMachineIsInServiceMode && (
						<FormattedMessage
							id="Machine Service: {alias}"
							defaultMessage="Machine Service: {alias}"
							values={{ alias: selectedMachine.alias }}
						/>
					)}
					{!selectedMachineIsInServiceMode && <FormattedMessage id="Machine Service" />}
				</Header>
				{selectedMachineIsInServiceMode && selectedMachine?.machineType !== machineTypes.X5wPrint && (
					<Header size="large" floated="right" className={classes.headerButton}>
						<Button
							primary
							onClick={(e) => {
								e.stopPropagation();
								setSelectedMachine(null);
								MachineServices.leaveServiceMode(selectedMachine, () => setSelectedMachine(null));
							}}
						>
							<FormattedMessage id="Leave Service Mode" />
						</Button>
					</Header>
				)}
				{selectedMachine?.machineType === machineTypes.X5wPrint && (
					<Header size="large" floated="right" className={classes.headerButton}>
						<Button
							primary
							onClick={(e) => {
								e.stopPropagation();
								setSelectedMachine(null);
							}}
						>
							<FormattedMessage id="Exit" />
						</Button>
					</Header>
				)}
			</Segment>
			<Fragment>
				{selectedMachineIsInServiceMode && (
					<Segment attached>
						{(() => {
							switch (selectedMachine.machineType) {
								case machineTypes.Em:
									return <EmService selectedMachine={selectedMachine} />;
								case machineTypes.Fusion:
									return <FusionService selectedMachine={selectedMachine} />;
								case machineTypes.X5wPrint:
									return <X5wPrintService selectedMachine={selectedMachine} />;
								default:
									return <FormattedMessage id="Machine does not have a service mode" />;
							}
						})()}
					</Segment>
				)}

				{!selectedMachine && (
					<Card.Group centered className={classes.cards}>
						{machineList.map((m) => {
							let machineIsInServiceMode = isInServiceMode(m);
							return (
								<Fragment>
									<Card
										key={m.id}
										color={machineIsInServiceMode ? "orange" : "grey"}
										onClick={() => (machineIsInServiceMode ? setSelectedMachine(m) : null)}
									>
										<Card.Content>
											<Card.Header>{m.alias}</Card.Header>
											<Card.Meta>
												<FormattedMessage id={m.machineType} />
											</Card.Meta>
											<Card.Description>
												<FormattedMessage id={m.status || m.currentStatus || "Offline"} />
											</Card.Description>
										</Card.Content>
										{!machineIsInServiceMode && (
											<Card.Content extra>
												<Button
													disabled={m.status === "Offline" || m.currentStatus === "Offline"}
													primary={m.status !== "Offline" && m.currentStatus !== "Offline"}
													fluid
													onClick={(e) => {
														e.stopPropagation();
														MachineServices.enterServiceMode(
															m,
															() => setSelectedMachine(m),
															(d) => console.error(d),
														);
													}}
												>
													<FormattedMessage id="Enter Service Mode" />
												</Button>
											</Card.Content>
										)}
										{machineIsInServiceMode && m.machineType !== machineTypes.X5wPrint ? (
											<Card.Content extra>
												<Button
													positive
													fluid
													onClick={(e) => {
														e.stopPropagation();
														setSelectedMachine(null);
														MachineServices.leaveServiceMode(m, () => setSelectedMachine(null));
													}}
												>
													<FormattedMessage id="Leave Service Mode" />
												</Button>
												<Divider fitted />
												<Button
													primary
													fluid
													onClick={(e) => {
														e.stopPropagation();
														MachineServices.enterServiceMode(
															m,
															() => setSelectedMachine(m),
															(d) => console.error(d),
														);
													}}
												>
													<FormattedMessage id="Enter Service Mode" />
												</Button>
											</Card.Content>
										) : (
											machineIsInServiceMode &&
											m.machineType === machineTypes.X5wPrint && (
												<Card.Content extra>
													<Button
														positive
														fluid
														onClick={(e) => {
															e.stopPropagation();
															setSelectedMachine(m);
														}}
													>
														<FormattedMessage id="Enter" />
													</Button>
												</Card.Content>
											)
										)}
									</Card>
								</Fragment>
							);
						})}
					</Card.Group>
				)}
			</Fragment>
		</Fragment>
	);
}

export default MachineService;

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import ProgressBarList from "@/views/reporting/progress-bar-list";
import SwimLanes from "@/views/reporting/swim-lanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import { getSwimLaneMaxiumum } from "@/views/reporting/functions/swimlanes";
import { getFilteredCorrugate } from "@/views/reporting/functions/corrugate";
import { sortByValue } from "@/views/reporting/functions/sort";

function ByMachine({ reportData, dateRange, color, limit, isMetric }) {
	const [presentationData, setPresentationData] = useState([]);
	const [upperBound, setUpperBound] = useState(0);
	const intl = useIntl();
	const [pagination, setPagination] = useState({
		perPage: 6,
		page: 1,
	});
	const unitLabel = isMetric ? "sq meters" : "sq ft";

	function mapData(data) {
		let aggregation = data.byMachine;

		const maximumConsumed = Math.max.apply(
			Math,
			aggregation.map((a) => a.totalConsumedCorrugate),
		);
		const upper = getSwimLaneMaxiumum(maximumConsumed);
		setUpperBound(upper);
		aggregation = aggregation.map((item) => {
			return {
				...item,
				percentage: (item.totalConsumedCorrugate / upper) * 100,
			};
		});

		const machines = aggregation.reduce((dict, el) => {
			!dict[`${el.tenantId}-${el.machineName}`]
				? (dict[`${el.tenantId}-${el.machineName}`] = [el])
				: dict[`${el.tenantId}-${el.machineName}`].push(el);
			return dict;
		}, []);

		let dataToShow = {};

		for (const [, value] of Object.entries(machines)) {
			const tenantId = value[0].tenantId;
			const machineName = value[0].machineName;
			const sortedValue = sortByValue(value, "totalConsumedCorrugate", "desc", "corrugateAlias", "asc");
			if (!dataToShow[tenantId]) {
				dataToShow[tenantId] = [{ machineName: machineName, values: sortedValue }];
			} else {
				dataToShow[tenantId].push({
					machineName: machineName,
					values: sortedValue,
				});
			}
		}
		machines.forEach((element) => {
			for (let i = 0; i < element.length; i++) {
				const e = element[i];
				if (!dataToShow[e.tenantId]) {
					dataToShow[e.tenantId] = [e];
				} else {
					dataToShow[e.tenantId].push(e);
				}
			}
		});
		setPresentationData(dataToShow);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const items = [];
	for (const [, siteData] of Object.entries(presentationData)) {
		const machines = siteData.map((m) => {
			const corrugates = getFilteredCorrugate(m.values).map((c) => {
				return {
					percent: c.percentage,
					text: c.corrugateAlias,
					value: `${c.totalConsumedCorrugate.toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})} ${intl.formatMessage({ id: unitLabel })}`,
				};
			});
			const machine = {
				heading: m.machineName,
				items: corrugates,
			};
			return machine;
		});
		const site = {
			machines: machines,
		};
		items.push(site);
	}

	function getPaginationResult(site, pageSize, page) {
		if (!site || !site.machines) {
			return {
				items: [],
				hasNext: false,
				hasPrevious: false,
				totalPages: 0,
			};
		}

		const rawValues = [];
		for (const machine of site.machines) {
			const heading = machine.heading;
			for (const item of machine.items) {
				rawValues.push({ heading: heading, item: item });
			}
		}

		const slicedItems = rawValues.slice((page - 1) * pageSize, page * pageSize);
		let items = [];
		if (slicedItems.length === 1) {
			items = [{ heading: slicedItems[0].heading, items: [slicedItems[0].item] }];
		} else {
			items = slicedItems.reduce((groups, item) => {
				const group = groups.find((i) => i.heading === item.heading);
				if (group) {
					group.items.push(item.item);
					return groups;
				} else {
					return [
						...groups,
						{
							heading: item.heading,
							items: [item.item],
						},
					];
				}
			}, []);
		}

		const result = {
			items: items,
			hasNext: page * pageSize < rawValues.length,
			hasPrevious: page > 1,
			totalPages: Math.ceil(rawValues.length / pageSize),
		};
		return result;
	}

	const paginationResult = getPaginationResult(items[0], pagination.perPage, pagination.page);
	return paginationResult.items.length ? (
		[
			<SwimLanes upperBound={upperBound} numberOfHeadings={2} key="by-machine-swim-lanes">
				{limit === 1000 &&
					items[0].machines.map((item, i) => {
						return (
							<ProgressBarList
								heading={item.heading}
								color={color}
								items={item.items}
								limit={1000}
								key={`by-machine-progress-bar-list-${i}`}
							/>
						);
					})}
				{limit < 1000 &&
					paginationResult.items.map((machine, i) => {
						return (
							<ProgressBarList
								heading={machine.heading}
								color={color}
								items={machine.items}
								limit={pagination.perPage}
								key={`by-machine-progress-bar-list-${i}`}
							/>
						);
					})}
			</SwimLanes>,
			<SwimLanePagination
				pagination={pagination}
				setPagination={setPagination}
				paginationResult={paginationResult}
				limit={limit}
				key="by-machine-swim-lane-pagination"
			/>,
		]
	) : (
		<div></div>
	);
}

export default ByMachine;

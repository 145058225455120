import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles({
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		inlineSize: "max-content",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
		cursor: "none",
	},
	sideMenuBody: {
		border: "solid 0.5px #e3e7e9",
		borderRadius: "8px",
		maxHeight: "255px",
		inlineSize: "min-content",
		padding: "20px 0px 0px 20px",
		"& > label:last-child": {
			marginBottom: "17px",
		},
		"& > div:last-child": {
			maxHeight: "195px",
			overflow: "auto",
			paddingRight: "32px",
			"&::-webkit-scrollbar-track": {
				backgroundColor: "white",
			},
		},
	},
});

function Legend({ headerText, children }) {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<div className={classes.sideMenuBody}>
			<div className={classes.header}>
				{" "}
				<FormattedMessage id={headerText} />
			</div>
			<div>{children}</div>
		</div>
	);
}

export default Legend;

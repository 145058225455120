import { FormattedMessage } from "react-intl";
import { Fragment, useContext } from "react";

import DimDataContext from "../contexts/dimensioning-data-context";
import { createUseStyles, useTheme } from "react-jss";
import RadioButton from "@/components/radio-button";

import { Form } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
	},
	nestingOptions: {
		marginTop: "8px",
		marginBottom: "16px",
		display: "flex !important",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "flex-start",
		alignContent: "stretch",
		"& .field": {
			marginRight: "1rem !important",
			width: "7em",

			"& input": {
				textAlign: "center",
				width: "7em",
				marginTop: "8px",
			},
		},
	},
	nestingOptionsMax: {
		marginTop: "8px",
		display: "flex !important",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "flex-start",
		alignContent: "stretch",
		"& .field": {
			marginRight: "1rem !important",
			width: "20em",

			"& input": {
				textAlign: "center",
				width: "23em",
				marginTop: "8px",
			},
		},
	},
	radioGroup: {
		display: "flex",
		alignItems: "flex-start",
		"& > *": {
			marginRight: "32px",
		},
		paddingBottom: "8px",
	},
	radioBlock: {
		marginTop: "8px",
		paddingTop: "2px",
		display: "block !important",
		fontFamily: "Stolzl-Book !important",
	},
}));

export const distoUnits = {
	" in": 0,
	" mm": 0,
};

export function isDistoInput(input) {
	return input && input.length > 3 && input.substring(input.length - 3).toLowerCase() in distoUnits;
}

const DimensioningNesting = ({ product, setProduct }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const dimCtx = useContext(DimDataContext);

	function adjustDistoValue(value) {
		let newValue = value;
		if (isDistoInput(newValue)) {
			const dim_split = newValue.split(" ");
			dim_split[0] -= dimCtx.customerConfiguration.productionSettings.distoOffset;
			newValue = dim_split[0];
		}

		return newValue;
	}

	return (
		<Fragment>
			<div className={classes.prompt}>
				<FormattedMessage id="Can the product nest?"></FormattedMessage>
			</div>
			<div className={classes.radioGroup}>
				<RadioButton
					name="nesting"
					className={classes.radioBlock}
					value="yes"
					checked={product?.canNest ?? false}
					onChange={(_) => setProduct({ ...product, canNest: true })}
				>
					<FormattedMessage id="Yes" />
				</RadioButton>
				<RadioButton
					name="nesting"
					className={classes.radioBlock}
					value="no"
					checked={!product?.canNest}
					onChange={(_) => setProduct({ ...product, canNest: false })}
				>
					<FormattedMessage id="No" />
				</RadioButton>
			</div>

			<div>
				{product.canNest && (
					<Fragment>
						<div className={classes.prompt}>
							<FormattedMessage id="DimNestingDims"></FormattedMessage>
						</div>
						<div className={classes.nestingOptions}>
							<Form.Input
								min={0}
								fluid
								label="Length*"
								placeholder="Length"
								checkRange={true}
								value={product?.nesting?.nestGrowthDimension1 ?? 0}
								onBlur={(event) => {
									const newValue = adjustDistoValue(event.target.value);
									setProduct({
										...product,
										nesting: { ...product.nesting, nestGrowthDimension1: newValue },
									});
								}}
								onChange={(_, { value }) =>
									setProduct({
										...product,
										nesting: { ...product.nesting, nestGrowthDimension1: value },
									})
								}
							/>
							<Form.Input
								min={0}
								fluid
								label="Width*"
								placeholder="Width"
								value={product?.nesting?.nestGrowthDimension2 ?? 0}
								onBlur={(event) => {
									const newValue = adjustDistoValue(event.target.value);
									setProduct({
										...product,
										nesting: { ...product.nesting, nestGrowthDimension2: newValue },
									});
								}}
								onChange={(_, { value }) =>
									setProduct({
										...product,
										nesting: { ...product.nesting, nestGrowthDimension2: value },
									})
								}
							/>
							<Form.Input
								min={0}
								fluid
								label="Height*"
								placeholder="Height"
								value={product?.nesting?.nestGrowthDimension3 ?? 0}
								onBlur={(event) => {
									const newValue = adjustDistoValue(event.target.value);
									setProduct({
										...product,
										nesting: { ...product.nesting, nestGrowthDimension3: newValue },
									});
								}}
								onChange={(_, { value }) =>
									setProduct({
										...product,
										nesting: { ...product.nesting, nestGrowthDimension3: value },
									})
								}
							/>
						</div>
						<div className={classes.nestingOptionsMax}>
							<Form.Input
								min={0}
								label="Nesting Level Max*"
								placeholder="Enter max number of products"
								checkRange={true}
								value={product?.nesting?.nestMax ?? 0}
								onChange={(_, { value }) =>
									setProduct({
										...product,
										nesting: { ...product.nesting, nestMax: value },
									})
								}
							/>
						</div>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default DimensioningNesting;

import { Button, Modal } from "semantic-ui-react";
import { useState } from "react";
import CloseButton from "../../../../components/close-button";
import { createUseStyles, useTheme } from "react-jss";
import { FormattedMessage } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	closeButton: {
		"& path": {
			fill: "#7C7B79 !important",
		},
	},
	modal: {
		"& > :first-child": {
			padding: "0px !important",
		},
	},
	fullSize: {
		borderRadius: "6px",
		background: "#f2f4f5",
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		padding: "2rem",
		gap: "1rem",
	},
	modalHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		"& h1": {
			margin: "0 !important",
		},
	},
	content: {
		display: "flex",
		gap: "1rem",
		flexDirection: "column",
		borderRadius: "6px",
		padding: "2rem",
		background: "#FFF",
		fontFamily: "Stolzl-Book",
		fontSize: "16px",
		fontWeight: "400",
		fontStyle: "normal",
		lineHeight: "normal",
	},
	"#cancelButton": {
		boxShadow: "none !important",
		border: "none !important",
	},
	footer: {
		display: "flex",
		justifyContent: "end",
		"& #cancelButton": {
			boxShadow: "none !important",
			border: "none !important",
		},
	},
}));

const ApimModal = ({
	size,
	trigger,
	submit,
	header,
	children,
	close,
	submitFunction,
	closeOnSubmit = true,
	disabled = false,
}) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [modalOpen, setModalOpen] = useState(false);

	const closeModal = () => {
		setModalOpen(false);
		if (close != null) close();
	};

	const internalSubmit = () => {
		if (!disabled) {
			submitFunction();
			if (closeOnSubmit) closeModal();
		}
	};

	return (
		<Modal
			size={size ?? "small"}
			centered={false}
			closeOnEscape={true}
			closeOnDimmerClick={true}
			open={modalOpen}
			onClose={() => {
				setModalOpen(false);
			}}
			className={classes.modal}
			trigger={<div onClick={() => setModalOpen(true)}>{trigger}</div>}
		>
			<Modal.Content>
				<div className={classes.fullSize}>
					<div className={classes.modalHeader}>
						<h1>{header}</h1>
						<CloseButton className={classes.closeButton} onClick={closeModal}></CloseButton>
					</div>
					<div className={classes.content}>{children}</div>

					{submit && (
						<div className={classes.footer}>
							<Button id="cancelButton" basic primary onClick={closeModal}>
								<FormattedMessage id="Cancel" />
							</Button>
							<div onClick={internalSubmit}> {submit}</div>
						</div>
					)}
				</div>
			</Modal.Content>
		</Modal>
	);
};
export default ApimModal;

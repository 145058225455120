import { Table } from "semantic-ui-react";
import X4Dropdown from "./x4-change-corrugate-dropsdown";

const TracksCard = ({
	classes,
	selectedMachine,
	translate,
	tracks,
	allowTrackToBeChanged,
	trackErrors,
	selectedCorrugateChanged,
	allowChange,
	getValidCorrugatesForTrack,
}) => {
	return (
		<div key={selectedMachine.id} className={classes.tracksBox}>
			<Table basic className={classes.trackTable}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell className={classes.columns}>
							<div className={`${classes.tablePadding} ${classes.textSmallBold}`}>{translate("Track")}</div>
						</Table.HeaderCell>
						<Table.HeaderCell className={classes.columns}>
							<div className={classes.textSmallBold}>{translate("Selected z-Fold")}</div>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{tracks.map((track, i) => (
						<Table.Row key={i}>
							<Table.Cell>
								<div className={classes.tablePadding}>{track.trackNumber}</div>
							</Table.Cell>
							<Table.Cell>
								<X4Dropdown
									dropDownBox={classes.dropDownBox}
									track={track}
									selectedMachine={selectedMachine}
									allowChange={allowChange}
									allowTrackToBeChanged={allowTrackToBeChanged}
									getValidCorrugatesForTrack={getValidCorrugatesForTrack}
									trackErrors={trackErrors}
									selectedCorrugateChanged={selectedCorrugateChanged}
								/>
							</Table.Cell>
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</div>
	);
};

export default TracksCard;

import { createUseStyles } from "react-jss";

export default function CheckboxButton({ children, className, color, ...others }) {
	const useStyles = createUseStyles({
		/* The container */
		container: {
			display: "block",
			position: "relative",
			paddingLeft: "35px",
			marginBottom: "20px",
			cursor: "pointer",
			userSelect: "none",

			/* Hide the browser's default checkbox */
			"& input": {
				position: "absolute",
				opacity: "0",
				cursor: "pointer",
			},

			"&:hover input ~ .checkmark": {
				backgroundColor: "#ccc",
				border: `2px solid ${color}`,
			},

			"& input:checked ~ $checkmark": {
				backgroundColor: color,
				border: "none",
			},

			/* Show the checkmark when checked */
			"& input:checked ~ $checkmark:after": {
				display: "block",
			},

			/* Style the checkmark */
			"& $checkmark:after": {
				top: "1px",
				left: "6px",
				width: "8px",
				height: "14px",
				border: "solid white",
				borderWidth: "0 2px 2px 0",
				backgroundColor: color,
				transform: "rotate(45deg)",
			},
		},

		/* Create a custom checkbox */
		checkmark: {
			position: "absolute",
			top: "0",
			left: "0",
			height: "20px",
			width: "20px",
			backgroundColor: "white",
			borderRadius: "3px",
			border: `2px solid ${color}`,

			/* Create the indicator the checkmark - (hidden when not checked) */
			"&:after": {
				content: '""',
				position: "absolute",
				display: "none",
			},
		},
	});
	const classes = useStyles();

	return (
		<label className={className ? `${classes.container} ${className}` : classes.container}>
			{children}
			<input type="checkbox" {...others} />
			<span className={classes.checkmark} />
		</label>
	);
}

import ListView from "@/components/list-view";
import New from "./new-carton-property-group";
import Table from "./carton-property-groups";
import { useIntl } from "react-intl";

function CartonPropertyGroups() {
	const intl = useIntl();
	const title = intl.formatMessage({ id: "Carton Property Groups" });

	return <ListView title={title} Table={Table} AddItem={New} size="large" />;
}

export default CartonPropertyGroups;

import { useContext, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Header, Table } from "semantic-ui-react";
import InfoIcon from "../../../components/info";
import { FormattedMessage } from "react-intl";
import DeleteButton from "../../../components/delete-button";
import DeleteApiKey from "./modals/api-key-delete";
import { getApimSubscriptions } from "../../../api/identity-api";
import { UserContext } from "../../../components/user-context";
import ListContext from "@/components/list-context";

const useStyles = createUseStyles((theme) => ({
	fullHeight: {
		minHeight: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
	},
	keyTable: {
		width: "100%",
		background: "#fff",
		padding: "2rem",
		borderRadius: " 10px",
		height: "100%",
		overflowY: "scroll",
	},
	mainContentKeyTable: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
		padding: "2rem",
		background: "#f2f4f5",
	},
	mainContentEmpty: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "center",
		width: "100%",
		height: "100%",
	},
	centeredBox: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "100%",
		width: "auto",
		textAlign: "center",
		"& .header": {
			margin: ".5rem !important",
		},
	},
	emptyListMessageTitle: {
		fontSize: "24px !important",
	},
	emptyListMessageBody: {
		fontSize: "17px !important",
	},
	iconContainer: {
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
		minHeight: "60px",
	},
	iconBackground: {
		background: "#f2f4f5",
		width: "60px",
		height: "60px",
		borderRadius: "10px",
		alignItems: "center",
		display: "flex",
		alignContent: "center",
		justifyContent: "center",
	},
	modal: {
		"& > :first-child": {
			padding: "0px !important",
		},
	},
	deleteButton: {
		boxShadow: "none !important",
	},
}));

const MakeScopePrettyAgain = (uglyScope) => {
	let splitScope = uglyScope.split("/");
	let combinedScope = splitScope[splitScope.length - 1];
	return combinedScope
		.split("And")
		.map((x) => x.split("_")[0])
		.join(", ");
};

const ApiSettingsTab = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { currentUser } = useContext(UserContext);
	const { list, setList } = useContext(ListContext);
	const load = () => {
		getApimSubscriptions(currentUser.tid).then((data) => {
			setList(data.responseData);
		});
	};

	useEffect(load, []);

	const apiKeyList = () => {
		return [...list]
			.sort((a, b) => new Date(b.properties.createdDate) - new Date(a.properties.createdDate))
			.map((item) => (
				<Table.Row key={item.identifier}>
					<Table.Cell>{item.properties.displayName}</Table.Cell>
					<Table.Cell>{MakeScopePrettyAgain(item.properties.scope)}</Table.Cell>
					<Table.Cell>
						{new Date(item.properties.createdDate).toLocaleDateString("en-us", {
							year: "2-digit",
							month: "2-digit",
							day: "2-digit",
						})}
					</Table.Cell>
					<Table.Cell className={classes.spacer}></Table.Cell>
					<Table.Cell textAlign="right" collapsing className={classes.actionButtons}>
						<div>
							<DeleteApiKey data={item} trigger={<DeleteButton className={classes.deleteButton} />} />
						</div>
					</Table.Cell>
				</Table.Row>
			));
	};

	return (
		<div className={classes.fullHeight}>
			{list?.length < 1 && (
				<div className={classes.mainContentEmpty}>
					<div className={classes.centeredBox}>
						<div className={classes.iconContainer}>
							<div className={classes.iconBackground}>
								<InfoIcon dataTooltipId="tooltip" size={40} color={"black"} />
							</div>
						</div>
						<Header as="h2" className={classes.emptyListMessageTitle}>
							You haven't generated any API keys yet
						</Header>
						<Header as="h4" color={"grey"} className={classes.emptyListMessageBody}>
							click the 'Generate API Key' button to get started
						</Header>
					</div>
				</div>
			)}
			{list?.length > 0 && (
				<div className={classes.mainContentKeyTable}>
					<div className={classes.keyTable}>
						<Table basic="very" className={classes.table}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>
										<FormattedMessage id="Name" />
									</Table.HeaderCell>
									<Table.HeaderCell>
										<FormattedMessage id="Services" />
									</Table.HeaderCell>
									<Table.HeaderCell>
										<FormattedMessage id="Date" />
									</Table.HeaderCell>
									<Table.HeaderCell className={classes.spacer}></Table.HeaderCell>
									<Table.HeaderCell textAlign="center" collapsing>
										<FormattedMessage id="Delete" />
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>{apiKeyList()}</Table.Body>
						</Table>
					</div>
				</div>
			)}
		</div>
	);
};

export default ApiSettingsTab;

import ScrollMenu, { filterAuthorizedAndLicensedTabs } from "@/components/scroll-menu/scroll-menu";
import RequireCurrentMachineGroup from "./require-current-machine-group";
import { createUseStyles, useTheme } from "react-jss";

import Flex from "@/components/flex";
import BoxLast from "@/views/production/box-last/box-last";
import ChangeCorrugate from "@/views/production/change-corrugate";
import JobQueue from "@/views/production/job-queue/job-queue";
import Orders from "@/views/production/orders/orders";
import ManualCartonBuild from "./manual-cartons";
import ManualMachineControl from "@/views/production/manual-machine-control";
import { Outlet } from "react-router";
import Scan2PackPOC from "./scan2pack-poc";
import { Segment } from "semantic-ui-react";
import { useIntl } from "react-intl";
import useScanToTriggerConfigured from "./use-scan-to-trigger-configured";
import MachineGroupControl from "./machine-group-control/machine-group-control";
import JobQueueProvider from "@/views/production/orders/job-queue-provider";

const useStyles = createUseStyles({
	submenu: {
		height: "64px",
		"border-bottom": "0px !important",
		background: "transparent !important",
		margin: "0px !important",
		padding: "0px 30px 2px 20px !important",
		border: "none !important",
	},
});

export const getAuthorizedProductionTabs = (currentUser, intl, isScanToTriggerConfigured) => {
	const tabs = [
		{
			defaultMenuItem: true,
			link: "production",
			text: intl.formatMessage({ id: "Job Queue" }),
			component: (
				<JobQueueProvider>
					<JobQueue />
				</JobQueueProvider>
			),
		},
		{
			link: "orders",
			text: intl.formatMessage({ id: "Orders" }),
			component: (
				<JobQueueProvider>
					<Orders />
				</JobQueueProvider>
			),
			licensed: "show-orders-page",
			subPaths: ["history"],
		},
		{
			link: "saved-jobs",
			text: intl.formatMessage({ id: "Packaging Creation" }),
			subPaths: [".*"],
			authorization: [
				"ManagePackagingSolutions",
				"ModifyAndSendPackagingSolutionToQueue",
				"SendPackagingSolutionToQueue",
				"CreatePackagingSolution",
			],
		},
		{
			link: "change-corrugate",
			text: intl.formatMessage({ id: "Change Corrugate" }),
			component: <ChangeCorrugate />,
			authorization: "ChangeLoadedCorrugatesOnMachine",
		},
		{
			link: "machine-control",
			text: intl.formatMessage({ id: "Machine Control" }),
			component: <ManualMachineControl />,
			authorization: "AccessMachineControl",
		},
		{
			link: "scan2pack",
			text: intl.formatMessage({ id: "Scan2Pack" }),
			component: <Scan2PackPOC />,
			licensed: "pack-net-scan-2-pack",
		},
		{
			link: "manual-cartons",
			text: intl.formatMessage({ id: "Die Cut Cartons" }),
			component: <ManualCartonBuild />,
			licensed: "pack-net-manual-cartons",
		},
	];

	if (isScanToTriggerConfigured) {
		tabs.push({
			link: "box-last",
			text: intl.formatMessage({ id: "Box Last" }),
			component: (
				<JobQueueProvider>
					<BoxLast />
				</JobQueueProvider>
			),
		});
	}

	return filterAuthorizedAndLicensedTabs(tabs, currentUser);
};

const ProductionRoutes = ({ currentUser }) => {
	const { isScanToTriggerConfigured } = useScanToTriggerConfigured();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const tabs = getAuthorizedProductionTabs(currentUser, useIntl(), isScanToTriggerConfigured);

	return (
		<>
			<Segment className={classes.submenu}>
				<Flex alignCenter justifyBetween>
					<ScrollMenu currentUser={currentUser} list={tabs.map((tab) => ({ ...tab }))} />
					<MachineGroupControl />
				</Flex>
			</Segment>
			<RequireCurrentMachineGroup>
				<Outlet />
			</RequireCurrentMachineGroup>
		</>
	);
};

export default ProductionRoutes;

import { createContext, useContext, useEffect } from "react";
import { aliasSort, ProductionContext } from "@/contexts/production-context";
import useAxios from "@/api/useAxios";
import { useQuery } from "@tanstack/react-query";
import { removeLocalStorage, writeLocalStorage } from "@/api/local-storage";
import useToken from "@/hooks/use-token";
import { UserContext } from "@/components/user-context";
import useSignalRHub from "@/hooks/use-signalr-hub/use-signalr-hub";
import useBaseRoutes from "@/hooks/use-base-routes";

export const MACHINE_GROUP_KEY = "currentMachineGroup";

export const MachineGroupContext = createContext({
	changeMachineGroup: () => {},
});

function MachineGroupProvider({ children }) {
	const token = useToken();
	const baseRoutes = useBaseRoutes();
	const MachineGroupApi = useAxios(`${baseRoutes.machineGroup}/v1/MachineGroups`, token);

	const { currentUser } = useContext(UserContext);

	const { hubConnection, usePolling } = useSignalRHub({
		hubUrl: "/MachineGroupApi/hubs/machineGroups",
		token,
	});

	const { machineGroups, setMachineGroups, currentMachineGroup, setCurrentMachineGroup } =
		useContext(ProductionContext);

	// Wiring up callbacks for when MachineGroups publishes messages
	useEffect(() => {
		if (!hubConnection || !currentUser) return;

		hubConnection.on("OnMachineGroupsVersion", (version) => {
			console.info(
				`signalr MachineGroup OnMachineGroupsVersion for connectionid ${hubConnection.connectionId}- ${version}`,
			);
		});

		hubConnection.on("OnMachineGroupUpdated", (tenantId, machineGroup) => {
			if (tenantId !== currentUser.Tenant) return;

			const oldMachineGroups = machineGroups.filter((pg) => pg.id !== machineGroup.id);
			const newMachineGroups = [...oldMachineGroups, machineGroup].sort(aliasSort);
			setMachineGroups(newMachineGroups);

			if (currentMachineGroup.id === machineGroup.id) changeMachineGroup(machineGroup);
		});

		hubConnection.on("OnMachineGroupAdded", (tenantId, machineGroup) => {
			if (tenantId !== currentUser.Tenant) return;

			const newMachineGroups = [...machineGroups, machineGroup].sort(aliasSort);
			setMachineGroups(newMachineGroups);
		});

		hubConnection.on("OnMachineGroupDeleted", (tenantId, deletedId) => {
			if (tenantId !== currentUser.Tenant) return;

			const newMachineGroups = machineGroups.filter((mg) => mg.id !== deletedId);
			setMachineGroups(newMachineGroups);

			if (currentMachineGroup.id === deletedId) changeMachineGroup(null);
		});

		// Cleanup block
		return () => {
			hubConnection.off("OnMachineGroupsVersion");
			hubConnection.off("OnMachineGroupUpdated");
			hubConnection.off("OnMachineGroupAdded");
			hubConnection.off("OnMachineGroupDeleted");
		};
	}, [hubConnection, currentMachineGroup, machineGroups, currentUser]);

	const queryFunction = async () => {
		const mgs = await MachineGroupApi.get();
		const sortedMgs = mgs.sort(aliasSort);
		setMachineGroups(sortedMgs);
		if (!currentMachineGroup) {
			return sortedMgs;
		}

		const current = mgs.find((mg) => mg.id === currentMachineGroup.id);
		if (!current) {
			changeMachineGroup(undefined);
		} else if (
			current.status !== currentMachineGroup.status ||
			current.productionEnabled !== currentMachineGroup.productionEnabled
		) {
			changeMachineGroup({ ...current });
		}
		return sortedMgs;
	};

	const queryKey = ["machineGroups", currentUser?.Tenant];

	useQuery({
		queryKey: queryKey,
		queryFn: queryFunction,
		refetchInterval: 3000,
		enabled: usePolling && Boolean(token),
	});

	const changeMachineGroup = (mg) => {
		setCurrentMachineGroup(mg);
		if (!mg) {
			removeLocalStorage(MACHINE_GROUP_KEY);
			console.info("Removed current machine group because we changed to undefined");
			return;
		}

		writeLocalStorage(MACHINE_GROUP_KEY, JSON.stringify(mg));
	};

	return <MachineGroupContext.Provider value={{ changeMachineGroup }}>{children}</MachineGroupContext.Provider>;
}

export default MachineGroupProvider;

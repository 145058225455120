import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useContext, useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { UserContext } from "@/components/user-context";

import CheckboxButton from "@/components/checkbox-button";
import CloseButton from "@/components/close-button";
import { ReactComponent as Error } from "@/assets/images/error.svg";
import ListContext from "@/components/list-context";
import NumUpDown from "@/components/numeric-up-down/numeric-up-down";
import { isNullOrWhitespace } from "@/utils/form-validation";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	rowGroup: {
		height: "calc(100% - 30px)",
		maxHeight: "calc(100% - 30px)",
		backgroundColor: theme.colors.white,
		borderRadius: "6px",
		marginLeft: "-8px",
		"& .row": {
			marginLeft: "1rem",
			marginRight: "1rem",
		},
		overflowY: "auto",
	},
	productionGroups: {
		marginLeft: "10px !important",
		marginTop: "10px !important",
		marginBottom: "10px !important",
		...theme.typography.bodyBold,
	},
	cpgForm: {
		...theme.configurationDialog.form,
		paddingBottom: "0 !important",
		height: "calc(100vh - 170px)",
		"& form": {
			height: "100%",
		},
		"& form > .grid": {
			height: "100%",
		},
		"& form > .grid > .column": {
			height: "100%",
		},
	},
}));

const NewCartonPropertyGroup = (props) => {
	const intl = useIntl();
	const theme = useTheme();
	const classes = useStyles({ theme });
	const { currentUser } = useContext(UserContext);
	const useAssignPgToCpgs = currentUser["ff-cpg-allow-pg-assignment"];
	const token = readLocalStorage("BEARER");
	const { closeModal } = props;
	const [errors, setErrors] = useState({});
	const [productionGroups, setProductionGroups] = useState([]);
	const { setList, edit, setEdit } = useContext(ListContext);
	const productionGroupsApi = useAxios("/ProductionGroupApi/api/v1/ProductionGroups", token);
	const { add, updateWithId } = useAxios("/cartonPropertyGroupApi/api/v1/cartonPropertyGroups", token);

	useEffect(() => {
		if (Object.keys(edit).length === 0) {
			setEdit({
				ratio: 1,
				assignedToProductionGroups: [],
			});
		} else if (!edit.assignedToProductionGroups) {
			setEdit({ ...edit, assignedToProductionGroups: [] });
		}

		productionGroupsApi.get((data) => {
			data.sort((a, b) => (a.alias > b.alias ? 1 : -1));
			setProductionGroups(data);
		});
	}, []);

	const validate = () => {
		const newErrors = {};
		if (isNullOrWhitespace(edit.alias)) newErrors.alias = intl.formatMessage({ id: "Name is required" });
		setErrors(newErrors);
		return newErrors;
	};

	const close = () => {
		closeModal();
	};

	const saveAndClose = async () => {
		const validationErrors = validate();
		if (validationErrors.alias) return;

		try {
			if (edit.id) {
				delete edit.status;
				await updateWithId(edit, setList);
			} else await add(edit, setList);

			close();
		} catch (e) {
			if (e.response && e.response.status === 409)
				setErrors({
					...validationErrors,
					addOrUpdate: intl.formatMessage(
						{
							id: "Failed to save Carton Property Group: An item already exists with the name {name}",
						},
						{ name: edit.alias },
					),
				});
			else
				setErrors({
					...validationErrors,
					addOrUpdate: intl.formatMessage({
						id: "Failed to save Carton Property Group",
					}),
				});
		}
	};

	return (
		<Segment.Group className={classes.group}>
			<Segment className={classes.header}>
				<Header as="h2" floated="left" textAlign="center" className={classes.headerText}>
					<FormattedMessage id={edit.id ? "Edit Carton Property Group" : "New Carton Property Group"} />
				</Header>
				<Header floated="right" className={classes.closeButton}>
					<CloseButton onClick={close} />
				</Header>
				<Header floated="right" className={classes.saveButton}>
					<Button primary onClick={saveAndClose} data-cy="carton-property-group-save-button">
						<FormattedMessage id="Save" />
					</Button>
				</Header>
			</Segment>
			{!useAssignPgToCpgs ? (
				<Segment className={classes.form}>
					<Form error data-cy="add-form">
						<Grid relaxed="very" stackable className={classes.topGrid}>
							<Grid.Row columns={1}>
								<Grid.Column>
									<Form.Input
										required
										label={intl.formatMessage({ id: "Name" })}
										placeholder={intl.formatMessage({ id: "Name" })}
										value={edit.alias}
										onChange={(e) => setEdit({ ...edit, alias: e.target.value })}
										error={errors.alias ? { content: errors.alias } : null}
										icon
										data-cy="carton-property-group-name-input"
									>
										<input />
										{errors.alias && (
											<i className="icon">
												<Error className={classes.inputError} />
											</i>
										)}
									</Form.Input>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
					</Form>
				</Segment>
			) : (
				<Segment className={classes.cpgForm}>
					<Form error data-cy="add-form">
						<Grid relaxed="very" stackable divided>
							<Grid.Row>
								<Grid.Column width={6}>
									<Form.Input
										required
										label={intl.formatMessage({ id: "Name" })}
										placeholder={intl.formatMessage({ id: "Name" })}
										value={edit.alias}
										onChange={(e) => setEdit({ ...edit, alias: e.target.value })}
										error={errors.alias ? { content: errors.alias } : null}
										icon
										data-cy="carton-property-group-name-input"
									>
										<input />
										{errors.alias && (
											<i className="icon">
												<Error className={classes.inputError} />
											</i>
										)}
									</Form.Input>
									<Form.Input label={intl.formatMessage({ id: "Ratio" })}>
										<NumUpDown
											number={parseInt(edit?.ratio ?? 1, 10)}
											minValue={1}
											setValue={(num) => {
												setEdit({ ...edit, ratio: num });
											}}
										/>
									</Form.Input>
								</Grid.Column>
								<Grid.Column width={8}>
									<Grid.Row className={classes.columnHeader}>
										<FormattedMessage id="Production Groups" />
									</Grid.Row>
									<div className={classes.rowGroup}>
										{productionGroups.map((productionGroup) => (
											<CheckboxButton
												className={classes.productionGroups}
												defaultChecked={edit.assignedToProductionGroups.includes(productionGroup.id)}
												onChange={(e) => {
													if (e.target.checked)
														setEdit({
															...edit,
															assignedToProductionGroups: [...edit.assignedToProductionGroups, productionGroup.id],
														});
													else {
														setEdit({
															...edit,
															assignedToProductionGroups: edit.assignedToProductionGroups.filter(
																(i) => i !== productionGroup.id,
															),
														});
													}
												}}
											>
												{productionGroup.alias}
											</CheckboxButton>
										))}
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						{errors.addOrUpdate && <Message error header={errors.addOrUpdate} />}
					</Form>
				</Segment>
			)}
		</Segment.Group>
	);
};

export default NewCartonPropertyGroup;

import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { createUseStyles, useTheme } from "react-jss";

import { Input } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
	},
	mainInput: {
		width: "400px !important",
	},
}));

const DimensioningWeight = ({
	prompt,
	example,
	defaultPrompt,
	product,
	setProduct,
	isEdit,
	inputRefs = null,
	activeStep = 0,
}) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const finalExample = example?.length ? example : defaultPrompt;
	let refs = null;
	if (inputRefs) {
		refs = inputRefs;
	}
	return (
		<Fragment>
			<div className={classes.prompt}>
				<FormattedMessage id={isEdit ? defaultPrompt : prompt?.length ? prompt : defaultPrompt}></FormattedMessage>
			</div>
			<Input
				className={classes.mainInput}
				label=""
				placeholder={finalExample}
				value={product.weight}
				onChange={(_, { value }) => setProduct({ ...product, weight: value })}
				ref={(ref) => {
					if (refs) refs.current[activeStep] = ref;
				}}
			/>
		</Fragment>
	);
};

export default DimensioningWeight;

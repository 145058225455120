import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import Chart from "react-apexcharts";

import CheckboxButton from "@/views/reporting/checkbox-button";
import { getNumericAbbreviation } from "../../functions/swimlanes";
import { getDateIndex, timeLabelFormatter } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";
import { sortByValue } from "@/views/reporting/functions/sort";

const useStyles = createUseStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		"& > div:first-child": {
			flexBasis: "70%",
		},
		"& > div:last-child": {
			flexBasis: "30%",
		},
	},
	errorContainer: {
		display: "flex",
		marginBottom: "32px",
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	sideMenuBody: {
		display: "flex",
		flexDirection: "column",
		gap: "1%",
		height: "343px",
		overflow: "auto",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "white",
		},
	},
	sideMenuHeader: {
		display: "flex",
		flexDirection: "column",
		flexFlow: "column wrap",
	},
	checkbox: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
}));

function CartonProductionOverTimePerMachineGraph({ reportData, dateRange }) {
	const classes = useStyles();
	const [aggregatedData, setAggregatedData] = useState([]);
	const [siteBrandTypes, setSiteBrandTypes] = useState([]);
	const intl = useIntl();

	function toggleMachineSelection(tenantId, machineName) {
		const site = siteBrandTypes.find((s) => s.tenantId === tenantId);
		const machine = site.machines.find((c) => c.machineName === machineName);
		machine.checked = !machine.checked;
		setSiteBrandTypes([...siteBrandTypes]);
	}

	function getOptions(allData, sitesSelection) {
		if (!allData || !allData.length) return { series: [] };

		const flatMachines = sitesSelection.flatMap((x) => x.machines);
		const presentationData = allData.filter(
			(d) => flatMachines.find((s) => s.tenantId === d.tenantId && s.machineName === d.machineName).checked,
		);

		if (!presentationData || !presentationData.length) return { series: [] };
		sortByValue(presentationData, "machineName", "asc");
		const colors = presentationData.map((a) => a.color);
		const series = presentationData.map((a) => ({
			name: a.machineName,
			data: a.data.map((d) => d.cartonsProduced),
		}));
		const sumOfSelected = flatMachines.find((s) => s.machineName === intl.formatMessage({ id: "Sum of Selected" }));
		const numberOfSelected = flatMachines.filter(
			(m) => m.checked && m.machineName !== intl.formatMessage({ id: "Sum of Selected" }),
		).length;
		if ((!sumOfSelected || sumOfSelected.checked) && numberOfSelected > 1) {
			colors.push("#000");
			const data = [];
			for (let i = 0; i < series[0].data.length; i++) {
				data.push(0);
			}
			for (let i = 0; i < series.length; i++) {
				const s = series[i];
				for (let j = 0; j < s.data.length; j++) {
					const value = s.data[j];
					data[j] += value;
				}
			}
			series.push({ name: "Sum of Selected", data: data });
		}

		const indexDate = getDateIndex(incrementDateRange(dateRange));
		let labelText = indexDate;

		const allDates = presentationData[0].data.map((item) => {
			return timeLabelFormatter(item, indexDate, 0, true);
		});
		const xaxisCategories = presentationData[0].data.map((item, i) => {
			return timeLabelFormatter(item, indexDate, i, false);
		});

		return {
			chart: {
				toolbar: {
					show: false,
					tools: {
						zoom: false,
					},
				},
				type: "line",
				stacked: false,
			},
			dataLabels: {
				enabled: false,
			},
			colors: colors,
			series: series,
			stroke: {
				curve: "smooth",
				width: [1, 1],
			},
			markers: {
				size: 0,
			},
			plotOptions: {
				bar: {
					columnWidth: "20%",
				},
			},
			xaxis: {
				labels: {
					style: {
						colors: "#7c7b79",
						fontSize: "16px",
						fontFamily: "Stolzl-Book",
					},
				},
				title: {
					text: intl.formatMessage({ id: labelText }),
					style: {
						color: "#353430",
						fontFamily: "Stolzl",
						fontSize: "15px",
						fontWeight: "normal",
					},
					offsetY: 0,
				},
				categories: xaxisCategories,
			},
			yaxis: [
				{
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
						color: "#e3e7e9",
					},
					labels: {
						style: {
							colors: "#7c7b79",
							fontSize: "16px",
							fontFamily: "Stolzl-Book",
						},
						formatter: (value) => {
							return getNumericAbbreviation(value, true);
						},
						offsetX: 32,
					},
					title: {
						text: intl.formatMessage({ id: "Cartons Made" }),
						style: {
							color: "#353430",
							fontFamily: "Stolzl",
							fontSize: "15px",
							fontWeight: "normal",
						},
						offsetX: -20,
					},
				},
			],
			tooltip: {
				show: false,
				x: {
					formatter: (index) => {
						return allDates[index - 1];
					},
				},
			},
			legend: {
				show: false,
			},
			grid: {
				show: true,
				borderColor: "#e3e7e9",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 52,
				},
			},
		};
	}

	function toggleAllMachines() {
		const allChecked = siteBrandTypes.flatMap((s) => s.machines).every((i) => i.checked);
		siteBrandTypes.forEach((s) => s.machines.forEach((m) => (m.checked = !allChecked)));
		setSiteBrandTypes([...siteBrandTypes]);
	}

	function mapData(data) {
		let aggregation = data.overTime.map((item) => {
			const color = Math.floor(Math.random() * 16777215)
				.toString(16)
				.padStart(6, "0");
			return {
				...item,

				tenantId: "",
				siteId: "",
				siteName: "",
				color: `#${color}`,
			};
		});

		// Sort data per group
		aggregation = aggregation.map((group) => {
			group.data.sort((a, b) => {
				const aDate = new Date(a.year, a.month - 1, a.day, a.hour);
				const bDate = new Date(b.year, b.month - 1, b.day, b.hour);
				return aDate - bDate;
			});
			return group;
		});

		setAggregatedData(aggregation);
		const aggregationGroupBySites = aggregation.reduce((groups, item) => {
			const group = groups.find((i) => i.tenantId === item.tenantId);
			if (group) {
				group.machines = [
					...group.machines,
					{
						tenantId: item.tenantId,
						machineName: item.machineName,
						checked: true,
						color: item.color,
					},
				];
				return groups;
			}
			return [
				...groups,
				{
					tenantId: item.tenantId,
					siteId: item.siteId,
					siteName: item.siteName,
					machines: [
						{
							tenantId: item.tenantId,
							machineName: item.machineName,
							checked: true,
							color: item.color,
						},
					],
				},
			];
		}, []);
		const checkboxValues = aggregationGroupBySites
			.map((item) => {
				return {
					...item,
					machines: item.machines.sort((a, b) => (a.machineName > b.machineName ? 1 : -1)),
				};
			})
			.sort((a, b) => (a.siteName > b.siteName ? 1 : -1));
		if (checkboxValues.length && checkboxValues[0].machines.length > 1) {
			checkboxValues[0].machines.push({
				tenantId: checkboxValues[0].machines[0].tenantId,
				machineName: intl.formatMessage({ id: "Sum of Selected" }),
				checked: true,
				color: "#000",
			});
		}
		setSiteBrandTypes(checkboxValues);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const options = getOptions(aggregatedData, siteBrandTypes);

	const allChecked = siteBrandTypes.every((s) => s.machines.every((m) => m.checked) === true);

	return siteBrandTypes.length ? (
		<div className={classes.container}>
			<Chart options={options} series={options.series} type="line" width="95%" height="353px" />
			<div>
				<div className={classes.sideMenuHeader}>
					{siteBrandTypes[0].machines.length > 1 && (
						<CheckboxButton
							key={`cb-all`}
							color={"black"}
							checked={siteBrandTypes.flatMap((s) => s.machines).every((i) => i.checked)}
							onChange={() => toggleAllMachines()}
						>
							{allChecked ? <FormattedMessage id="Deselect All" /> : <FormattedMessage id="Select All" />}
						</CheckboxButton>
					)}
				</div>
				<div className={classes.sideMenuBody}>
					{siteBrandTypes.map((item) => {
						const sumOfSelected = item.machines.filter(
							(s) => s.machineName === intl.formatMessage({ id: "Sum of Selected" }),
						)[0];
						const machines = item.machines.filter(
							(s) => s.machineName !== intl.formatMessage({ id: "Sum of Selected" }),
						);
						const numberOfSelected = machines.filter((m) => m.checked).length;
						if (numberOfSelected > 1) {
							machines.push(sumOfSelected);
						}
						return (
							<div className={classes.siteGroup}>
								<div>
									{machines.map((machine) => (
										<CheckboxButton
											key={`cb-${machine.siteId}-${machine.machineName}`}
											color={machine.color}
											checked={machine.checked}
											className={classes.checkbox}
											onChange={() => toggleMachineSelection(item.tenantId, machine.machineName)}
										>
											{machine.machineName}
										</CheckboxButton>
									))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		<div className={classes.errorContainer}>
			<FormattedMessage id="No Cartons Have Been Produced" />
		</div>
	);
}

export default CartonProductionOverTimePerMachineGraph;

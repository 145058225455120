import { createUseStyles, useTheme } from "react-jss";
import DualProgressBarGroup from "./dual-progress-bar-group";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "column",
		"& > div:last-child": {
			paddingBottom: "32px",
		},
	},
	heading: {
		fontSize: "17px",
		fontStyle: "normal",
		fontFamily: "Stolzl-Medium",
		fontWeight: "500",
		lineHeight: "normal",
		fontStretch: "normal",
		letterSpacing: "normal",
		paddingBottom: "20px",
	},
	progressBarGroupWrapper: {
		paddingBottom: "20px",
	},
});

function DualProgressBarList({ heading, items, limit }) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	let count = 0;
	return (
		<div className={classes.container}>
			{items.map((item, i) => {
				if (count >= limit) {
					return "";
				}
				count++;
				return (
					<div className={classes.progressBarGroupWrapper} key={`dual-progress-bar-group-div-${heading}-${i}`}>
						<DualProgressBarGroup
							leftText={item.text}
							rightText={item.value}
							successPercent={item.cartonsProducedPercentage}
							failedPercent={item.cartonsFailedPercentage}
							successNumber={item.cartonsProduced}
							failedNumber={item.cartonsFailed}
						/>
					</div>
				);
			})}
		</div>
	);
}

export default DualProgressBarList;

import { Fragment, useContext, useEffect, useState } from "react";
import { useTheme } from "react-jss";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Grid, Header, Image, Modal } from "semantic-ui-react";
import { ReactComponent as ErrorIcon } from "@/assets/images/error-current.svg";
import { ProductionContext } from "@/contexts/production-context";
import { ReactComponent as WarningIcon } from "@/assets/images/warning-current.svg";
import machineTypes from "@/constants/machineTypes";
import EmErrorsAndWarnings from "./errors-and-warnings-em";
import FusionErrorsAndWarnings from "./errors-and-warnings-fusion";
import X4ErrorsAndWarningsNew from "./errors-and-warnings-x4-new";
import X5ErrorsAndWarnings from "./errors-and-warnings-x5";
import X6ErrorsAndWarnings from "./errors-and-warnings-x6";
import ZebraErrorsAndWarnings from "./errors-and-warnings-zebra";
import CloseButton from "./close-button";
import X7ErrorsAndWarnings from "./errors-and-warnings-x7";
import Flex from "./flex";

const images = Object.fromEntries(
	Object.entries(
		import.meta.glob("../assets/images/errors-and-warnings/**/*.{png,jpe,jpeg,svg}", {
			query: "?url",
			import: "default",
		}),
	).map(([path, module]) => [path.replace("../assets/images/errors-and-warnings/", ""), module]),
);

export default function ErrorsAndWarningsOld() {
	const intl = useIntl();
	const theme = useTheme();
	const { currentMachineGroup } = useContext(ProductionContext);
	const [machines, setMachines] = useState([]);
	const [machineServices, setMachineServices] = useState();
	const [errors, setErrors] = useState([]);
	const [warnings, setWarnings] = useState([]);
	const [selectedItem, setSelectedItem] = useState();
	const [showError, setShowingError] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		const newMachines =
			Object.entries(currentMachineGroup?.configuredMachinesWithMachineType ?? {}).map(([machineId, machineType]) => ({
				machineId,
				machineType,
			})) ?? [];

		const newIds = new Set(newMachines.map((x) => x.machineId));
		const existingIds = new Set(machines.map((x) => x.machineId));
		if (newIds.size !== existingIds.size || ![...newIds].every((x) => existingIds.has(x))) {
			setErrors([]);
			setWarnings([]);
			setMachines(newMachines);
		}
	}, [currentMachineGroup, machines]);

	useEffect(() => {
		function updateErrors(machineId, newErrors) {
			setErrors((oldErrors) => {
				const updatedList = oldErrors.filter((x) => x.machineId !== machineId).concat(newErrors);
				const lastIndex = updatedList.length - 1;
				updatedList.forEach((x, index) => {
					x.index = index;
					x.lastIndex = lastIndex;
				});
				return updatedList;
			});
		}

		function updateWarnings(machineId, newWarnings) {
			setWarnings((oldWarnings) => {
				const updatedList = oldWarnings.filter((x) => x.machineId !== machineId).concat(newWarnings);
				const lastIndex = updatedList.length - 1;
				updatedList.forEach((x, index) => {
					x.index = index;
					x.lastIndex = lastIndex;
				});
				return updatedList;
			});
		}

		const includedMachineTypes = new Set(machines.map((x) => x.machineType));
		setMachineServices(
			[...includedMachineTypes].map((machineType, index) => {
				switch (machineType) {
					case machineTypes.Em:
						return <EmErrorsAndWarnings key={index} machines={machines} onErrorsChanged={updateErrors} />;
					case machineTypes.Fusion:
						return <FusionErrorsAndWarnings key={index} machines={machines} onErrorsChanged={updateErrors} />;
					case machineTypes.X4:
						return (
							<X4ErrorsAndWarningsNew
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.X5:
					case machineTypes.X5wPrint:
						return (
							<X5ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.X6:
						return (
							<X6ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.X7:
						return (
							<X7ErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					case machineTypes.ZebraPrinter:
						return (
							<ZebraErrorsAndWarnings
								key={index}
								machines={machines}
								onErrorsChanged={updateErrors}
								onWarningsChanged={updateWarnings}
							/>
						);
					default:
						console.error(`No errors and warnings service for ${machineType} machines`);
						return undefined;
				}
			}),
		);
	}, [machines]);

	useEffect(() => {
		if (!isModalOpen) {
			return;
		}

		let currentItem;
		if (!selectedItem) {
			changeSelectedItem(showError ? errors[0] : warnings[0]);
		} else if (showError) {
			currentItem =
				errors.find(
					(x) =>
						x.machineId === selectedItem.machineId &&
						x.code === selectedItem.code &&
						x.parameter === selectedItem.parameter,
				) ?? errors[0];
		} else {
			currentItem =
				warnings.find(
					(x) =>
						x.machineId === selectedItem.machineId &&
						x.code === selectedItem.code &&
						x.parameter === selectedItem.parameter,
				) ?? warnings[0];
		}

		if (currentItem === selectedItem) {
			return;
		}

		if (currentItem) {
			changeSelectedItem(currentItem);
		} else {
			closeModal();
		}
	}, [isModalOpen, errors, warnings, showError, selectedItem]);

	function closeModal() {
		setIsModalOpen(false);
		changeSelectedItem();
	}

	async function changeSelectedItem(newItem) {
		if (newItem) {
			newItem.title = newItem.title ?? getTitle(newItem);
			newItem.image = newItem.image ?? (await getImagePath(newItem));
		}
		setSelectedItem(newItem);
	}

	function getTitle(item) {
		const translationKey = `${item.machineType} ${item.severity} ${item.code}${
			item.parameter ? `-${item.parameter}` : ""
		}`;
		let actionMessage = "";
		if (item.actionKey) {
			const actionKey = `X5 Action ${item.actionKey}`;
			actionMessage = intl.formatMessage({ id: actionKey });
			if (actionMessage === actionKey) {
				actionMessage = "";
			}
		}
		return `${
			isNaN(item.code) ? "" : `${item.code}${item.parameter ? `-${item.parameter}: ` : ": "}`
		}${intl.formatMessage({ id: translationKey })}${actionMessage}`;
	}

	async function getImagePath(item) {
		const module =
			images[(item.machineType + "/" + item.severity + "/" + item.code + ".svg").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/" + item.code + ".png").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/" + item.category + ".svg").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/" + item.category + ".png").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/default.svg").toLowerCase()] ??
			images[(item.machineType + "/" + item.severity + "/default.png").toLowerCase()];

		if (module) return await module();
	}

	function displayPreviousItem() {
		if (showError) {
			changeSelectedItem(errors[selectedItem.index - 1]);
		} else {
			changeSelectedItem(warnings[selectedItem.index - 1]);
		}
	}

	function displayNextItem() {
		if (showError) {
			changeSelectedItem(errors[selectedItem.index + 1]);
		} else {
			changeSelectedItem(warnings[selectedItem.index + 1]);
		}
	}

	return (
		<Fragment>
			{machineServices}
			<Modal
				open={isModalOpen}
				dimmer="blurring"
				size="large"
				centered={false}
				closeOnEscape={false}
				closeOnDimmerClick={false}
				style={{
					// width: "1302px",
					padding: "12px",
					backgroundColor: showError
						? theme.error.replace(" !important", "")
						: theme.warning.replace(" !important", ""),
				}}
				trigger={
					<Flex alignCenter>
						<Button
							disabled={errors.length < 1}
							onClick={() => {
								setShowingError(true);
								setIsModalOpen(true);
							}}
							style={{
								backgroundColor: theme.error.replace(" !important", ""),
								marginRight: "24px",
								width: "40px",
								height: "40px",
								padding: "0px",
								visibility: errors.length > 0 ? "visible" : "hidden",
							}}
						>
							<ErrorIcon fill="#fff" />
						</Button>
						<Button
							disabled={warnings.length < 1}
							onClick={() => {
								setShowingError(false);
								setIsModalOpen(true);
							}}
							style={{
								backgroundColor: theme.warning.replace(" !important", ""),
								marginLeft: "auto",
								width: "40px",
								height: "40px",
								padding: "0px ",
								visibility: warnings.length > 0 ? "visible" : "hidden",
							}}
						>
							<WarningIcon fill="#fff" />
						</Button>
					</Flex>
				}
			>
				<Modal.Header
					style={{
						padding: 0,
						borderRadius: "0px",
						borderBottomWidth: "0px",
						display: "flex",
					}}
				>
					<Grid.Column style={{ paddingLeft: 0 }}>
						{showError ? (
							<ErrorIcon
								fill="#fff"
								style={{
									backgroundColor: theme.error.replace(" !important", ""),
									padding: "12px 24px 24px 12px",
									borderRadius: "0px 0px 6px 0px",
									width: "116px",
									height: "116px",
								}}
							/>
						) : (
							<WarningIcon
								fill="#fff"
								style={{
									backgroundColor: theme.warning.replace(" !important", ""),
									padding: "12px 24px 24px 12px",
									borderRadius: "0px 0px 6px 0px",
									width: "116px",
									height: "116px",
								}}
							/>
						)}
					</Grid.Column>
					<Grid.Column style={{ padding: "0px 24px 0px 24px", alignSelf: "center", flexGrow: 1 }}>
						<Header>{selectedItem?.title}</Header>
					</Grid.Column>
					<Grid.Column textAlign="right" style={{ paddingTop: 12, paddingRight: 12 }}>
						<CloseButton onClick={closeModal} style={{ height: "36px", width: "36px" }} />
					</Grid.Column>
				</Modal.Header>
				<Modal.Content
					style={{
						borderRadius: "0px",
						padding: 0,
						paddingLeft: "12px",
						paddingRight: "12px",
						height: "459px",
					}}
				>
					<Image
						src={selectedItem?.image}
						style={{
							margin: "auto",
							maxWidth: "1092px",
							maxHeight: "459px",
						}}
					/>
				</Modal.Content>
				<Modal.Actions
					style={{
						padding: "12px",
						borderRadius: "0px",
						borderTopWidth: "0px",
						backgroundColor: theme.white,
						display: "flex",
					}}
				>
					<Grid.Column>
						{selectedItem?.index > 0 && (
							<Button primary onClick={displayPreviousItem}>
								<FormattedMessage id="Previous" />
							</Button>
						)}
					</Grid.Column>
					<Grid.Column style={{ flexGrow: 1 }} />
					<Grid.Column>
						{selectedItem?.index !== selectedItem?.lastIndex && (
							<Button primary onClick={displayNextItem}>
								<FormattedMessage id="Next" />
							</Button>
						)}
					</Grid.Column>
				</Modal.Actions>
			</Modal>
		</Fragment>
	);
}

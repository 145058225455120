import { createContext, useCallback } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Flex from "@/components/flex";

import { NavIcon } from "@/views/navbar/navbar";

export const FullScreenContext = createContext({
	fullScreenActive: false,
	FullScreenControl: () => {},
});

function FullScreenProvider({ children }) {
	const fullScreenHandle = useFullScreenHandle();

	const FullScreenControl = useCallback(
		({ color }) => (
			<Flex alignCenter>
				<NavIcon
					size="large"
					onClick={() => {
						if (fullScreenHandle.active) fullScreenHandle.exit();
						else fullScreenHandle.enter();
					}}
					name={fullScreenHandle.active ? "window maximize outline" : "expand arrows alternate"}
					color={color || "white"}
				/>
			</Flex>
		),
		[fullScreenHandle],
	);

	return (
		<FullScreen handle={fullScreenHandle}>
			<FullScreenContext.Provider value={{ fullScreenActive: fullScreenHandle.active, FullScreenControl }}>
				{children}
			</FullScreenContext.Provider>
		</FullScreen>
	);
}

export default FullScreenProvider;

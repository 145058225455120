import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	/* The container */
	container: {
		display: "block",
		position: "relative",
		paddingLeft: "35px",
		marginBottom: "12px",
		cursor: "pointer",
		userSelect: "none",

		"& input": {
			position: "absolute",
			opacity: "0",
			cursor: "pointer",
		},

		"& input:checked ~ $checkmark": {
			backgroundColor: "white",
			border: "2px solid #000",
		},

		"& input:checked ~ $checkmark:after": {
			display: "block",
		},

		"& $checkmark:after": {
			top: "3px",
			left: "3px",
			width: "10px",
			height: "10px",
			borderRadius: "50%",
			backgroundColor: "#000",
		},
	},

	checkmark: {
		position: "absolute",
		top: "0",
		left: "0",
		height: "20px",
		width: "20px",
		backgroundColor: "white",
		border: "2px solid #7C7B79",
		borderRadius: "50%",

		"&:after": {
			content: '""',
			position: "absolute",
			display: "none",
		},
	},
});

export default function RadioButton({ children, className, ...others }) {
	const classes = useStyles();

	return (
		<label className={`${classes.container} ${className}`}>
			{children}
			<input type="radio" {...others} />
			<span className={classes.checkmark} />
		</label>
	);
}

import React, { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";

import { getAvailabilityDisplayData } from "@/views/reporting/functions/availability-data";
import { twentyFourHourToTwelveHourHourFormat } from "@/views/reporting/functions/over-time";
import { getPaginationResult } from "@/views/reporting/functions/swimlanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import ChangeTimeFrame from "./change-time-frame";
import ReportingTable from "@/views/reporting/reporting-table";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "row",
		"$ > table": {
			maxHeight: "376px",
		},
		marginBottom: "20px",
		width: "calc(97vw - 55px)",
	},
	showAllContainer: {
		composes: "$container",
		maxHeight: "1024px",
	},
	headerCell: {
		backgroundColor: "#2e475f !important",
		color: "white !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		fontWeight: "500 !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		borderLeft: "1px solid #c4c4c4 !important",
		minWidth: "150px",
		height: "80px",
	},
	contentRow: {
		color: "#353430 !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		"& > td": {
			border: "1px solid #c4c4c4 !important",
		},
	},
	evenRow: {
		composes: "$contentRow",
		backgroundColor: "#FFF !important",
	},
	oddRow: {
		composes: "$contentRow",
		backgroundColor: "rgba(0, 0, 50, 0.02) !important",
	},
	tableHeader: {
		position: "sticky",
		top: 0,
	},
});

function MachineStatusByHourTable({
	reportData,
	dateRange,
	limit,
	setMachine,
	headerText,
	headerButtons,
	isNewFormat,
}) {
	const classes = useStyles();
	const intl = useIntl();
	const [presentationData, setPresentationData] = useState([]);
	const [validTimeFrame, setValidTimeFrame] = useState(true);
	const [pagination, setPagination] = useState({
		perPage: 1,
		page: 1,
	});

	const columns = React.useMemo(
		() => [
			{
				Header: intl.formatMessage({ id: "Date" }),
				accessor: "date",
			},
			{
				Header: intl.formatMessage({ id: "Time" }),
				accessor: "time",
			},
			{
				Header: intl.formatMessage({ id: "Machine Name" }),
				Cell: ({ row }) => row.original.machineName,
			},
			{
				Header: intl.formatMessage({ id: "Duration in Minutes" }),
				accessor: "duration",
			},
			{
				Header: intl.formatMessage({ id: "Status" }),
				accessor: "status",
			},
		],
		[intl],
	);

	function getTimeValues(item) {
		const startHour = parseInt(item.startTime.split("T")[1].split(":")[0]);
		let endHour = parseInt(item.endTime.split("T")[1].split(":")[0]);
		const startDay = parseInt(item.startTime.split("T")[0].split("-")[2]);
		let endDay = parseInt(item.endTime.split("T")[0].split("-")[2]);
		const date = item.startTime.split("T")[0];
		if (startHour > endHour && endHour === 0) {
			endHour = 23;
		} else if (startHour === endHour && startDay !== endDay) {
			endHour = 23;
		}
		if (startHour === endHour) {
			return [
				{
					hour: startHour,
					seconds: (Date.parse(item.endTime) - Date.parse(item.startTime)) / 1000,
				},
			];
		}
		const timeValues = [];
		let start = item.startTime;
		while (true) {
			const current = parseInt(start.split("T")[1].split(":")[0]);
			const next = current + 1;
			if (current < endHour) {
				const temp = `${date}T${String(next).padStart(2, "0")}:00:00`;
				const result = {
					hour: current,
					seconds: (Date.parse(temp) - Date.parse(start)) / 1000,
				};
				timeValues.push(result);
				start = temp;
			} else {
				const result = {
					hour: current,
					seconds: (Date.parse(item.endTime) - Date.parse(start)) / 1000,
				};
				timeValues.push(result);
				break;
			}
		}

		return timeValues;
	}

	function mapData(data) {
		let statuses = [];
		let aggregation = [];
		const totalTime = Date.parse(dateRange.end) - Date.parse(dateRange.start);
		if (totalTime > 24 * 60 * 60 * 1000) {
			setValidTimeFrame(false);
		} else {
			setValidTimeFrame(true);
		}

		if (isNewFormat) {
			statuses = data.byMachineByHour
				.map((item) => {
					return item.data;
				})
				.flat()
				.reduce((groups, item) => {
					const [ordering, color, displayStatus] = getAvailabilityDisplayData(item.name);
					const group = groups.find((i) => i.status === displayStatus);
					if (!group) {
						return [
							...groups,
							{
								status: displayStatus,
								color: color,
								ordering: ordering,
							},
						];
					}
					return groups;
				}, []);
			aggregation = data.byMachineByHour.map((item) => {
				item.data = item.data.map((dataItem) => {
					let [ordering, color, displayStatus] = getAvailabilityDisplayData(dataItem.name);
					dataItem.status = displayStatus;
					dataItem.color = color;
					dataItem.ordering = ordering;
					return dataItem;
				});
				item.date = dateRange.start.split("T")[0];
				return item;
			});
		} else {
			statuses = data.reduce((groups, item) => {
				const [ordering, color, displayStatus] = getAvailabilityDisplayData(item.status);
				const group = groups.find((i) => i.status === displayStatus);
				if (!group) {
					return [
						...groups,
						{
							status: displayStatus,
							color: color,
							ordering: ordering,
						},
					];
				}
				return groups;
			}, []);
			statuses.sort((a, b) => {
				if (a.ordering < b.ordering) return -1;
				if (a.ordering > b.ordering) return 1;
				return 0;
			});

			function getSeriesTemplate(statuses) {
				return statuses.reduce((groups, item) => {
					return [
						...groups,
						{
							name: item.status,
							data: new Array(24).fill(0),
						},
					];
				}, []);
			}

			aggregation = data.reduce((groups, item) => {
				const timeValues = getTimeValues(item);
				const displayStatus = getAvailabilityDisplayData(item.status)[2];
				let group = groups.find((i) => i.machineId === item.machineId);
				if (!group) {
					groups = [
						...groups,
						{
							machineId: item.machineId,
							machineName: item.machineName,
							date: dateRange.start.split("T")[0],
							data: getSeriesTemplate(statuses),
						},
					];
				}
				group = groups.find((i) => i.machineId === item.machineId);
				const section = group.data.find((i) => i.name === displayStatus);
				for (const tv of timeValues) {
					section.data[tv.hour] += tv.seconds;
				}
				return groups;
			}, []);
		}

		let records = [];
		for (let item of aggregation) {
			for (let statusRecord of item.data) {
				let i = 0;
				for (let seconds of statusRecord.data) {
					if (seconds !== 0) {
						records.push({
							date: item.date,
							machineId: item.machineId,
							machineName: item.machineName,
							time: twentyFourHourToTwelveHourHourFormat(i),
							hour: i,
							duration: (seconds / 60).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}),
							status: statusRecord.name,
						});
					}
					i++;
				}
			}
		}

		const grouped = records.reduce((groups, item) => {
			let group = groups.find((i) => i.machineId === item.machineId);
			if (!group) {
				groups = [
					...groups,
					{
						machineId: item.machineId,
						data: [item],
					},
				];
			} else {
				group.data.push(item);
			}
			return groups;
		}, []);

		for (const group of grouped) {
			group.data.sort((a, b) => {
				if (a.machineName < b.machineName) {
					return 1;
				} else if (a.machineName > b.machineName) {
					return -1;
				}
				if (a.hour < b.hour) {
					return -1;
				} else if (a.hour > b.hour) {
					return 1;
				}
				if (a.status < b.status) {
					return -1;
				} else if (a.status > b.status) {
					return 1;
				}
				return 0;
			});
			group.data.forEach(function (v) {
				delete v.hour;
			});
		}

		setPresentationData(grouped);
	}

	useEffect(() => {
		if (reportData.length || isNewFormat) mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const paginationResult = getPaginationResult({ items: presentationData }, pagination.perPage, pagination.page);

	useEffect(() => {
		if (limit < 1000 && paginationResult.items?.length > 0) {
			setMachine(paginationResult.items[0].data[0].machineName);
		} else {
			setMachine("All Machines");
		}
	}, [limit, paginationResult]);

	let allData = [];
	for (const machine of presentationData) {
		allData = allData.concat(machine.data);
	}

	return presentationData ? (
		<>
			{!validTimeFrame && (
				<div className={classes.changeTimeFrame}>
					<ChangeTimeFrame />
				</div>
			)}
			{validTimeFrame && limit === 1000 && (
				<ReportingTable
					columns={columns}
					data={allData}
					header={headerText}
					extraHeader={headerButtons}
					dateOptions={dateRange}
					taller={true}
				/>
			)}
			{validTimeFrame && limit < 1000 && (
				<ReportingTable
					columns={columns}
					data={paginationResult.items[0]?.data ?? []}
					header={headerText}
					extraHeader={headerButtons}
					dateOptions={dateRange}
				/>
			)}
			{validTimeFrame && (
				<SwimLanePagination
					pagination={pagination}
					setPagination={setPagination}
					paginationResult={paginationResult}
					limit={limit}
					key="machine-status-by-hour-table-swim-lane-pagination"
				/>
			)}
		</>
	) : (
		<div></div>
	);
}

export default MachineStatusByHourTable;

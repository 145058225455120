import { readLocalStorage } from "@/api/local-storage";
import machineTypes from "@/constants/machineTypes";
import errorSeverity from "@/constants/errorSeverity";
import { useEffect, useState } from "react";
import { HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr";

const machineType = machineTypes.Em;
const apiUrl = "/emMachineApi/api/v1/machines";
const SignalRContext = createSignalRContext();
const websocketUrl = "/EmMachineApi/hubs/emMachines";
const versionEventName = "OnEmVersion";
const errorChangedEventName = "OnErrorUpdated";

function createError(machineId, code, errorArguments) {
	const parameter = code === 5 ? errorArguments[0] : undefined;

	return {
		machineId,
		machineType,
		code,
		parameter,
		severity: errorSeverity.error,
	};
}

export default function EmErrorsAndWarnings({ machines, onErrorsChanged }) {
	const token = readLocalStorage("BEARER");
	const [machineIds, setMachineIds] = useState([]);
	const [staleData, setStaleData] = useState(false);

	useEffect(() => {
		setMachineIds(
			machines.filter((x) => x.machineType.toLowerCase() === machineType.toLowerCase()).map((x) => x.machineId),
		);
	}, [machines]);

	useEffect(() => {
		if (!staleData && SignalRContext.connection?.state !== HubConnectionState.Connected) {
			const handle = setTimeout(() => setStaleData(true), 5000);
			return () => clearTimeout(handle);
		}

		async function getErrors(abortController) {
			for (const machineId of machineIds) {
				try {
					const response = await fetch(`${apiUrl}/${machineId}`, {
						headers: { Authorization: token },
						signal: abortController.signal,
					});

					if (!response.ok) {
						throw new Error("Did not successfully complete request");
					}

					if (!abortController.signal.aborted && response.status === 204) {
						onErrorsChanged(machineId, []);
						continue;
					}

					const data = await response.json();
					if (!abortController.signal.aborted) {
						const code = data.error.code;
						onErrorsChanged(machineId, code > 0 ? [createError(machineId, code, data.error.arguments)] : []);
					}
				} catch {
					console.error(`Failed to retrieve errors for ${machineType} machine: ${machineId}`);
				}
			}

			if (!abortController.signal.aborted) {
				setStaleData(false);
			}
		}

		const abortController = new AbortController();
		getErrors(abortController);
		return () => abortController.abort();
	}, [machineIds, staleData]);

	SignalRContext.useSignalREffect(versionEventName, (version) => {
		console.info(`Received ${versionEventName} SignalR event with version: ${version}`);
	});

	SignalRContext.useSignalREffect(errorChangedEventName, (_, machineId, error) => {
		if (machineIds.includes(machineId)) {
			console.info(`Processing ${errorChangedEventName} SignalR event for machine: ${machineId}`);
			const code = error.code;
			onErrorsChanged(machineId, code > 0 ? [createError(machineId, code, error.arguments)] : []);
		}
	});

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.WebSockets}
			url={websocketUrl}
			onOpen={() => {
				console.info(`${machineType} websocket opened and is in the ${SignalRContext.connection?.state} state`);
				if (SignalRContext.connection?.state === HubConnectionState.Connected) {
					SignalRContext.connection?.invoke("EmVersion");
					setStaleData(true);
				}
			}}
			onReconnect={() => {
				console.info(`${machineType} websocket reconnected and is in the ${SignalRContext.connection?.state} state`);
				if (SignalRContext.connection.state === HubConnectionState.Connected) {
					SignalRContext.connection?.invoke("EmVersion");
					setStaleData(true);
				}
			}}
			onError={() => {
				console.info(`${machineType} websocket errored`);
				setStaleData(true);
			}}
			onClosed={() => {
				console.info(`${machineType} websocket closed`);
				setStaleData(true);
			}}
		/>
	);
}

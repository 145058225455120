import { Button, Modal } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
	title: {
		fontSize: "20pt !important",
		padding: "28px 28px !important",
		borderBottom: "none !important",
	},
	content: {
		display: "flex !important",
		flexDirection: "column !important",
		gap: "40px",
		padding: "0px 28px 28px 28px !important",
	},
	saveButton: {
		marginRight: "24px !important",
	},
	detail: {
		fontSize: "16pt !important",
		alignItems: "left",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row-reverse",

		"& button": {
			color: theme.colors.primary,
			backgroundColor: "white !important",
		},
	},
	checkboxContainer: {
		flex: 1,
		"& i": {
			marginRight: "4px !important",
		},
	},
}));

export default function MessageModal({ open, onClose, text: messageText }) {
	const classes = useStyles();

	return (
		<Modal
			size="small"
			centered={true}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			className={classes.modal}
			open={open}
		>
			<Modal.Header className={classes.title}>{messageText}</Modal.Header>
			<Modal.Content className={classes.content}>
				<div className={classes.buttons}>
					<Button onClick={() => onClose && onClose(true)}>
						<FormattedMessage id={"OK"} />
					</Button>
				</div>
			</Modal.Content>
		</Modal>
	);
}

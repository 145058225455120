import { Button } from "semantic-ui-react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	iconButton: {
		width: "42px",
		height: "42px",
		padding: "0px 0px !important",
		margin: "0 !important",
		background: "white !important",
		border: "1px solid rgba(34, 36, 38, 0.15) !important",
		"& > *": {
			margin: "0 !important",
		},
		"& i": {
			marginTop: "-7px !important",
			marginLeft: "-2px !important",
		},
	},
});

const IconButton = ({ children, className, ...otherProps }) => {
	const classes = useStyles();
	return (
		<Button className={`${classes.iconButton} ${className ?? ""}`} {...otherProps}>
			{children}
		</Button>
	);
};

export default IconButton;

import { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";
import { createUseStyles } from "react-jss";
import { FormattedMessage } from "react-intl";
import { getDateIndex } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";
import { sortByOrder } from "@/views/reporting/functions/sort";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "row",
		overflow: "scroll",
		maxHeight: "376px",
		marginBottom: "20px",
		width: "calc(97vw - 90px)",
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	headerCellBase: {
		backgroundColor: "#2e475f !important",
		color: "white !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		borderLeft: "1px solid #c4c4c4 !important",
		minWidth: "150px",
		height: "80px",
	},
	headerCell: {
		composes: "$headerCellBase",
		fontWeight: "500 !important",
	},
	headerCellTotal: {
		composes: "$headerCellBase",
		fontWeight: "800 !important",
	},
	cell: {
		composes: "$headerCellBase",
		"& >span:first-child": {
			fontWeight: "800 !important",
		},
		"& >span:last-child": {
			fontWeight: "500 !important",
		},
	},
	contentRow: {
		color: "#353430 !important",
		fontFamily: "Stolzl !important",
		fontSize: "17px !important",
		fontStretch: "normal !important",
		fontStyle: "normal !important",
		lineHeight: "normal !important",
		letterSpacing: "normal !important",
		"& > td": {
			border: "1px solid #c4c4c4 !important",
		},
	},
	evenRow: {
		composes: "$contentRow",
		backgroundColor: "#FFF !important",
	},
	oddRow: {
		composes: "$contentRow",
		backgroundColor: "rgba(0, 0, 50, 0.02) !important",
	},
	totalCell: {
		composes: "$contentRow",
		backgroundColor: "#EAECEE !important",
		fontStyle: "bold !important",
		fontWeight: "800 !important",
	},
	tableHeader: {
		position: "sticky",
		top: 0,
	},
});

function OEEOverTimeTable({ reportData, dateRange, order }) {
	const classes = useStyles();
	const [aggregation, setAggregation] = useState([]);
	const [headers, setHeaders] = useState([]);

	function mapData(data) {
		const dateIndex = getDateIndex(incrementDateRange(dateRange));
		const aggregation = data;

		let key = "";
		switch (dateIndex) {
			case "Hours":
				key = "hour";
				break;
			case "Days":
				key = "day";
				break;
			case "Weeks":
				key = "week";
				break;
			case "Months":
				key = "month";
				break;
			default:
				break;
		}

		setHeaders(aggregation[0].data.map((a) => getDateColumn(key, a)));
		setAggregation(sortByOrder(aggregation, "metric", order));
	}

	function getDateColumn(dateKey, item) {
		switch (dateKey) {
			case "hour": {
				const temp = new Date(item.year, item.month - 1, item.day, item.hour).toLocaleTimeString([], {
					hour: "2-digit",
					minute: "2-digit",
				});
				return temp;
			}
			case "day":
				return new Date(item.year, item.month - 1, item.day).toLocaleDateString();
			case "week":
			case "month":
				return new Date(item.year, item.month - 1, item.day).toLocaleDateString();
			default:
				return "";
		}
	}

	function getRow(oee) {
		const columns = [
			<Table.Cell className={classes.cell} key={`over-time-table-body-${oee.metric}-name`}>
				<FormattedMessage id={oee.metric} />
				<br />
			</Table.Cell>,
		];
		columns.push(
			...oee.data.map((d, i) => {
				return (
					<Table.Cell textAlign="right" key={`over-time-table-body-${oee.metric}-${i}`}>
						{`${(d.score * 100).toLocaleString(undefined, { maximumFractionDigits: 1 })}%`}
					</Table.Cell>
				);
			}),
		);

		return columns;
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let coloredRow = false;
	return aggregation.length ? (
		<div className={classes.container}>
			<Table singleLine={true}>
				<Table.Header className={classes.tableHeader}>
					<Table.Row>
						<Table.HeaderCell className={classes.headerCell} key="over-time-table-header-oee"></Table.HeaderCell>
						{headers.map((h) => {
							return (
								<Table.HeaderCell textAlign="right" className={classes.headerCell} key={`over-time-table-header-${h}`}>
									{h}
								</Table.HeaderCell>
							);
						})}
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{aggregation.map((score, index) => {
						coloredRow = !coloredRow;
						return (
							<Table.Row
								className={coloredRow ? classes.evenRow : classes.oddRow}
								key={`over-time-table-body-row-${index}`}
							>
								{getRow(score)}
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</div>
	) : (
		<div></div>
	);
}

export default OEEOverTimeTable;

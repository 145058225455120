import { useEffect, useState } from "react";
import OfflineIcon from "@/assets/images/machine-status/offline.svg";
import InitializingIcon from "@/assets/images/machine-status/initializing.svg";
import OnlineIcon from "@/assets/images/machine-status/online.svg";
import ErrorIcon from "@/assets/images/machine-status/error.svg";
import PauseIcon from "@/assets/images/machine-status/paused.svg";
import IdleIcon from "@/assets/images/machine-status/idle.svg";

export const machineStatuses = Object.freeze({
	online: "Online",
	offline: "Offline",
	error: "Error",
	serviceMode: "Service Mode",
	initializing: "Initializing",
	idle: "Idle",
	paused: "Paused",
});

const MachineStatus = ({ status, translate, centerVertically, machineStatus, statusText }) => {
	const [statusIcon, setStatusIcon] = useState(OfflineIcon);

	useEffect(() => {
		switch (status) {
			case "Online":
				setStatusIcon(OnlineIcon);
				break;
			case "Offline":
				setStatusIcon(OfflineIcon);
				break;
			case "Error":
				setStatusIcon(ErrorIcon);
				break;
			case "Service Mode":
				setStatusIcon(ErrorIcon);
				break;
			case "Initializing":
				setStatusIcon(InitializingIcon);
				break;
			case "Idle":
				setStatusIcon(IdleIcon);
				break;
			case "Paused":
				setStatusIcon(PauseIcon);
				break;
			default:
				setStatusIcon(OfflineIcon);
				break;
		}
	}, [status]);

	return (
		<div className={`${centerVertically} ${machineStatus}`}>
			<img src={statusIcon} />
			<span className={statusText}>{translate(status)}</span>
		</div>
	);
};

export default MachineStatus;

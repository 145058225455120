import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import Chart from "react-apexcharts";

import CheckboxButton from "@/views/reporting/checkbox-button";
import { getDateIndex, timeLabelFormatter } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";
import { sortByValue } from "@/views/reporting/functions/sort";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "row",
		"& > div:first-child": {
			flexBasis: "70%",
		},
		"& > div:last-child": {
			flexBasis: "30%",
		},
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	sideMenuBody: {
		display: "flex",
		flexDirection: "column",
		gap: "1%",
		height: "343px",
		overflow: "auto",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "white",
		},
	},
	sideMenuHeader: {
		display: "flex",
		flexDirection: "column",
		flexFlow: "column wrap",
	},
	checkbox: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
});

function TrimByMachineOverTime({ reportData, dateRange }) {
	const classes = useStyles();
	const [aggregatedData, setAggregatedData] = useState([]);
	const [sitesWithMachines, setSitesWithMachines] = useState([]);
	const intl = useIntl();

	function toggleMachineSelection(tenantId, machineName) {
		const site = sitesWithMachines.find((s) => s.tenantId === tenantId);
		const machine = site.machines.find((m) => m.machineName === machineName);
		machine.checked = !machine.checked;
		setSitesWithMachines([...sitesWithMachines]);
	}

	function getOptions(allData, sitesSelection) {
		if (!allData || !allData.length) return { series: [] };

		const flatSites = sitesSelection.flatMap((x) => x.machines);

		const presentationData = allData.filter(
			(d) => flatSites.find((s) => s.tenantId === d.tenantId && s.machineName === d.machineName).checked,
		);

		if (!presentationData || !presentationData.length) return { series: [] };
		sortByValue(presentationData, "machineName", "asc");

		const colors = presentationData.map((a) => a.color);
		const series = presentationData.map((a) => ({
			name: a.machineName,
			data: a.data.map((d) => d.percentage.toFixed(2)),
		}));
		const indexDate = getDateIndex(incrementDateRange(dateRange));
		let labelText = indexDate;
		const allDates = presentationData[0].data.map((item) => {
			return timeLabelFormatter(item, indexDate, 0, true);
		});
		const xaxisCategories = presentationData[0].data.map((item, i) => {
			return timeLabelFormatter(item, indexDate, i);
		});

		return {
			chart: {
				toolbar: {
					show: false,
					tools: {
						zoom: false,
					},
				},
				type: "line",
				stacked: false,
			},
			dataLabels: {
				enabled: false,
			},
			colors: colors,
			series: series,
			stroke: {
				curve: "smooth",
				width: [1, 1],
			},
			markers: {
				size: 0,
			},
			plotOptions: {
				bar: {
					columnWidth: "20%",
				},
			},
			xaxis: {
				labels: {
					style: {
						colors: "#7c7b79",
						fontSize: "16px",
						fontFamily: "Stolzl-Book",
					},
				},
				title: {
					text: intl.formatMessage({ id: labelText }),
					style: {
						color: "#353430",
						fontFamily: "Stolzl",
						fontSize: "15px",
						fontWeight: "normal",
					},
					offsetY: 0,
				},
				categories: xaxisCategories,
			},
			yaxis: [
				{
					//vertical left labels
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
						color: "#e3e7e9",
					},
					labels: {
						style: {
							colors: "#7c7b79",
							fontSize: "16px",
							fontFamily: "Stolzl-Book",
						},
						formatter: (value) => {
							return `${value}%`;
						},
					},
				},
			],
			tooltip: {
				show: false,
				x: {
					formatter: (index) => {
						return allDates[index - 1];
					},
				},
			},
			legend: {
				show: false,
			},
			grid: {
				show: true,
				borderColor: "#e3e7e9",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 20,
				},
			},
		};
	}

	function mapData(data) {
		const aggregation = data.overTime.map((item) => {
			const color = Math.floor(Math.random() * 16777215)
				.toString(16)
				.padStart(6, "0");
			return {
				...item,
				color: `#${color}`,
			};
		});

		setAggregatedData(aggregation);

		const aggregationGroupBySites = aggregation.reduce((groups, item) => {
			const group = groups.find((i) => i.tenantId === item.tenantId);
			if (group) {
				group.machines = [
					...group.machines,
					{
						tenantId: item.tenantId,
						machineName: item.machineName,
						checked: true,
						color: item.color,
					},
				];
				return groups;
			}
			return [
				...groups,
				{
					tenantId: item.tenantId,
					siteId: item.siteId,
					siteName: item.siteName,
					machines: [
						{
							tenantId: item.tenantId,
							machineName: item.machineName,
							checked: true,
							color: item.color,
						},
					],
				},
			];
		}, []);
		setSitesWithMachines(
			aggregationGroupBySites.map((item) => {
				return {
					...item,
					machines: sortByValue(item.machines, "machineName", "asc"),
				};
			}),
		);
	}

	function toggleAllMachines() {
		const allChecked = sitesWithMachines.flatMap((s) => s.machines).every((i) => i.checked);
		sitesWithMachines.forEach((s) => s.machines.forEach((m) => (m.checked = !allChecked)));
		setSitesWithMachines([...sitesWithMachines]);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const options = getOptions(aggregatedData, sitesWithMachines);

	const allChecked = sitesWithMachines.every((s) => s.machines.every((m) => m.checked) === true);

	return sitesWithMachines.length ? (
		<div className={classes.container}>
			<Chart options={options} series={options.series} type="line" width="95%" height="353px" />
			<div>
				<div className={classes.sideMenuHeader}>
					<CheckboxButton
						key={`cb-all`}
						color={"black"}
						checked={sitesWithMachines.flatMap((s) => s.machines).every((i) => i.checked)}
						onChange={() => toggleAllMachines()}
					>
						{allChecked ? <FormattedMessage id="Deselect All" /> : <FormattedMessage id="Select All" />}
					</CheckboxButton>
				</div>
				<div className={classes.sideMenuBody}>
					{sitesWithMachines.map((item) => (
						<div>
							{item.machines.map((machine) => (
								<CheckboxButton
									key={`cb-${machine.siteId}-${machine.machineName}`}
									color={machine.color}
									checked={machine.checked}
									className={classes.checkbox}
									onChange={() => toggleMachineSelection(item.tenantId, machine.machineName)}
								>
									{machine.machineName}
								</CheckboxButton>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	) : (
		<div></div>
	);
}

export default TrimByMachineOverTime;

import { createUseStyles, useTheme } from "react-jss";

import CartonsProducedOverTimeGraph from "./over-time-per-machine-graph";
import CartonsProducedOverTimeTable from "./over-time-per-machine-table";

const useStyles = createUseStyles((theme) => ({
	baseCard: {
		display: "flex",
		flexDirection: "column",
		backgroundColor: "white",
		borderRadius: "8px",
		height: "500px",
		paddingBottom: "20px",
	},
}));

function CartonsProducedOverTime({ dateRange, sites, data, showGraph }) {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<div className={classes.card}>
			{showGraph ? (
				<CartonsProducedOverTimeGraph reportData={data} dateRange={dateRange} sites={sites} />
			) : (
				<CartonsProducedOverTimeTable reportData={data} dateRange={dateRange} sites={sites} />
			)}
		</div>
	);
}

export default CartonsProducedOverTime;

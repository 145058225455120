import ZFoldUsageOverTimeGraph from "./z-fold-usage-over-time-graph";
import ZFoldUsageOverTimeTable from "./z-fold-usage-over-time-table";

function ZFoldOverTime({ showGraph, dateRange, reportData, isMetric }) {
	return (
		<>
			{showGraph ? (
				<ZFoldUsageOverTimeGraph reportData={reportData} dateRange={dateRange} />
			) : (
				<ZFoldUsageOverTimeTable reportData={reportData} dateRange={dateRange} isMetric={isMetric} />
			)}
		</>
	);
}

export default ZFoldOverTime;

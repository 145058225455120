import { Button, Grid, Header, Input, Radio, TextArea } from "semantic-ui-react";
import { useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { Fragment } from "react";

const useStyles = createUseStyles({
	main: {
		padding: "20px",
	},
	error: {
		color: "red",
	},
	results: {
		paddingTop: "20px !important",
	},
	instruction: {
		marginTop: "20px !important",
	},
	canvas: {
		height: "350px",
	},
});

const rscWithoutOnTheFly = [
	31000, 8, 1, 1, 31000, 2, 1, 0, 376, 2, 0, 1389, 3, 0, 2452, 4, 0, 3424, 5, 0, 4473, 6, 0, 9216, 31000, 4, 0, 516,
	31000, 1, 0, 0, 0, 4560, 31000, 3, 100, 31000, 1, 0, 0, 0, 477, 31000, 3, 300, 31000, 1, 0, 0, 0, 304, 31000, 3, 0,
	31000, 4, 0, 32, 31000, 7, 1, 300, 2, 300, 3, 300, 4, 300, 5, 300, 31000, 4, 0, 394, 31000, 7, 5, 310, 31000, 4, 0,
	110, 31000, 7, 1, 100, 2, 100, 3, 100, 4, 100, 31000, 4, 0, 511, 31000, 3, 300, 31000, 1, 0, 0, 0, 477, 31000, 3, 100,
	31000, 1, 0, 0, 0, 4560, 31000, 3, 0, 31000, 4, 0, 516, 31000, 1, 0, 0, 0, 304, 31000, 3, 300, 31000, 1, 0, 0, 0,
	9304, 31000, 3, 0, 31000, 8, 1, 0, 31000, 4, 0, 44, 31000, 7, 1, 300, 2, 300, 3, 300, 4, 300, 31000, 4, 0, 504, 31000,
	7, 1, 100, 2, 100, 3, 100, 4, 100, 5, 110, 31000, 4, 0, 1994, 31000, 7, 1, 0, 2, 0, 3, 0, 4, 0, 5, 0, 6, 0, 32000,
];

const rscOnTheFly = [
	31000, 8, 1, 1, 31000, 2, 1, 0, 173, 2, 0, 638, 3, 0, 748, 4, 0, 1575, 5, 0, 1686, 6, 0, 2084, 31000, 9, 0, 0, 876,
	31001, 0, 352, 100, 6, 100, 31001, 0, 352, 100, 2, 100, 4, 100, 31001, 0, 501, 100, 6, 300, 31000, 6, 0, 0, 298, 0,
	2619, 100, 0, 2336, 300, 0, 1803, 100, 0, 868, 300, 31000, 3, 0, 31000, 4, 0, 916, 31000, 3, 300, 31000, 6, 0, 0,
	2158, 0, 678, 100, 0, 1589, 300, 31000, 3, 0, 31000, 4, 0, 916, 31000, 3, 300, 31000, 6, 0, 0, 298, 0, 1779, 100, 0,
	868, 300, 31000, 3, 0, 31000, 4, 0, 916, 31000, 3, 300, 31000, 6, 0, 0, 2158, 0, 678, 100, 0, 1589, 300, 31000, 3, 0,
	31000, 4, 0, 190, 31000, 1, 0, 0, 0, 298, 31000, 3, 300, 31000, 1, 0, 0, 0, 9298, 31000, 3, 0, 31000, 8, 1, 0, 31000,
	9, 0, 0, 2140, 31001, 0, 514, 100, 6, 100, 31000, 7, 1, 0, 2, 0, 3, 0, 4, 0, 5, 0, 6, 0, 32000,
];

const newInstruction = 31000;
const endInstruction = 32000;
const crossHeadMove = 1;
const longHeadMove = 2;
const crossHeadTool = 3;
const feed = 4;
const metaData = 5;
const onTheFlyCrossHeadMove = 6;
const longHeadTool = 7;
const trackActivation = 8;
const onTheFlyLongHeadMove = 9;
const printInstruction = 10;
const onTheFlyNewPositionMarker = 31001;

const splitOnTheFly = (instructions) => {
	const parts = [];
	let part = [];
	instructions.forEach((element) => {
		if (element === onTheFlyNewPositionMarker) {
			if (part.length) parts.push(part);
			part = [];
		} else part.push(element);
	});
	if (part.length) parts.push(part);
	return parts;
};

const parseToMillimeter = (decimeter, hundredsOfMm) => {
	const dm = parseInt(decimeter);
	const hm = parseInt(hundredsOfMm);
	const mm = dm * 100;
	const mm2 = hm / 100;
	return mm + mm2;
};

const toolstateToString = (toolstate) => {
	switch (toolstate) {
		case 0:
			return "Deactivated";
		case 100:
			return "Crease";
		case 110:
			return "Crease With Separation";
		case 300:
			return "Cut";
		case 310:
			return "Cut With Separation";
		case 400:
			return "Perforation";
		case 410:
			return "Perforation With Separation";
		default:
			throw new Error(`Invalid toolstate ${toolstate}`);
	}
};

const parseTrackActivation = (parts) => {
	const [instructionNumber, trackNumber, active] = parts;
	const instruction = {
		heading: `${active ? "Activate" : "Deactivate"} Track '${trackNumber}'`,
		instructionNumber,
		trackNumber,
		active: active === 1 ? true : false,
		raw: parts,
	};
	return instruction;
};

const parseCrossHeadMove = (parts) => {
	const [
		instructionNumber,
		performSimultaneouslyWithFeed,
		dontDisconnectTrack, //TODO: Rename this
		decimeter,
		hundredsOfMm,
	] = parts;
	const position = parseToMillimeter(decimeter, hundredsOfMm);
	const instruction = {
		heading: `Move cross head to position ${position}`,
		instructionNumber,
		performSimultaneouslyWithFeed,
		dontDisconnectTrack: dontDisconnectTrack === 1 ? true : false,
		position,
		raw: parts,
	};
	return instruction;
};

const parseLongHeadMove = (parts) => {
	let position = {};
	const positions = [];
	const [instructionNumber, ...rest] = parts;
	rest.forEach((p, i) => {
		if (i % 3 === 0) {
			position.longHeadNumber = p;
			position.position = parseToMillimeter(rest[i + 1], rest[i + 2]);
			positions.push(position);
			position = {};
		}
	});

	const positionStrings = positions.map((p) => `${p.longHeadNumber} to ${p.position}`);
	const instruction = {
		heading: "Move long heads",
		details: positionStrings.join(", "),
		instructionNumber,
		positions,
		raw: parts,
	};
	return instruction;
};

const parseOnTheFlyLongHeadMove = (parts) => {
	const [
		instructionNumber,
		_, // eslint-disable-line no-unused-vars
		distanceToFeedDecimeter,
		distanceToFeedHundredsOfMm,
		...rest
	] = parts;

	// Mother please forgive me, I am a sinner. The format of the instructions is super painful
	const otfPositions = splitOnTheFly(rest);
	const parsedOtfPositions = otfPositions
		.map((p) => {
			const [decimeterPart, millimeterPart, speed, ...longheads] = p;
			const lhs = [];
			let lh = {};
			longheads.forEach((l, i) => {
				if (i % 2 === 0) {
					lh = {
						longHeadNumber: l,
						toolState: toolstateToString(longheads[i + 1]),
						position: parseToMillimeter(decimeterPart, millimeterPart),
						decimeterDelta: decimeterPart,
						millimeterDelta: millimeterPart,
						speed,
					};
					lhs.push(lh);
				}
			});
			return lhs;
		})
		.flat();
	const positionStrings = parsedOtfPositions.map(
		(p) => `Long head ${p.longHeadNumber} move to ${p.position} ${p.toolState} with speed ${p.speed}`,
	);
	const instruction = {
		heading: "OnTheFly long head instructions:",
		details: positionStrings.join(", "),
		distance: parseToMillimeter(distanceToFeedDecimeter, distanceToFeedHundredsOfMm),
		instructionNumber,
		parsedOtfPositions,
		positions: parsedOtfPositions,
		raw: parts,
	};
	return instruction;
};

const parseOnTheFlyCrossHeadMove = (parts) => {
	const [
		instructionNumber,
		_, // eslint-disable-line no-unused-vars
		finalDecimeter,
		finalMillimeter,
		...rest
	] = parts;
	const movements = [];
	let move = {};
	rest.forEach((r, i) => {
		if (i % 3 === 0) {
			move = {
				decimeterPart: rest[i],
				millimeterPart: rest[i + 1],
				position: parseToMillimeter(rest[i], rest[i + 1]),
				toolState: toolstateToString(rest[i + 2]),
			};
			movements.push(move);
		}
	});

	const instruction = {
		heading: "OnTheFly cross head instruction:",
		details: movements.map((m) => `position ${m.position} state ${m.toolState}`).join(", "),
		instructionNumber,
		finalDecimeter,
		finalMillimeter,
		finalPosition: parseToMillimeter(finalDecimeter, finalMillimeter),
		movements,
		raw: parts,
	};
	return instruction;
};

const parseCrossHeadToolActivation = (parts) => {
	const [instructionNumber, activation] = parts;
	const toolState = toolstateToString(activation);
	const instruction = {
		heading: `Cross head tool activation ${toolState}`,
		instructionNumber,
		activation,
		toolState,
		raw: parts,
	};
	return instruction;
};

const parseFeed = (parts) => {
	const [instructionNumber, decimeter, hundredsOfMm] = parts;
	const length = parseToMillimeter(decimeter, hundredsOfMm);
	const instruction = {
		heading: `Feed ${length}`,
		instructionNumber,
		length,
		raw: parts,
	};
	return instruction;
};

const parseLongHeadTool = (parts) => {
	const [instructionNumber, ...rest] = parts;
	let longHeadState = {};
	const longHeadStates = [];
	rest.forEach((p, i) => {
		if (i % 2 === 0) {
			longHeadState.longHeadNumber = p;
			longHeadState.activation = rest[i + 1];
			longHeadState.toolState = toolstateToString(longHeadState.activation);
			longHeadStates.push(longHeadState);
			longHeadState = {};
		}
	});

	const instruction = {
		heading: "Long Head Tool",
		details: longHeadStates.map((l) => `${l.longHeadNumber} ${l.toolState}`).join(", "),
		instructionNumber,
		longHeadStates,
		raw: parts,
	};
	return instruction;
};

const splitInstructions = (instructions) => {
	const parts = [];
	let part = [];
	instructions.forEach((element) => {
		if (element === newInstruction) {
			if (part.length) parts.push(part);
			part = [];
		} else if (element === endInstruction) {
			if (part.length) parts.push(part);
		} else part.push(element);
	});
	return parts;
};

const parseInstructions = (instructionParts) =>
	instructionParts.map((part) => {
		switch (part[0]) {
			case crossHeadMove:
				return parseCrossHeadMove(part);
			case longHeadMove:
				return parseLongHeadMove(part);
			case trackActivation:
				return parseTrackActivation(part);
			case onTheFlyLongHeadMove:
				return parseOnTheFlyLongHeadMove(part);
			case onTheFlyCrossHeadMove:
				return parseOnTheFlyCrossHeadMove(part);
			case crossHeadTool:
				return parseCrossHeadToolActivation(part);
			case feed:
				return parseFeed(part);
			case longHeadTool:
				return parseLongHeadTool(part);
			case metaData:
			case printInstruction:
			case onTheFlyNewPositionMarker:
				return { heading: part[0] };
			default:
				console.error(`Instruction ${part[0]} not handled`);
				return { error: `Instruction ${part[0]} not handled` };
		}
	});

const Canvas = (props) => {
	const canvasRef = useRef(null);
	const longHeadPositions = {};
	const longHeadTools = {};
	const defaultCrossHeadPosition = 5;
	const defaultFedLength = 35;
	const positionMultiplier = props.scale;
	const lines = [];
	let crossHeadPosition = defaultCrossHeadPosition;
	let crossHeadState = 0;
	let crossHeadColor = "black";
	let fed = 1;

	const toolstateToColor = (toolstate) => {
		switch (toolstate) {
			case 0: // Deactivated
				return "black";
			case 100: // Crease
			case 110: // Crease With Separation
				return "blue";
			case 300: // Cut
			case 310: // Cut With Separation
				return "red";
			case 400: //Perforation
			case 410: //Perforation With Separation
				return "yellow";
			default:
				return "black";
		}
	};

	const activateTrack = (ctx, trackNumber) => {
		ctx.textAlign = "center";
		ctx.fillText(`Track ${trackNumber}`, 100, 10);
	};

	const placeLongHeads = (positions) => {
		positions.forEach((l) => {
			longHeadPositions[l.longHeadNumber] = l.position * positionMultiplier;
		});
	};

	const drawLongHeads = (ctx) => {
		Object.keys(longHeadPositions).forEach((k) => {
			const tool = longHeadTools[k];
			const color = tool ? toolstateToColor(tool.activation) : "black";
			ctx.textAlign = "center";
			ctx.fillStyle = color;
			ctx.fillText(k, longHeadPositions[k], 30);
		});
	};

	const drawFedCorrugate = (ctx, color) => {
		ctx.strokeStyle = color;
		ctx.beginPath();
		ctx.moveTo(0, fed);
		ctx.lineTo(1000, fed);
		ctx.stroke();
	};

	const drawCrossHead = (ctx) => {
		ctx.textAlign = "center";
		ctx.fillStyle = crossHeadColor;
		ctx.fillText("C", crossHeadPosition, 20);
	};

	const moveCrossHead = (instruction) => {
		const originalPosition = crossHeadPosition;
		crossHeadPosition = defaultCrossHeadPosition + instruction.position * positionMultiplier;
		if (crossHeadState === 0) return;
		lines.push({
			type: "ch",
			start: { x: originalPosition, y: defaultFedLength },
			end: { x: crossHeadPosition, y: defaultFedLength },
			activation: crossHeadState,
		});
	};

	const drawLines = (ctx, lines) => {
		lines.forEach((l) => {
			const color = toolstateToColor(l.activation);
			ctx.beginPath();
			ctx.strokeStyle = color;
			ctx.moveTo(l.start.x, l.start.y);
			ctx.lineTo(l.end.x, l.end.y);
			ctx.stroke();
		});
	};

	const draw = (ctx, instructions) => {
		instructions.forEach((i) => {
			if (i.instructionNumber === trackActivation) activateTrack(ctx, i.trackNumber);
			else if (i.instructionNumber === longHeadMove) placeLongHeads(i.positions);
			else if (i.instructionNumber === feed) {
				const originalPosition = fed;
				fed = fed + i.length * positionMultiplier;
				lines
					//.filter((l) => l.type === "ch")
					.forEach((l) => {
						l.start.y = l.start.y + i.length * positionMultiplier;
						l.end.y = l.end.y + i.length * positionMultiplier;
					});

				const handledLongHeadStates = ["Crease", "Cut", "Cut With Separation", "Crease With Separation"];
				Object.keys(longHeadTools).forEach((s) => {
					const tool = longHeadTools[s];

					if (handledLongHeadStates.includes(tool.toolState)) {
						const lhPos = longHeadPositions[s];
						const line = {
							type: "lh",
							start: { x: lhPos, y: originalPosition },
							end: { x: lhPos, y: fed },
							activation: tool.activation,
						};
						lines.push(line);
					} else console.error(`tool state ${tool.toolState} not handled for longhead ${tool.longHeadNumber}`);
				});
			} else if (i.instructionNumber === crossHeadTool) {
				crossHeadColor = toolstateToColor(i.activation);
				crossHeadState = i.activation;
			} else if (i.instructionNumber === crossHeadMove) {
				moveCrossHead(i);
			} else if (i.instructionNumber === longHeadTool) {
				i.longHeadStates.forEach((l) => {
					longHeadTools[l.longHeadNumber] = l;
				});
			}

			//else if (i.instructionNumber == onTheFlyLongHeadMove)
			//  moveLongHeads(ctx, i.)
			else console.error("Not handled", i);
		});

		drawCrossHead(ctx);
		drawLongHeads(ctx);
		drawFedCorrugate(ctx, "brown");
	};

	useEffect(() => {
		const canvas = canvasRef.current;
		const context = canvas.getContext("2d");
		const instructions = props.step ? props.instructions.slice(0, props.step) : props.instructions;

		context.clearRect(0, 0, canvas.width, canvas.height);

		//draw machine
		drawFedCorrugate(context, "black");
		fed = defaultFedLength;
		drawFedCorrugate(context, "black");

		draw(context, instructions);
		drawLines(context, lines);
	}, [props.instructions, props.step, props.scale]);

	return <canvas ref={canvasRef} {...props} />;
};

const EmInstructionList = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [errors, setErrors] = useState({});
	const [step, setStep] = useState(0);
	const [instructionsArray, setInstructionsArray] = useState(rscOnTheFly);
	//const [instructionsArray, setInstructionsArray] =
	useState(rscWithoutOnTheFly);
	const [interpreted, setInterpreted] = useState([]);
	const [scale, setScale] = useState(1);

	useEffect(() => {
		const instructionParts = splitInstructions(instructionsArray);
		const interpreted = parseInstructions(instructionParts);
		setStep(interpreted.length - 1);
		setInterpreted(interpreted);
	}, [instructionsArray]);

	return (
		<div className={classes.main}>
			<Grid padded columns={2} stretched>
				<Grid.Row>
					<Grid.Column width={3}>Paste Instruction List:</Grid.Column>
					<Grid.Column width={13}>
						<TextArea
							rows={10}
							value={JSON.stringify(instructionsArray, null, 2)}
							onChange={(_, { value }) => {
								try {
									if (value.length === 0) {
										setInstructionsArray([]);
										setErrors({});
										return;
									}

									const json = JSON.parse(value);
									if (!Array.isArray(json)) {
										setErrors({ "Invalid Array": true });
										return;
									}
									setInstructionsArray(value);
								} catch {
									setErrors({ "Invalid Array": true });
								}
							}}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className={classes.results}>
					<Grid.Column width={8}>
						<div>Results:</div>
						{Object.keys(errors).length > 0 && (
							<div className={classes.error}>
								{Object.keys(errors).map((e, i) => (
									<div key={i}>{e}</div>
								))}
							</div>
						)}
						{interpreted.map((i, index) => (
							<Fragment>
								<Header key={index} className={classes.instruction}>
									<Radio
										fitted
										name="step"
										value={index}
										checked={step === index + 1}
										onChange={(_, { value }) => setStep(value + 1)}
									/>
									{i.heading}
									<Header.Subheader>{i.details}</Header.Subheader>
								</Header>
							</Fragment>
						))}
					</Grid.Column>
					<Grid.Column width={8} className={classes.canvas}>
						{interpreted.length && (
							<Fragment>
								<Header>
									Current Instruction: {interpreted[step - 1].heading}
									<Header.Subheader>{interpreted[step - 1].details}</Header.Subheader>
								</Header>
								<div>
									<Input
										label="Image Scale"
										value={scale}
										onChange={(_, { value }) => {
											const newValue = parseFloat(value);
											if (newValue) setScale(value);
										}}
									></Input>
									<Button
										size="mini"
										primary
										content=">>"
										floated="right"
										disabled={step === interpreted.length - 1}
										onClick={() => {
											setStep(interpreted.length - 1);
										}}
									/>
									<Button
										size="mini"
										primary
										content=">"
										floated="right"
										disabled={step === interpreted.length - 1}
										onClick={() => {
											setStep(step + 1);
										}}
									/>
									<Button
										size="mini"
										primary
										content="<"
										floated="right"
										disabled={step === 1}
										onClick={() => {
											setStep(step - 1);
										}}
									/>
									<Button
										size="mini"
										primary
										content="<<"
										floated="right"
										disabled={step === 1}
										onClick={() => {
											setStep(1);
										}}
									/>
								</div>
							</Fragment>
						)}
						<Canvas instructions={interpreted} step={step} scale={scale} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default EmInstructionList;

export const getSolutionTemplate = (tenantId) => ({
	id: "00000000-0000-0000-0000-000000000000",
	tenantId: tenantId,
	internalUniqueId: "00000000-0000-0000-0000-000000000000",
	batchId: null,
	alias: "",
	licensePlateNumber: "",
	cartons: [],
	bags: [],
	labels: [],
	fillers: [],
	documents: [],
	importDate: "0001-01-01T00:00:00+00:00",
	createByDate: "0001-01-01T00:00:00+00:00",
	releasedForProductionDate: "0001-01-01T00:00:00+00:00",
	priority: 0,
	cartonPropertyGroups: [],
	classification: null,
	estimatedTotalCost: 0.0,
	estimatedTimeToPack: "00:00:00",
	attributes: { quantity: 1 },
});

export const getCartonTemplate = () => ({
	id: crypto.randomUUID(),
	media: {
		dimensions: {
			length: 0,
			width: 0,
			height: 0,
		},
		style: "",
		styleAlias: "",
		brand: "",
		thickness: undefined,
		attributes: {
			name: "",
		},
	},
	rotation: null,
	prints: [],
	labels: [],
	errors: [],
});

export const getPrintTemplate = () => ({
	id: crypto.randomUUID(),
	placement: {}, //x/y coords
	media: {
		dimensions: {},
	},
	most: 0,
	value: "", //Image name
	timeToPack: "",
	attributes: {
		name: "",
		ColorWiseOff: "False",
		"@@NoValidatePrintImage": "False",
	},
	errors: [],
});

export const getLabelTemplate = () => ({
	id: crypto.randomUUID(),
	specialCharsError: false,
	placement: {
		x: 0,
		y: 0,
	},
	media: {
		brand: undefined,
		style: undefined,
		dimensions: {
			width: undefined,
			length: undefined,
		},
	},
	attributes: {
		name: "",
		cartonId: "",
	},
	errors: [],
});

export const getDefaultDisplayAlias = (intl, itemType) =>
	intl.formatMessage({ id: `untitled_${itemType.toLowerCase()}` });

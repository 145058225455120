import PropTypes from "prop-types";
import { Link } from "react-router";
import { List } from "semantic-ui-react";
import { createUseStyles, useTheme } from "react-jss";

import ProductId from "@/views/dimensioning/products/product-id";
import { ReactComponent as BarcodeIcon } from "@/assets/images/icon-barcode.svg";

const useStyles = createUseStyles((theme) => ({
	icon: {
		height: 16,
		width: 24,
		marginRight: "1em !important",
	},
	listItem: {
		display: "flex",
		alignItems: "center",
	},
	title: {
		color: `${theme.colors.text}`,
		fontFamily: "Stolzl-Book",
	},
}));

const SearchResultRow = ({ product, searchTerm, renderRow }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	if (renderRow) {
		return renderRow();
	}

	return (
		<List.Item as={Link} to={`/cart/products/${product.identifiers.upc}`}>
			<div className={classes.listItem}>
				<BarcodeIcon className={classes.icon} />
				<span className={classes.title}>
					<ProductId text={product.identifiers.upc} searchText={searchTerm} />
				</span>
			</div>
		</List.Item>
	);
};

SearchResultRow.propTypes = {
	product: PropTypes.shape({
		tenantId: PropTypes.string.isRequired,
		identifiers: PropTypes.shape({
			upc: PropTypes.string,
		}),
		customAttributes: PropTypes.object,
		name: PropTypes.string,
		quantity: PropTypes.number,
		dimensions: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
			length: PropTypes.number,
		}).isRequired,
		weight: PropTypes.number,
	}),
	voidFill: PropTypes.shape({
		height: PropTypes.number,
		width: PropTypes.number,
		length: PropTypes.number,
	}),
	searchTerm: PropTypes.string,
	renderRow: PropTypes.func,
};

export { SearchResultRow as default };

import { createUseStyles } from "react-jss";

import { Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog.listView,
	failedRetry: {
		fontSize: "16px !important",
		height: "200px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	reload: {
		paddingLeft: "24px !important",
		paddingRight: "24px !important",
		margin: "0 !important",
		marginTop: "32px !important",
	},
}));

const FailedRetry = (props) => {
	const classes = useStyles();

	return (
		<div data-testid="failed-retry" className={classes.failedRetry}>
			<div>
				<FormattedMessage id="We had a problem loading your information" />
			</div>
			<div>
				<Button primary className={classes.reload} onClick={() => (props.retry ? props.retry() : 1)}>
					<FormattedMessage id="Reload" />
				</Button>
			</div>
		</div>
	);
};

export default FailedRetry;

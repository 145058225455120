import { Button, Modal } from "semantic-ui-react";

import { FormattedMessage } from "react-intl";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
	title: {
		fontSize: "32pt !important",
		padding: "28px 28px !important",
		borderBottom: "none !important",
	},
	content: {
		display: "flex !important",
		flexDirection: "column !important",
		gap: "40px",
		padding: "0px 28px 28px 28px !important",
	},
	saveButton: {
		marginRight: "24px !important",
	},
	detail: {
		fontSize: "16pt !important",
		alignItems: "left",
	},
	buttons: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row-reverse",
	},
	checkboxContainer: {
		flex: 1,
		"& i": {
			marginRight: "4px !important",
		},
	},
});

export default function ConfirmModal({
	open,
	onClose,
	titleText,
	detailText,
	confirmText,
	cancelText,
	size,
	formatMessages = true,
}) {
	const classes = useStyles();
	if (!size) size = "small";

	return (
		<Modal
			size={size}
			centered={true}
			closeOnEscape={false}
			closeOnDimmerClick={false}
			className={classes.modal}
			open={open}
		>
			<Modal.Header className={classes.title}>{titleText}</Modal.Header>
			<Modal.Content className={classes.content}>
				<div className={classes.detail}>{formatMessages ? <FormattedMessage id={detailText} /> : detailText}</div>
				<div className={classes.buttons}>
					<Button primary onClick={() => onClose && onClose(true)} data-cy="confirm-modal-confirm-button">
						{formatMessages ? <FormattedMessage id={confirmText} /> : confirmText}
					</Button>
					<Button onClick={() => onClose && onClose(false)} data-cy="confirm-modal-close-button">
						{formatMessages ? <FormattedMessage id={cancelText} /> : cancelText}
					</Button>
				</div>
			</Modal.Content>
		</Modal>
	);
}

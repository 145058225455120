import { Fragment } from "react";
import { createUseStyles, useTheme } from "react-jss";

import { ReactComponent as Error } from "@/assets/images/error.svg";
import { Input } from "semantic-ui-react";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	prompt: {
		paddingBottom: "8px",
		size: "16px",
	},
	mainInput: {
		width: "400px !important",
	},
}));

const AlphaNumeric = ({
	type,
	name,
	prompt,
	example,
	defaultPrompt,
	callback,
	error,
	value,
	disabled,
	maxLength,
	isEdit,
	inputRefs = null,
	activeStep = 0,
}) => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	let finalLabel = example?.length ? example : defaultPrompt;
	let finalName = prompt?.length ? prompt : defaultPrompt;
	let refs = null;
	if (maxLength) {
		finalLabel = finalLabel + ` (max character limit ${maxLength})`;
	}
	if (isEdit) {
		finalName = name;
	}
	if (inputRefs) {
		refs = inputRefs;
	}

	return (
		<Fragment>
			<div className={classes.prompt}>{finalName}</div>
			<Input
				data-testid="distoOffset"
				icon
				className={classes.mainInput}
				type={type ? type : "string"}
				placeholder={finalLabel}
				value={value}
				onChange={(e, { value }) => {
					if (callback) {
						callback(value);
					}
				}}
				error={!!error}
				disabled={disabled}
				ref={(ref) => {
					if (refs) refs.current[activeStep] = ref;
				}}
			>
				<input />
				{error && (
					<i className="icon">
						<Error className={classes.inputError} />
					</i>
				)}
			</Input>
		</Fragment>
	);
};

export default AlphaNumeric;

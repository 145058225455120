import { Button, Menu, MenuItem, Segment } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import ServerSettingsTab from "./server-settings-tab";
import ApiSettingsTab from "./api-settings-tab";
import FailedRetry from "@/components/failed-retry";
import Loading from "@/components/loading";
import AddApiKey from "./modals/add-api-key";
import ListContext from "../../../components/list-context";
import { UserContext } from "../../../components/user-context";
import errorBoundary from "../../../components/error-boundary";

const useStyles = createUseStyles((theme) => ({
	...theme.configurationDialog,
	fullHeight: {
		height: "calc(100vh - 225px)",
	},
	header: {
		border: "0 !important",
		alignItems: "center",
		padding: "24px 32px !important",
		margin: "0 !important",
		backgroundColor: `${theme.colors.light} !important`,
		borderRadius: "0px !important",
		minHeight: "96px",
		display: "flex",
		justifyContent: "space-between",
		boxShadow: "none !important",
	},
	headerText: {
		color: `${theme.colors.text} !important`,
		fontWeight: "normal !important",
		fontSize: "40px !important",
		margin: "0 !important",
		lineHeight: "36px !important",
		letterSpacing: "-.5px",
		display: "flex",
		rowGap: "3rem",
	},
	form: {
		border: "unset !important",
		padding: "75px 170px !important",
	},
	readonly: {
		opacity: "1 !important",
		backgroundColor: "#f2f4f5",
		color: "#7c7b79",
	},
	connectionStatus: {
		marginRight: "10px !important",
		marginBottom: "10px !important",
	},
	licensingServer: {
		marginTop: "16px",
	},
	headerTabs: {
		marginTop: "0 !important",
		marginLeft: "2rem !important",
	},
	saveContainer: {
		display: "flex",
		justifyContent: "flex-end",
		justifySelf: "end",
		width: "100% !important",
	},
}));

const ServerSettings = () => {
	let { currentUser } = useContext(UserContext);
	const theme = useTheme();
	const classes = useStyles({ theme });
	const [isDirty, setIsDirty] = useState(false);
	const [tab, setTab] = useState("settings");
	const [displayState, setDisplayState] = useState("loading");
	const serverSettingsRef = useRef();
	const apiKeysRef = useRef();
	const [list, setList] = useState([]);
	const [edit, setEdit] = useState({});
	const [loading, setLoading] = useState(true);
	const [failed, setFailed] = useState(false);
	const contextValue = {
		list,
		setList,
		edit,
		setEdit,
		loading,
		setLoading,
		failed,
		setFailed,
	};
	const load = () => {
		setDisplayState("loading");
		setDisplayState("tabs");
	};

	useEffect(load, []);

	const tabsComponent =
		tab === "settings" ? (
			<ServerSettingsTab ref={serverSettingsRef} setIsDirty={setIsDirty} />
		) : (
			<ApiSettingsTab ref={apiKeysRef} classname={classes.fullHeight} />
		);
	const handleChange = (e, { id }) => {
		setTab(id);
	};

	return (
		<div className={classes.fullHeight}>
			<ListContext.Provider value={contextValue}>
				<Segment clearing className={classes.header}>
					<div className={classes.headerText} data-cy="settings-title">
						<div>
							<FormattedMessage className={classes.titleText} id="Server Settings" />
						</div>
						{currentUser["ff-authorization-allow-apim"] === "True" && (
							<Menu secondary className={classes.headerTabs}>
								<MenuItem id="settings" name="General" active={tab === "settings"} onClick={handleChange} />
								<MenuItem id="api" name={"API"} active={tab === "api"} onClick={handleChange} />
							</Menu>
						)}
					</div>
					<div className={classes.saveContainer}>
						<div className={classes.saveButton} data-cy="save-settings">
							{tab === "settings" && (
								<Button primary disabled={!isDirty} onClick={async () => await serverSettingsRef.current.save()}>
									<FormattedMessage id="Save" />
								</Button>
							)}
							{tab === "api" && (
								<div>
									<AddApiKey />
								</div>
							)}
						</div>
					</div>
				</Segment>
				{displayState === "loading" && <Loading />}
				{displayState === "failed" && <FailedRetry />}
				{displayState === "tabs" && tabsComponent}
			</ListContext.Provider>
		</div>
	);
};

export default errorBoundary(ServerSettings);

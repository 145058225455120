import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button, Message } from "semantic-ui-react";
import HmiIcon from "@/assets/images/machine-type/X4HMI.svg";
import OfflineIcon from "@/assets/images/change-corrugate-status/ccOffline.svg";
import PauseIcon from "@/assets/images/change-corrugate-status/ccPaused.svg";
import ProceedIcon from "@/assets/images/change-corrugate-status/ccProceed.svg";
import { readLocalStorage } from "@/api/local-storage";
import useAxios from "@/api/useAxios";

const ChangeCorrugateStatus = ({ classes, status, translate, tenantId, machineId, hasBeenSaved, trackErrors }) => {
	const [statusInfo, setStatusInfo] = useState({
		statusIcon: OfflineIcon,
		statusText: "Put machine in ErrorPaused to change z-Fold",
		statusHeader: "Machine Offline",
	});
	const [errors, setErrors] = useState([]);
	const token = readLocalStorage("BEARER");
	const x4Api = useAxios("/x4api/api/v1.0", token);

	const onPauseClick = async () => {
		x4Api.addWithUrl(
			`tenants/${tenantId}/machines/${machineId}/stateChange/3`,
			null,
			(data) => {},
			(err) => {
				console.error(err);
			},
		);
	};

	useEffect(() => {
		switch (status) {
			case "Online":
			case "Initializing":
			case "Idle":
				setStatusInfo({
					statusIcon: PauseIcon,
					statusHeader: "Pause Machine",
					statusText: "To change z-Fold pause the machine",
				});
				break;
			case "Error":
				setStatusInfo({
					statusIcon: ProceedIcon,
					statusHeader: "Proceed to change z-Fold",
					statusText: "Select drop downs to change z-Fold",
				});
				break;
			case "Paused":
				if (hasBeenSaved) {
					setStatusInfo({
						statusIcon: HmiIcon,
						statusHeader: "Press Play on the Machine",
						statusText: "Press play on the machine to continue production",
					});
				} else {
					setStatusInfo({
						statusIcon: ProceedIcon,
						statusHeader: "Proceed to change z-Fold",
						statusText: "Select drop downs to change z-Fold",
					});
				}
				break;
			case "Service":
				setStatusInfo({
					statusIcon: OfflineIcon,
					statusHeader: "Service Mode",
					statusText: "Place machine back in Production Mode to change corrugate",
				});
				break;
			case "Offline":
			default:
				setStatusInfo({
					statusIcon: OfflineIcon,
					statusHeader: "Machine Offline",
					statusText: "Put machine in Error or Paused to change z-Fold",
				});
				break;
		}
	}, [status, hasBeenSaved]);

	useEffect(() => {
		let temperrors = [];
		if (trackErrors.corrugateTrackErrors.length > 0) temperrors.push("Please select a valid corrugate for track(s)");
		else {
			if (trackErrors.casset1) temperrors.push("Tracks 1 & 2 exceed maximum width for machine");
			if (trackErrors.casset2) temperrors.push("Tracks 3 & 4 exceed maximum width for machine");
		}
		if (trackErrors.duplicate) temperrors.push("Cannot have the same z-Fold loaded on more than one track");

		setErrors(temperrors);
	}, [trackErrors]);

	return (
		<div className={classes.pausePrompt}>
			<div>
				<img src={statusInfo.statusIcon} />
			</div>
			<div className={classes.statusHeader}>
				<span>{translate(statusInfo.statusHeader)}</span>
			</div>
			<div className={classes.statusText}>
				<span>{translate(statusInfo.statusText)}</span>
			</div>
			<div>
				{status === ("Online" || "Idle") ? (
					<Button className={classes.pauseButton} primary={true} onClick={onPauseClick}>
						<span>{translate("Pause Machine")}</span>
					</Button>
				) : (
					<></>
				)}
			</div>
			<Message error={errors.length > 0} hidden={errors.length === 0} className={classes.errorBox}>
				<Message.List>
					{errors.map((err, errIndex) => {
						return (
							<Message.Item key={errIndex}>
								<FormattedMessage id={err} />
							</Message.Item>
						);
					})}
				</Message.List>
			</Message>
		</div>
	);
};

export default ChangeCorrugateStatus;

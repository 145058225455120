import { Link } from "react-router";
import { createUseStyles, useTheme } from "react-jss";
import { Button, Menu } from "semantic-ui-react";

import { ReactComponent as Close } from "@/assets/images/close.svg";

const useStyles = createUseStyles((theme) => ({
	container: {
		height: "65px !important",
		borderRadius: "0 !important",
		backgroundColor: `${theme.colors.white} !important`,
	},
	fullWidth: {
		flex: "1 !important",
	},
	menuItem: {
		backgroundColor: "#fff !important",
		"&:before": {
			width: "0 !important",
		},
	},
	closeIcon: {
		height: 21,
		width: 21,
	},
}));

const ProductNav = ({ product }) => {
	const theme = useTheme();
	const classes = useStyles({ theme });

	return (
		<Menu fixed="top" className={classes.container}>
			<Menu.Item className={classes.menuItem} as={Link} to="/cart/scan">
				<Close className={classes.closeIcon} />
			</Menu.Item>
			<Menu.Menu className={classes.fullWidth}>
				<Menu.Item className={classes.menuItem}>
					<p className={classes.text}>{product.id}</p>
				</Menu.Item>
				<Menu.Item position="right" className={classes.menuItem}>
					<Button>SAVE</Button>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
};

export { ProductNav as default };

import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import Chart from "react-apexcharts";

import CheckboxButton from "@/views/reporting/checkbox-button";
import { getNumericAbbreviation } from "../../functions/swimlanes";
import { getDateIndex, timeLabelFormatter } from "@/views/reporting/functions/over-time";
import { incrementDateRange } from "@/views/reporting/functions";
import { sortByValue } from "@/views/reporting/functions/sort";

const useStyles = createUseStyles({
	container: {
		display: "flex",
		flexDirection: "row",
		"& > div:first-child": {
			flexBasis: "70%",
		},
		"& > div:last-child": {
			flexBasis: "30%",
		},
	},
	errorContainer: {
		display: "flex",
		marginBottom: "32px",
	},
	header: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		marginBottom: "20px",
		color: "black",
	},
	sideMenuBody: {
		display: "flex",
		flexDirection: "column",
		gap: "1%",
		height: "343px",
		overflow: "auto",
		"&::-webkit-scrollbar-track": {
			backgroundColor: "white",
		},
	},
	sideMenuHeader: {
		display: "flex",
		flexDirection: "column",
		flexFlow: "column wrap",
	},
	checkbox: {
		fontFamily: "Stolzl",
		fontSize: "15px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
	},
});

function ZFoldUsageOverTimeGraph({ reportData, dateRange }) {
	const classes = useStyles();
	const [aggregatedData, setAggregatedData] = useState([]);
	const [siteBrandTypes, setSiteBrandTypes] = useState([]);
	const intl = useIntl();

	function toggleMachineSelection(tenantId, corrugateAlias) {
		const site = siteBrandTypes.find((s) => s.tenantId === tenantId);
		const corrugate = site.corrugates.find((c) => c.corrugateAlias === corrugateAlias);
		corrugate.checked = !corrugate.checked;
		setSiteBrandTypes([...siteBrandTypes]);
	}

	function getOptions(allData, sitesSelection) {
		if (!allData || !allData.length) return { series: [] };

		const flatCorrugates = sitesSelection.flatMap((x) => x.corrugates);
		const presentationData = allData.filter(
			(d) => flatCorrugates.find((s) => s.tenantId === d.tenantId && s.corrugateAlias === d.corrugateAlias).checked,
		);

		if (!presentationData || !presentationData.length) return { series: [] };

		const colors = presentationData.map((a) => a.color);
		const series = presentationData.map((a) => ({
			name: a.corrugateAlias,
			data: a.data.map((d) => d.totalConsumedCorrugate),
		}));
		const sumOfSelected = flatCorrugates.find(
			(s) => s.corrugateAlias === intl.formatMessage({ id: "Sum of Selected" }),
		);
		const numberOfSelected = flatCorrugates.filter(
			(c) => c.checked && c.corrugateAlias !== intl.formatMessage({ id: "Sum of Selected" }),
		).length;
		if ((!sumOfSelected || sumOfSelected.checked) && numberOfSelected > 1) {
			colors.push("#000");
			const data = [];
			for (let i = 0; i < series[0].data.length; i++) {
				data.push(0);
			}
			for (let i = 0; i < series.length; i++) {
				const s = series[i];
				for (let j = 0; j < s.data.length; j++) {
					const value = s.data[j];
					data[j] += value;
				}
			}
			series.push({ name: "Sum of Selected", data: data });
		}

		const indexDate = getDateIndex(incrementDateRange(dateRange));
		let labelText = indexDate;

		const allDates = presentationData[0].data.map((item) => {
			return timeLabelFormatter(item, indexDate, 0, true);
		});
		const xaxisCategories = presentationData[0].data.map((item, i) => {
			return timeLabelFormatter(item, indexDate, i);
		});

		return {
			chart: {
				toolbar: {
					show: false,
					tools: {
						zoom: false,
					},
				},
				type: "line",
				stacked: false,
			},
			dataLabels: {
				enabled: false,
			},
			colors: colors,
			series: series,
			stroke: {
				curve: "smooth",
				width: [1, 1],
			},
			markers: {
				size: 0,
			},
			plotOptions: {
				bar: {
					columnWidth: "20%",
				},
			},
			xaxis: {
				labels: {
					style: {
						colors: "#7c7b79",
						fontSize: "16px",
						fontFamily: "Stolzl-Book",
					},
				},
				title: {
					text: intl.formatMessage({ id: labelText }),
					style: {
						color: "#353430",
						fontFamily: "Stolzl",
						fontSize: "15px",
						fontWeight: "normal",
					},
					offsetY: 0,
				},
				categories: xaxisCategories,
			},
			yaxis: [
				{
					axisTicks: {
						show: false,
					},
					axisBorder: {
						show: false,
						color: "#e3e7e9",
					},
					labels: {
						style: {
							colors: "#7c7b79",
							fontSize: "16px",
							fontFamily: "Stolzl-Book",
							margin: "10px",
						},
						formatter: (value) => {
							return getNumericAbbreviation(value, true);
						},
						offsetX: 32,
					},
					title: {
						text: intl.formatMessage({ id: "z-Fold Used" }),
						style: {
							color: "#353430",
							fontFamily: "Stolzl",
							fontSize: "15px",
							fontWeight: "normal",
						},
						offsetX: -20,
					},
				},
			],
			tooltip: {
				show: false,
				x: {
					formatter: (index) => {
						return allDates[index - 1];
					},
				},
			},
			legend: {
				show: false,
			},
			grid: {
				show: true,
				borderColor: "#e3e7e9",
				strokeDashArray: 0,
				position: "back",
				xaxis: {
					lines: {
						show: false,
					},
				},
				yaxis: {
					lines: {
						show: true,
					},
				},
				row: {
					colors: undefined,
					opacity: 0.5,
				},
				column: {
					colors: undefined,
					opacity: 0.5,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 52,
				},
			},
		};
	}

	function toggleAllCorrugates() {
		const allChecked = siteBrandTypes.flatMap((s) => s.corrugates).every((i) => i.checked);
		siteBrandTypes.forEach((s) => s.corrugates.forEach((m) => (m.checked = !allChecked)));
		setSiteBrandTypes([...siteBrandTypes]);
	}

	function mapData(data) {
		let aggregation = data.overTime.map((item) => {
			const color = Math.floor(Math.random() * 16777215)
				.toString(16)
				.padStart(6, "0");
			return {
				...item,
				color: `#${color}`,
			};
		});

		setAggregatedData(aggregation);

		const aggregationGroupBySites = aggregation.reduce((groups, item) => {
			const group = groups.find((i) => i.tenantId === item.tenantId);
			if (group) {
				group.corrugates = [
					...group.corrugates,
					{
						tenantId: item.tenantId,
						corrugateAlias: item.corrugateAlias,
						checked: true,
						color: item.color,
					},
				];
				return groups;
			}
			return [
				...groups,
				{
					tenantId: item.tenantId,
					corrugates: [
						{
							tenantId: item.tenantId,
							corrugateAlias: item.corrugateAlias,
							checked: true,
							color: item.color,
						},
					],
				},
			];
		}, []);
		const checkboxValues = aggregationGroupBySites.map((item) => {
			return {
				...item,
				corrugates: sortByValue(item.corrugates, "corrugateAlias", "asc"),
			};
		});
		if (checkboxValues.length && checkboxValues[0].corrugates.length > 1) {
			checkboxValues[0].corrugates.push({
				tenantId: checkboxValues[0].corrugates[0].tenantId,
				corrugateAlias: intl.formatMessage({ id: "Sum of Selected" }),
				checked: true,
				color: "#000",
			});
		}

		setSiteBrandTypes(checkboxValues);
	}

	useEffect(() => {
		mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	const options = getOptions(aggregatedData, siteBrandTypes);

	const allChecked = siteBrandTypes.every((s) => s.corrugates.every((m) => m.checked) === true);

	return siteBrandTypes.length ? (
		<div className={classes.container}>
			<Chart options={options} series={options.series} type="line" width="95%" height="353px" />
			<div>
				<div className={classes.sideMenuHeader}>
					{siteBrandTypes[0].corrugates.length > 1 && (
						<CheckboxButton
							key={`cb-all`}
							color={"black"}
							checked={siteBrandTypes.flatMap((s) => s.corrugates).every((i) => i.checked)}
							onChange={() => toggleAllCorrugates()}
						>
							{allChecked ? <FormattedMessage id="Deselect All" /> : <FormattedMessage id="Select All" />}
						</CheckboxButton>
					)}
				</div>
				<div className={classes.sideMenuBody}>
					{siteBrandTypes.map((item, i) => {
						const sumOfSelected = item.corrugates.filter(
							(s) => s.corrugateAlias === intl.formatMessage({ id: "Sum of Selected" }),
						)[0];
						const corrugates = item.corrugates.filter(
							(s) => s.corrugateAlias !== intl.formatMessage({ id: "Sum of Selected" }),
						);
						const numberOfSelected = corrugates.filter((c) => c.checked).length;
						if (numberOfSelected > 1) {
							corrugates.push(sumOfSelected);
						}
						return (
							<div className={classes.siteGroup} key={`z-fold-usage-over-time-graph-site-group-${i}`}>
								<div className={classes.header}>{item.siteName}</div>
								<div>
									{corrugates.map((corrugate) => (
										<CheckboxButton
											key={`cb-${corrugate.siteId}-${corrugate.corrugateAlias}`}
											color={corrugate.color}
											checked={corrugate.checked}
											className={classes.checkbox}
											onChange={() => toggleMachineSelection(item.tenantId, corrugate.corrugateAlias)}
										>
											{corrugate.corrugateAlias}
										</CheckboxButton>
									))}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	) : (
		<div className={classes.errorContainer}>
			<FormattedMessage id="No z-Folds Have Been Used" />
		</div>
	);
}

export default ZFoldUsageOverTimeGraph;

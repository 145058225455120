import { useState } from "react";
import { useIntl } from "react-intl";
import { createUseStyles } from "react-jss";
import { Button, Popup } from "semantic-ui-react";

import MachineStatusByHourGraph from "./machine-status-by-hour-graph";
import MachineStatusByHourTable from "./machine-status-by-hour-table";
import { ReactComponent as ChartViewIcon } from "@/views/reporting/img/chartview-icon.svg";
import { ReactComponent as TableViewIcon } from "@/views/reporting/img/tableview-icon.svg";
import NoData from "@/views/reporting/no-data";

const useStyles = createUseStyles(() => ({
	cardHeader: {
		display: "flex",
		justifyContent: "space-between",
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingTop: "20px",
		paddingLeft: "0",
		paddingBottom: "31px",
		paddingRight: "0",
	},
	toggleBtnGrp: {
		"& > button:first-child": {
			borderTopRightRadius: "0px",
			borderBottomRightRadius: "0px",
		},
		"& > button:last-child": {
			borderTopLeftRadius: "0px",
			borderBottomLeftRadius: "0px",
		},
		"& > button": {
			height: "32px !important",
			width: "56px !important",
			padding: "0px !important",
			margin: "0px !important",
			border: "1px solid #dededf !important",
			"& svg": {
				height: "20px !important",
				width: "20px !important",
				alignItems: "center !important",
			},
		},
	},
	notActiveToggleBtn: {
		backgroundColor: "#ffffff !important",
		"& svg": {
			"& path": {
				fill: "#6b6b6b !important",
			},
		},
	},
	activeToggleBtn: {
		backgroundColor: "#f2f2f2 !important",
		"& svg": {
			"& path": {
				fill: "#353430 !important",
			},
		},
	},
}));

function MachineStatusByHour({ dateRange, data, showGraph, setShowGraph, limit, isNewFormat }) {
	const classes = useStyles();
	const intl = useIntl();
	const [machineName, setMachineName] = useState("");

	const headerButtons = (
		<span className={classes.toggleBtnGrp}>
			<Popup
				inverted
				trigger={
					<Button
						className={showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
						onClick={() => setShowGraph(true)}
					>
						<ChartViewIcon />
					</Button>
				}
				content={intl.formatMessage({ id: "Graph View" })}
				position="bottom center"
			/>
			<Popup
				inverted
				trigger={
					<Button
						className={!showGraph ? classes.activeToggleBtn : classes.notActiveToggleBtn}
						onClick={() => setShowGraph(false)}
					>
						<TableViewIcon />
					</Button>
				}
				content={intl.formatMessage({ id: "Table View" })}
				position="bottom center"
			/>
		</span>
	);

	const headerText = intl.formatMessage(
		{ id: "Machine Status by Hour by {machineName}" },
		{
			machineName: machineName,
		},
	);

	return (
		<>
			{isNewFormat ? (
				data.byMachineByHour.length !== 0 ? (
					showGraph ? (
						<MachineStatusByHourGraph
							reportData={data}
							dateRange={dateRange}
							limit={limit}
							setMachine={setMachineName}
							headerButtons={headerButtons}
							headerText={headerText}
							isNewFormat={isNewFormat}
						/>
					) : (
						<MachineStatusByHourTable
							reportData={data}
							dateRange={dateRange}
							limit={limit}
							setMachine={setMachineName}
							machineName={machineName}
							headerButtons={headerButtons}
							headerText={headerText}
							isNewFormat={isNewFormat}
						/>
					)
				) : (
					<NoData />
				)
			) : data.length !== 0 ? (
				showGraph ? (
					<MachineStatusByHourGraph
						reportData={data}
						dateRange={dateRange}
						limit={limit}
						setMachine={setMachineName}
						headerButtons={headerButtons}
						headerText={headerText}
						isNewFormat={isNewFormat}
					/>
				) : (
					<MachineStatusByHourTable
						reportData={data}
						dateRange={dateRange}
						limit={limit}
						setMachine={setMachineName}
						machineName={machineName}
						headerButtons={headerButtons}
						headerText={headerText}
						isNewFormat={isNewFormat}
					/>
				)
			) : (
				<NoData />
			)}
		</>
	);
}

export default MachineStatusByHour;

import { Button, Icon, List, Menu, Popup } from "semantic-ui-react";
import { useContext, useEffect, useRef, useState } from "react";
import { addWeeks, compareAsc, format, getDay, isFuture, isToday, setHours } from "date-fns";

import { ReactComponent as CalendarImage } from "@/views/reporting/img/calendar-icon.svg";
import { FormattedMessage, useIntl } from "react-intl";
import RadioButton from "./radio-button";
import { createUseStyles } from "react-jss";
import { useLilius } from "use-lilius";
import { useLocalStorage } from "@/views/reporting/custom-hooks/local-storage-hook";
import { midnightDate, toLocalFormat } from "@/views/reporting/functions/index";
import { getCurrentTimeZoneForSite } from "./services/API/Reports/identity-api";
import { isFeatureFlagEnabled } from "@/utils/auth";
import { UserContext } from "@/components/user-context";

const useStyles = createUseStyles((theme) => ({
	dropdownHeading: {
		display: "flex",
		alignItems: "center",
	},
	inputStyle: {
		backgroundColor: "#e0e0e0 !important",
		width: "135px !important",
		maxWidth: "182px !important",
		margin: "0em",
		height: "44px",
		flex: "1 0 auto",
		outline: "none",
		"-webkit-tap-highlight-color": "rgba(255, 255, 255, 0)",
		"text-align": "center",
		"line-height": "1.4",
		"font-family": "'Stolzl', 'Helvetica Neue', Arial, Helvetica, sans-serif",
		fontSize: "16px",
		background: "#d36060",
		border: "1px solid rgba(34, 36, 38, 0.15)",
		color: "#353430",
		"border-radius": "6px",
		transition: "box-shadow 0.1s ease, border-color 0.1s ease",
		"box-shadow": "none",
		"&:focus": {
			border: "2px solid #294652 !important",
		},
	},
	datePicker: {
		borderRadius: "8px !important",
	},
	fromTo: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	selectedDay: {
		backgroundColor: "#294652 !important",
		height: "scale(1.5)",
		width: "scale(1.5)",
		margin: "0",
		borderRadius: "0",
	},
	inRangeDay: {
		backgroundColor: "#D4DADC !important",
		width: "scale(1.2)",
		margin: "0",
		border: "0",
		borderRadius: "0",
		color: "#353430",
	},
	doneButton: {
		color: "#fff !important",
		float: "right",
		marginRight: "0px !important",
	},
	customButtonEnabled: {
		backgroundColor: "#294652 !important",
	},
	customButtonDisabled: {
		backgroundColor: "#e0e0e0 !important",
	},
	calendarImage: {
		marginLeft: "15px !important",
		marginRight: "6px",
	},
	dropdownIcon: {
		marginRight: "15px !important",
	},
	calendar: {
		border: "none",
		fontFamily: "'Stolzl', 'Helvetica Neue', Arial, Helvetica, sans-serif",
	},
	month: {
		backgroundColor: "red !important",
	},
	alignCalendar: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		"& > div": {
			flexGrow: "1",
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
		},
	},
	calendarGrid: {
		display: "flex",
		flexDirection: "column",
		gap: "2px",
		margin: "0",
		marginBottom: "12px",
		"& > div": {
			textTransform: "uppercase",
			fontFamily: "Stolzl",
			fontSize: "14px",
			fontWeight: "normal",
			fontStretch: "normal",
			fontStyle: "normal",
			lineHeight: "1.4",
			letterSpacing: "normal",
			display: "flex",
			flexDirection: "row",
			margin: "0",
			justifyContent: "space-evenly",
			alignItems: "center",
			"& > div": {
				width: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "32px",
				"& > span": {
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					fontFamily: "Stolzl",
					fontSize: "16px",
					fontWeight: "normal",
					fontStretch: "normal",
					fontStyle: "normal",
					lineHeight: "1.4",
				},
			},
		},
	},
	calendarHeader: {
		fontFamily: "Stolzl",
		fontSize: "21px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.4",
		letterSpacing: "normal",
		marginTop: "36px",
		marginBottom: "10px",
	},
	calendarLine: {
		"& > $inRange:first-child": {
			borderTopLeftRadius: "8px",
			borderBottomLeftRadius: "8px",
		},
		"& > $inRange:last-child": {
			borderTopRightRadius: "8px",
			borderBottomRightRadius: "8px",
		},
	},
	selected: {
		"& > span": {
			backgroundColor: "#294652",
			borderRadius: "50%",
			height: "30px",
			width: "30px",
			color: "#fff",
		},
	},
	selectedFirst: {
		background: "linear-gradient(to left, #D4DADC 50%, white 50%)",
		composes: ["$selected"],
	},
	selectedLast: {
		background: "linear-gradient(to left, white 50%, #D4DADC 50%)",
		composes: ["$selected"],
	},
	inRange: {
		backgroundColor: "#D4DADC",
	},
	startOfWeek: {
		borderRadius: "2px 0 0 2px",
	},
	endOfMonth: {
		borderRadius: "0 2px 2px 0",
	},
	today: {
		"& > span": {
			backgroundColor: "#f2f2f2",
			borderRadius: "50%",
			height: "30px",
			width: "30px",
		},
	},
	future: {
		"& > span": {
			backgroundColor: "#f2f2f2",
			borderRadius: "50%",
			height: "30px",
			width: "30px",
			opacity: "0.4",
		},
	},
}));

const options = ["Today", "Yesterday", "Last7Days", "Last30Days", "Custom"];

function getStartEndDates(selectedTimeOption, timeZoneOffset, timeZoneId) {
	let now = new Date();
	let siteNow = now;
	let siteToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
	let offset = siteToday.getTimezoneOffset() * -1;
	// We only pass in -100_000_000 for the timeZoneOffset when they do not have the
	// pack-net-reporting-use-site-timezone flag enabled
	if (timeZoneOffset > -100_000_000) {
		const utcNow = new Date(
			now.getUTCFullYear(),
			now.getUTCMonth(),
			now.getUTCDate(),
			now.getUTCHours(),
			now.getUTCMinutes(),
			now.getUTCSeconds(),
		);
		var msPerMinute = 60000;
		siteNow = new Date(utcNow.getTime() + timeZoneOffset * msPerMinute);
		siteToday = new Date(siteNow.getFullYear(), siteNow.getMonth(), siteNow.getDate());
		offset = timeZoneOffset;
	}
	switch (selectedTimeOption) {
		case "Yesterday": {
			const yesterday = new Date(siteToday);
			yesterday.setDate(siteToday.getDate() - 1);
			return {
				start: toLocalFormat(yesterday),
				end: toLocalFormat(siteToday),
				offset: offset,
				id: selectedTimeOption,
				timeZoneId: timeZoneId,
			};
		}
		case "Last7Days": {
			const sevenDaysAgo = new Date(siteToday);
			sevenDaysAgo.setDate(siteToday.getDate() - 6);
			return {
				start: toLocalFormat(sevenDaysAgo),
				end: toLocalFormat(siteNow),
				offset: offset,
				id: selectedTimeOption,
				timeZoneId: timeZoneId,
			};
		}
		case "Last30Days": {
			const thirtyDaysAgo = new Date(siteToday);
			thirtyDaysAgo.setDate(siteToday.getDate() - 29);
			return {
				start: toLocalFormat(thirtyDaysAgo),
				end: toLocalFormat(siteNow),
				offset: offset,
				id: selectedTimeOption,
				timeZoneId: timeZoneId,
			};
		}
		case "Custom": {
			return {
				start: toLocalFormat(siteToday),
				end: toLocalFormat(siteToday),
				offset: offset,
				id: selectedTimeOption,
				timeZoneId: timeZoneId,
			};
		}
		case "Today":
		default: {
			return {
				start: toLocalFormat(siteToday),
				end: toLocalFormat(siteNow),
				offset: offset,
				id: selectedTimeOption,
				timeZoneId: timeZoneId,
			};
		}
	}
}

function DatePickerDropdown({ dateOptions, setDateOptions, datePickerWidth }) {
	const widthDatePicker = datePickerWidth + "px";
	const SELECTED_DATE_RANGE = "SELECTED_DATE_RANGE";
	const [dateOptionsLocalStorage, setDateOptionsLocalStorage] = useLocalStorage(SELECTED_DATE_RANGE);
	const initStartDate = new Date(dateOptions.start).setHours(0, 0, 0);
	const initEndDate = new Date(dateOptions.end).setHours(0, 0, 0);
	const [open, setOpen] = useState(false);
	const classes = useStyles();
	const [isCustomShown, setIsCustomShown] = useState(dateOptions.id === "Custom");
	const [customStartDate, setCustomStartDate] = useState(new Date(initStartDate));
	const [customEndDate, setCustomEndDate] = useState(new Date(initEndDate));
	const [optionSelected, setOptionSelected] = useState(dateOptions.id);
	const [timeZone, setTimeZone] = useState("");
	const intl = useIntl();
	const { currentUser } = useContext(UserContext);

	const startRef = useRef(null);
	const endRef = useRef(null);
	const minutesPerHour = 60;

	const { calendar, deselect, inRange, isSelected, select, selected, viewNextMonth, viewPreviousMonth } = useLilius({
		numberOfMonths: 1,
		selected: [initStartDate, initEndDate],
	});

	useEffect(() => {
		async function getData() {
			if (isFeatureFlagEnabled(currentUser, "pack-net-reporting-use-site-timezone")) {
				var response = await getCurrentTimeZoneForSite();
				if (!dateOptions.id) {
					const dateFilter = dateOptionsLocalStorage ? JSON.parse(dateOptionsLocalStorage) : { id: "Today" };
					setTimeZone(response.data.siteTimeZone);

					if (dateFilter.id !== "Custom") {
						const ev = getStartEndDates(
							dateFilter.id,
							response.data.siteTimeZoneOffset * minutesPerHour,
							response.data.siteTimeZone,
						);
						setDateOptions(ev);
						setOptionSelected(ev.id);
						const start = setHours(new Date(ev.start), 0);
						const end = setHours(new Date(ev.end), 0);
						select([start, end], true);
						setCustomStartDate(start);
						setCustomEndDate(end);
					} else {
						dateFilter.offset = response.data.siteTimeZoneOffset * minutesPerHour;
						dateFilter.timeZoneId = response.data.siteTimeZone;
						setDateOptions(dateFilter);
						setOptionSelected(dateFilter.id);
						const startDate = dateFilter.start.endsWith("Z") ? dateFilter.start : (dateFilter.start += "Z");
						const endDate = dateFilter.end.endsWith("Z") ? dateFilter.end : (dateFilter.end += "Z");
						const s = new Date(startDate);
						const e = new Date(endDate);
						const start = new Date(s.getTime() + s.getTimezoneOffset() * 60 * 1000);
						const end = new Date(e.getTime() + e.getTimezoneOffset() * 60 * 1000);
						select([start, end], true);
						setCustomStartDate(start);
						setCustomEndDate(end);
						setIsCustomShown(true);
					}
				} else {
					dateOptions.offset = response.data.siteTimeZoneOffset * minutesPerHour;
					dateOptions.timeZoneId = response.data.siteTimeZone;
					setDateOptions(dateOptions);
					setTimeZone(response.data.siteTimeZone);
				}
			} else {
				if (!dateOptions.id) {
					const dateFilter = dateOptionsLocalStorage ? JSON.parse(dateOptionsLocalStorage) : { id: "Today" };

					if (dateFilter.id !== "Custom") {
						const ev = getStartEndDates(dateFilter.id, -100_000_000, "");
						setDateOptions(ev);
						setOptionSelected(ev.id);
						const start = setHours(new Date(ev.start), 0);
						const end = setHours(new Date(ev.end), 0);
						select([start, end], true);
						setCustomStartDate(start);
						setCustomEndDate(end);
					} else {
						setDateOptions(dateFilter);
						setOptionSelected(dateFilter.id);
						const startDate = dateFilter.start.endsWith("Z") ? dateFilter.start : (dateFilter.start += "Z");
						const endDate = dateFilter.end.endsWith("Z") ? dateFilter.end : (dateFilter.end += "Z");
						const s = new Date(startDate);
						const e = new Date(endDate);
						// The date we store will go back a day if the location of your browser has a negative UTC offset.
						// This causes the date picker to move behind 1 day.
						// By adding the offset to our selected date we get the correct date at midnight.
						const start = new Date(s.getTime() + s.getTimezoneOffset() * 60 * 1000);
						const end = new Date(e.getTime() + e.getTimezoneOffset() * 60 * 1000);
						select([start, end], true);
						setCustomStartDate(start);
						setCustomEndDate(end);
						setIsCustomShown(true);
					}
				}
			}
		}
		getData();
	}, [dateOptions, dateOptionsLocalStorage]);

	async function handleDoneComplete() {
		if (!(customEndDate instanceof Date) || isNaN(customEndDate.getTime())) return;

		let siteNow = new Date();
		let offset = new Date().getTimezoneOffset() * -1;
		let timeZoneId = "";
		if (isFeatureFlagEnabled(currentUser, "pack-net-reporting-use-site-timezone")) {
			let siteResponse = await getCurrentTimeZoneForSite();
			timeZoneId = siteResponse.data.siteTimeZone;
			offset = siteResponse.data.siteTimeZoneOffset * 60;
			const utcNow = new Date(
				siteNow.getUTCFullYear(),
				siteNow.getUTCMonth(),
				siteNow.getUTCDate(),
				siteNow.getUTCHours(),
				siteNow.getUTCMinutes(),
				siteNow.getUTCSeconds(),
			);

			var msPerMinute = 60000;
			siteNow = new Date(utcNow.getTime() + offset * msPerMinute);
		}

		const end = isToday(customEndDate) ? toLocalFormat(siteNow) : midnightDate(customEndDate);

		const eventDetail = {
			start: midnightDate(customStartDate),
			end: end,
			offset: offset,
			id: "Custom",
			timeZoneId: timeZoneId,
		};

		setOpen(false);
		setDateOptionsLocalStorage(JSON.stringify(eventDetail));
		setDateOptions(eventDetail);
	}

	function handleChange(id) {
		setOptionSelected(id);
		if (id === "Custom") {
			setIsCustomShown(true);
		} else {
			let offset = -100_000_000;
			let timeZoneId = "";
			if (isFeatureFlagEnabled(currentUser, "pack-net-reporting-use-site-timezone")) {
				offset = dateOptions.offset;
				timeZoneId = dateOptions.timeZoneId;
			}

			const eventDetail = getStartEndDates(id, offset, timeZoneId);
			setDateOptionsLocalStorage(JSON.stringify(eventDetail));
			setDateOptions(eventDetail);
			const start = setHours(new Date(eventDetail.start), 0);
			const end = setHours(new Date(eventDetail.end), 0);
			select([start, end], true);
			setCustomStartDate(start);
			setCustomEndDate(end);

			setIsCustomShown(false);
			setOpen(false);
		}
	}

	const paddingStyle = {
		paddingRight: "15px !important",
		paddingLeft: "15px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	};

	function getDayClass(day) {
		let tempSelected = selected;
		tempSelected[0].setHours(0, 0, 0, 0);
		if (tempSelected.length > 1) {
			tempSelected[1].setHours(0, 0, 0, 0);
		}
		if (typeof tempSelected[0] === "number") tempSelected = [new Date(tempSelected[0]), new Date(tempSelected[1])];
		if (isSelected(day)) {
			if (
				tempSelected.length === 1 ||
				(tempSelected.length === 2 && tempSelected[0].getTime() === tempSelected[1].getTime())
			) {
				return classes.selected;
			}
			if (tempSelected[0].getTime() === day.getTime()) {
				return classes.selectedFirst;
			}
			return classes.selectedLast;
		}

		if (tempSelected.length === 2 && inRange(day, tempSelected[0], tempSelected[1])) return classes.inRange;

		if (isToday(day)) return classes.today;

		if (isFuture(day)) return classes.future;
		return "";
	}

	function handleCalendarClick(day) {
		if (isFuture(day)) {
			return;
		}

		if (selected.length === 0) {
			select(day);
			setCustomStartDate(day);
			return;
		}

		if (selected.length > 1) {
			selected.forEach((d) => deselect(d));
			setCustomEndDate(null);
			select(day);
			setCustomStartDate(day);
			return;
		}

		const sorted = [selected[0], day].sort((a, b) => compareAsc(a, b));

		select(sorted, true);

		setCustomStartDate(sorted[0]);
		setCustomEndDate(sorted[1]);
	}

	const timeZoneLabel = timeZone ? ` (${timeZone})` : "";

	return (
		<Menu className={classes.datePicker} fluid widths={1}>
			<Popup
				style={{ width: widthDatePicker }}
				flowing
				basic
				on="click"
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
				open={open}
				position="bottom right"
				trigger={
					<Menu.Item style={paddingStyle}>
						<div className={classes.dropdownHeading}>
							<CalendarImage className={classes.calendarImage} />
							<FormattedMessage id={dateOptions.id ? dateOptions.id : "Date Range"} />
							{timeZoneLabel}
						</div>
						<Icon name="dropdown" className={classes.dropdownIcon} />
					</Menu.Item>
				}
			>
				<List relaxed="very" size="medium">
					{options.map((option) => (
						<List.Item key={option}>
							<RadioButton checked={optionSelected === option} onChange={() => handleChange(option)}>
								<FormattedMessage id={option} /> {option === "Today" && `(${intl.formatMessage({ id: "Default" })})`}
							</RadioButton>
						</List.Item>
					))}
					{isCustomShown && [
						<List.Item key="custom">
							<div className={classes.fromTo}>
								<input
									className={classes.inputStyle}
									value={customStartDate ? customStartDate.toLocaleDateString() : ""}
									readOnly
									ref={startRef}
								/>
								<FormattedMessage id="to" />
								<input
									className={classes.inputStyle}
									value={customEndDate ? customEndDate.toLocaleDateString() : ""}
									readOnly
									ref={endRef}
								/>
							</div>
							<div className={classes.alignCalendar}>
								<div className={classes.calendarHeader}>
									<Icon onClick={viewPreviousMonth} name="angle left" />

									{calendar.map(([[firstDay]]) => {
										const year = format(addWeeks(firstDay, 1), "yyyy");
										const month = format(addWeeks(firstDay, 1), "MMMM");
										return <span key={firstDay.toDateString()}>{intl.formatMessage({ id: month }) + ` ${year}`}</span>;
									})}

									<Icon onClick={viewNextMonth} name="angle right" />
								</div>
								{calendar.map((month, i) => (
									<div className={classes.calendarGrid} key={`calendar-row-${i}`}>
										<div>
											{month[0].map((day) => {
												const daysOfWeek = [
													"Sunday Abbrev",
													"Monday Abbrev",
													"Tuesday Abbrev",
													"Wednesday Abbrev",
													"Thursday Abbrev",
													"Friday Abbrev",
													"Saturday Abbrev",
												].map((d) => intl.formatMessage({ id: d }));
												return <div key={`${day}`}>{daysOfWeek[getDay(day)]}</div>;
											})}
										</div>

										{month.map((week) => (
											<div key={`month-${month[0][0].toDateString()}-week-${week[0]}`} className={classes.calendarLine}>
												{week.map((day) => (
													<div className={getDayClass(day)} key={`${day}`} onClick={() => handleCalendarClick(day)}>
														<span>{format(day, "dd")}</span>
													</div>
												))}
											</div>
										))}
									</div>
								))}
							</div>
							<div>
								<Button
									onClick={handleDoneComplete}
									disabled={selected.length !== 2}
									className={
										selected.length === 2
											? `${classes.doneButton} ${classes.customButtonEnabled}`
											: `${classes.doneButton} ${classes.customButtonDisabled}`
									}
								>
									<FormattedMessage id="Done" />
								</Button>
							</div>
						</List.Item>,
					]}
				</List>
			</Popup>
		</Menu>
	);
}

export default DatePickerDropdown;

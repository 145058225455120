import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { createUseStyles, useTheme } from "react-jss";
import { useIntl } from "react-intl";
import { getAvailabilityDisplayData } from "@/views/reporting/functions/availability-data";
import { timeLabelFormatter, twentyFourHourToTwelveHourHourFormat } from "@/views/reporting/functions/over-time";
import { getPaginationResult } from "@/views/reporting/functions/swimlanes";
import SwimLanePagination from "@/views/reporting/swim-lane-pagination";
import Legend from "../../legend";
import LegendDot from "../../legend-dot";
import ChangeTimeFrame from "./change-time-frame";

const useStyles = createUseStyles({
	cardHeader: {
		display: "flex",
		justifyContent: "space-between",
		fontFamily: "Stolzl-Medium",
		fontSize: "24px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "left",
		paddingTop: "20px",
		paddingLeft: "0",
		paddingBottom: "32px",
		paddingRight: "0",
	},
	content: {
		display: "flex",
		flexDirection: "column",
		flexWrap: "no-wrap",
	},
	machineNameHeading: {
		paddingBottom: "20px",
	},
	statusByHour: {
		display: "flex",
		flexDirection: "row",
		marginBottom: "32px",
		"& > div:first-child": {
			width: "75%",
			paddingRight: "55px",
			"& > div": {
				"& > div": {
					"& > svg": {
						transform: "translate(42px)",
					},
					"& > svg:not(:root)": {
						overflow: "visible !important",
					},
					"& div": {
						border: "0px solid #353430",
						"& div": {
							backgroundColor: "#353430 !important",
							color: "#ffffff !important",
						},
					},
				},
			},
		},
		"& > div:last-child": {
			paddingTop: "27px",
		},
	},
	changeTimeFrame: {
		display: "flex",
		alignItems: "center",
		minHeight: "462px",
	},
});

function MachineStatusByHourGraph({
	reportData,
	dateRange,
	limit,
	setMachine,
	headerText,
	headerButtons,
	isNewFormat,
}) {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const intl = useIntl();
	const [presentationData, setPresentationData] = useState("");
	const [statuses, setStatuses] = useState([]);
	const [validTimeFrame, setValidTimeFrame] = useState(true);
	const [pagination, setPagination] = useState({
		perPage: 1,
		page: 1,
	});

	function getOptions() {
		if (!presentationData || !presentationData.length) return { series: [] };

		const colors = statuses.map((a) => a.color);
		const indexDate = "Hours";
		const xaxisCategories = Array.from(Array(24).keys()).map((item, i) => {
			const date = { year: 1, month: 1, day: 1, hour: i };
			return timeLabelFormatter(date, indexDate, i % 2 !== 0);
		});

		return {
			chart: {
				stacked: true,
				stackedType: "100%",
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "25px",
					dataLabels: {
						position: "top",
						total: {
							enabled: false,
						},
					},
				},
			},
			colors: colors,
			dataLabels: {
				offsetY: -15,
				enabled: true,
				style: {
					fontSize: "10px",
					fontFamily: "Stolzl",
					colors: ["#353430"],
				},
				dropShadow: {
					enabled: false,
					blur: 0,
					top: 1,
				},
				formatter: (value) => {
					if (value === 3600) {
						return "100%";
					}
					return `${((value / 3600) * 100).toFixed(1)}%`;
				},
			},
			xaxis: {
				labels: {
					style: {
						colors: "#7c7b79",
						fontSize: "16px",
						fontFamily: "Stolzl-Book",
					},
				},
				title: {
					text: intl.formatMessage({ id: indexDate }),
					style: {
						color: "#353430",
						fontFamily: "Stolzl",
						fontSize: "15px",
						fontWeight: "normal",
					},
					offsetY: 0,
				},
				categories: xaxisCategories,
			},
			yaxis: {
				labels: {
					style: {
						colors: "#7c7b79",
						fontSize: "16px",
						fontFamily: "Stolzl-Book",
					},
					formatter: (value) => {
						if (value === 4200) return "";
						return `${value / 60}`;
					},
				},
				title: {
					text: intl.formatMessage({ id: "Mins" }),
					style: {
						color: "#353430",
						fontFamily: "Stolzl",
						fontSize: "15px",
						fontWeight: "normal",
					},
					offsetX: -20,
				},
				min: 0,
				max: 4200,
				tickAmount: 7,
			},
			legend: {
				show: false,
			},
			stroke: {
				width: 0,
			},
			tooltip: {
				// custom: function ({ series, seriesIndex, dataPointIndex, w }) {
				// 	return `Status - Time - ${(series / 60).toFixed(1).toLocaleString()} ${intl.formatMessage({ id: "mins" })}`;
				// },
				x: {
					show: false,
				},
				y: {
					formatter: (x, { dataPointIndex }) => {
						return ` - ${twentyFourHourToTwelveHourHourFormat(dataPointIndex)} - ${(x / 60)
							.toFixed(1)
							.toLocaleString()} ${intl.formatMessage({ id: "mins" })}`;
					},
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				fixed: {
					enabled: true,
					position: "topLeft",
					offsetX: 0,
					offsetY: 0,
				},
				style: {
					fontSize: "13px",
					fontFamily: "Stolzl",
				},
				onDatasetHover: {
					highlightDataSeries: true,
				},
			},
		};
	}

	function getTimeValues(item) {
		const startHour = parseInt(item.startTime.split("T")[1].split(":")[0]);
		let endHour = parseInt(item.endTime.split("T")[1].split(":")[0]);
		const startDay = parseInt(item.startTime.split("T")[0].split("-")[2]);
		let endDay = parseInt(item.endTime.split("T")[0].split("-")[2]);
		const date = item.startTime.split("T")[0];
		if (startHour > endHour && endHour === 0) {
			endHour = 23;
		} else if (startHour === endHour && startDay !== endDay) {
			endHour = 23;
		}
		if (startHour === endHour) {
			return [
				{
					hour: startHour,
					seconds: (Date.parse(item.endTime) - Date.parse(item.startTime)) / 1000,
				},
			];
		}
		const timeValues = [];
		let start = item.startTime;
		while (true) {
			const current = parseInt(start.split("T")[1].split(":")[0]);
			const next = current + 1;
			if (current < endHour) {
				const temp = `${date}T${String(next).padStart(2, "0")}:00:00`;
				const result = {
					hour: current,
					seconds: (Date.parse(temp) - Date.parse(start)) / 1000,
				};
				timeValues.push(result);
				start = temp;
			} else {
				const result = {
					hour: current,
					seconds: (Date.parse(item.endTime) - Date.parse(start)) / 1000,
				};
				timeValues.push(result);
				break;
			}
		}

		return timeValues;
	}

	function mapData(data) {
		if (
			dateRange.id === "Yesterday" ||
			dateRange.id === "Today" ||
			(dateRange.id === "Custom" && dateRange.start === dateRange.end)
		) {
			setValidTimeFrame(true);
		} else {
			setValidTimeFrame(false);
		}

		let aggregation = [];
		let statuses = [];
		if (isNewFormat) {
			statuses = data.byMachineByHour
				.map((item) => {
					return item.data;
				})
				.flat()
				.reduce((groups, item) => {
					const [ordering, color, displayStatus] = getAvailabilityDisplayData(item.name);
					const group = groups.find((i) => i.status === displayStatus);
					if (!group) {
						return [
							...groups,
							{
								status: displayStatus,
								color: color,
								ordering: ordering,
							},
						];
					}
					return groups;
				}, []);
			aggregation = data.byMachineByHour.map((item) => {
				item.data = item.data.map((dataItem) => {
					let [ordering, color, displayStatus] = getAvailabilityDisplayData(dataItem.name);
					dataItem.status = displayStatus;
					dataItem.color = color;
					dataItem.ordering = ordering;
					return dataItem;
				});
				return item;
			});
		} else {
			statuses = data.reduce((groups, item) => {
				const [ordering, color, displayStatus] = getAvailabilityDisplayData(item.status);
				const group = groups.find((i) => i.status === displayStatus);
				if (!group) {
					return [
						...groups,
						{
							status: displayStatus,
							color: color,
							ordering: ordering,
						},
					];
				}
				return groups;
			}, []);
			statuses.sort((a, b) => {
				if (a.ordering < b.ordering) return -1;
				if (a.ordering > b.ordering) return 1;
				return 0;
			});

			function getSeriesTemplate(statuses) {
				return statuses.reduce((groups, item) => {
					return [
						...groups,
						{
							name: item.status,
							data: new Array(24).fill(0),
						},
					];
				}, []);
			}

			aggregation = data.reduce((groups, item) => {
				const timeValues = getTimeValues(item);
				const displayStatus = getAvailabilityDisplayData(item.status)[2];
				let group = groups.find((i) => i.machineId === item.machineId);
				if (!group) {
					groups = [
						...groups,
						{
							machineId: item.machineId,
							machineName: item.machineName,
							data: getSeriesTemplate(statuses),
						},
					];
				}
				group = groups.find((i) => i.machineId === item.machineId);
				const section = group.data.find((i) => i.name === displayStatus);
				for (const tv of timeValues) {
					section.data[tv.hour] += tv.seconds;
				}
				return groups;
			}, []);
		}
		setPresentationData(aggregation);
		setStatuses(statuses);
	}

	useEffect(() => {
		if (reportData.length || isNewFormat) mapData(reportData, dateRange);
	}, [reportData, dateRange]);

	let options = getOptions();

	const paginationResult = getPaginationResult({ items: presentationData }, pagination.perPage, pagination.page);

	useEffect(() => {
		if (limit < 1000 && paginationResult.items.length > 0) {
			setMachine(paginationResult.items[0].machineName);
		} else {
			setMachine("All Machines");
		}
	}, [limit, paginationResult]);

	return presentationData ? (
		<>
			{!validTimeFrame && (
				<div className={classes.changeTimeFrame}>
					<ChangeTimeFrame />
				</div>
			)}
			{validTimeFrame && (
				<div className={`${classes.cardHeader}`}>
					<span>{headerText}</span>
					{headerButtons}
				</div>
			)}
			{validTimeFrame && limit === 1000 && (
				<div className={classes.content}>
					{presentationData.map((data) => {
						return (
							<>
								<div className={classes.machineNameHeading}>{data.machineName}</div>
								<div className={classes.statusByHour}>
									<div>
										<Chart options={options} series={data.data} type="bar" height="447px" />
									</div>
									<div>
										<Legend headerText={"Availability Status"}>
											{statuses.map((item, i) => {
												const siteId = item.siteId ?? i;
												const machineName = item.machineName ?? i;
												return (
													<LegendDot key={`cb-${siteId}-${machineName}`} color={item.color}>
														{intl.formatMessage({ id: item.status })}
													</LegendDot>
												);
											})}
										</Legend>
									</div>
								</div>
							</>
						);
					})}
				</div>
			)}
			{validTimeFrame && limit < 1000 && (
				<div className={classes.statusByHour}>
					<div>
						<Chart options={options} series={paginationResult.items[0].data} type="bar" height="447px" />
					</div>
					<div>
						<Legend headerText={"Availability Status"}>
							{statuses.map((item, i) => {
								const siteId = item.siteId ?? i;
								const machineName = item.machineName ?? i;
								return (
									<LegendDot key={`cb-${siteId}-${machineName}`} color={item.color}>
										{item.status}
									</LegendDot>
								);
							})}
						</Legend>
					</div>
				</div>
			)}
			{validTimeFrame && (
				<SwimLanePagination
					pagination={pagination}
					setPagination={setPagination}
					paginationResult={paginationResult}
					limit={limit}
					key="machine-status-by-hour-graph-swim-lane-pagination"
				/>
			)}
		</>
	) : (
		<div></div>
	);
}

export default MachineStatusByHourGraph;

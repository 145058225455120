import { Dropdown, Icon } from "semantic-ui-react";
import Flex from "@/components/flex";
import TwoLineDropdownItem from "@/components/dropdown/two-line-dropdown-item";
import { ReactComponent as IconReproduce } from "@/assets/images/icon-reproduce.svg";

function HistoryActionMenu({ batchId, reproduceSingle }) {
	return (
		<Dropdown icon={<Icon name="ellipsis vertical" />} direction="left">
			<Dropdown.Menu>
				<Dropdown.Item onClick={() => reproduceSingle(batchId)}>
					<Flex alignCenter gap={12}>
						<IconReproduce size={24} />
						<TwoLineDropdownItem mainTextId="Reproduce" subTextId="Single quantity" />
					</Flex>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}

export default HistoryActionMenu;

import { readLocalStorage } from "@/api/local-storage";
import machineTypes from "@/constants/machineTypes";
import errorSeverity from "@/constants/errorSeverity";
import { useEffect, useState } from "react";
import { HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import { createSignalRContext } from "react-signalr";

const machineType = machineTypes.X5;
const apiUrl = "/x5api/api/v1.0/machines";
const SignalRContext = createSignalRContext();
const websocketUrl = "/x5api/hubs/x5machines";
const versionEventName = "OnX5Version";
const errorsChangedEventName = "OnErrorsUpdated";
const warningsChangedEventName = "OnWarningsUpdated";

function getActionKey(code, severity) {
	switch (`${severity}${code}`) {
		case "Error1030":
			return "CheckAir";
		case "Error1":
		case "Warning1101":
			return "CheckFollowing";
		case "1022":
		case "1024":
		case "1025":
		case "1026":
		case "712":
		case "713":
			return "CheckLabelPrinter";
		case "Error1032":
		case "Error1034":
			return "CheckScanner";
		case "Error1014":
		case "Error1015":
		case "Error1016":
		case "Error1017":
		case "Error701":
		case "Error702":
		case "Error703":
		case "Error704":
		case "Error705":
		case "Error707":
		case "Error718":
		case "Error725":
		case "Error726":
		case "Error727":
			return "CleanConveyor";
		case "Error1021":
		case "Error1027":
			return "CleanConveyorAndCheckLabelApplicator";
		case "Error502":
			return "CleanBoxFeeder";
		case "Error719":
		case "Error720":
			return "CleanLabelArea";
		case "Error203":
			return "CleanRollers";
		case "Error706":
			return "CleanSpinner";
		case "Error1001":
		case "Error1002":
		case "Error1003":
		case "Error1004":
		case "Error1005":
		case "Error1006":
		case "Error1007":
		case "Error1008":
		case "Error1009":
		case "Error1010":
		case "Error1011":
		case "Error1012":
		case "Error1013":
			return "ClearAndHome";
		case "Error709":
		case "Error710":
		case "Warning603":
		case "Warning710":
			return "DoFollowing";
		default:
			return "";
	}
}

function formatAlarmData(machineId, data, severity) {
	return (
		data?.map((alarm) => {
			const code = alarm.code ?? alarm.alarm ?? alarm.alarmNumber;
			const parameter = code === 1 && severity === errorSeverity.warning ? alarm.aux2 : undefined;
			return {
				machineId,
				machineType,
				severity,
				code,
				parameter,
				actionKey: getActionKey(code, severity),
			};
		}) ?? []
	);
}

export default function X5ErrorsAndWarnings({ machines, onErrorsChanged, onWarningsChanged }) {
	const token = readLocalStorage("BEARER");
	const [machineIds, setMachineIds] = useState([]);
	const [staleData, setStaleData] = useState(false);

	useEffect(() => {
		setMachineIds(
			machines.filter((x) => x.machineType.toLowerCase() === machineType.toLowerCase()).map((x) => x.machineId),
		);
	}, [machines]);

	useEffect(() => {
		if (!staleData && SignalRContext.connection?.state !== HubConnectionState.Connected) {
			const handle = setTimeout(() => setStaleData(true), 5000);
			return () => clearTimeout(handle);
		}

		async function getErrorsAndWarnings(abortController) {
			for (const machineId of machineIds) {
				try {
					const response = await fetch(`${apiUrl}/${machineId}/errorsandwarnings`, {
						headers: { Authorization: token },
						signal: abortController.signal,
					});

					if (!response.ok) {
						throw new Error("Did not successfully complete request");
					}

					if (!abortController.signal.aborted && response.status === 204) {
						onErrorsChanged(machineId, []);
						onWarningsChanged(machineId, []);
						continue;
					}

					const data = await response.json();
					const errors = formatAlarmData(machineId, data.errors, errorSeverity.error);
					const warnings = formatAlarmData(machineId, data.warnings, errorSeverity.warning);

					if (!abortController.signal.aborted) {
						onErrorsChanged(machineId, errors);
						onWarningsChanged(machineId, warnings);
					}
				} catch {
					console.error(`Failed to retrieve errors and warnings for ${machineType} machine: ${machineId}`);
				}
			}

			if (!abortController.signal.aborted) {
				setStaleData(false);
			}
		}

		const abortController = new AbortController();
		getErrorsAndWarnings(abortController);
		return () => abortController.abort();
	}, [machineIds, staleData]);

	SignalRContext.useSignalREffect(versionEventName, (version) => {
		console.info(`Received ${versionEventName} SignalR event with version: ${version}`);
	});

	SignalRContext.useSignalREffect(errorsChangedEventName, (_, machineId, newErrors) => {
		if (machineIds.includes(machineId)) {
			console.info(`Processing ${errorsChangedEventName} SignalR event for machine: ${machineId}`);
			onErrorsChanged(machineId, formatAlarmData(machineId, newErrors, errorSeverity.error));
		}
	});

	SignalRContext.useSignalREffect(warningsChangedEventName, (_, machineId, newWarnings) => {
		if (machineIds.includes(machineId)) {
			console.info(`Processing ${warningsChangedEventName} SignalR event for machine: ${machineId}`);
			onWarningsChanged(machineId, formatAlarmData(machineId, newWarnings, errorSeverity.warning));
		}
	});

	return (
		<SignalRContext.Provider
			connectEnabled={!!token}
			accessTokenFactory={() => token.replace("BEARER ", "")}
			dependencies={[token]}
			transport={HttpTransportType.WebSockets}
			url={websocketUrl}
			onOpen={() => {
				console.info(`${machineType} websocket opened and is in the ${SignalRContext.connection?.state} state`);
				if (SignalRContext.connection.state === HubConnectionState.Connected) {
					SignalRContext.connection?.invoke("X5Version");
					setStaleData(true);
				}
			}}
			onReconnect={() => {
				console.info(`${machineType} websocket reconnected and is in the ${SignalRContext.connection?.state} state`);
				if (SignalRContext.connection?.state === HubConnectionState.Connected) {
					SignalRContext.connection?.invoke("X5Version");
					setStaleData(true);
				}
			}}
			onError={() => {
				console.info(`${machineType} websocket errored`);
				setStaleData(true);
			}}
			onClosed={() => {
				console.info(`${machineType} websocket closed`);
				setStaleData(true);
			}}
		/>
	);
}
